<template>
    <div class="filter color-filter">
        <ais-menu attribute="color" :sort-by="sortBy" v-slot="{ items, createURL, canRefine }" ref="menu">
            <FilterCollapse
                v-show="canRefine"
                id="color"
                label="Couleurs"
                body-class="pb-3"
                :collapsed="isCollapsed(items)"
            >
                <div class="row mx-n1">
                    <template v-for="item in items">
                        <div class="col-auto mb-2 px-1" :key="item.value">
                            <a class="color-filter__link"
                                :class="linkClasses(item)"
                                :style="linkStyle(item)"
                                :href="createURL(item.value)"
                                @click.prevent="handleItemClicked(item)"
                            >
                            </a>
                        </div>
                    </template>
                </div>
            </FilterCollapse>
        </ais-menu>
    </div>
</template>

<script>
    import {AisMenu} from 'vue-instantsearch';
    import FilterCollapse from "../FilterCollapse.vue";

    export default {
        components: {
            AisMenu,
            FilterCollapse,
        },

        props: {
            collapsed: Boolean,
        },

        computed: {
            sortBy() {
                return ['name:asc', 'count:desc'];
            },
        },

        methods: {
            linkClasses(item) {
                return {
                    'color-filter__link--active': item.isRefined,
                    'color-filter__link--transparent': item.value === '_transparent',
                    'color-filter__link--multicolor': item.value === '_multicolor',
                }
            },
            linkStyle(item) {
                return {
                    backgroundColor: /^#/.test(item.value) ? item.value : null,
                }
            },
            isCollapsed(items) {
                return this.collapsed && !items.some(item => item.isRefined);
            },
            handleItemClicked(item) {
                this.$refs.menu.state.refine(item.value);
            },
        },
    }
</script>
