<template>
    <div :class="{ 'active border-primary shadow': value === modelValue }"
        class="card justify-content-center card-btn"
        role="button"
        style="border-width: 2px"
        tabindex="0"
        @click="handleClicked"
        @keydown.enter="handleClicked"
    >
        <div :style="notSelected ? 'opacity: .75' : ''" class="card-body py-3">
            <div class="row align-items-center mx-n2 h-100">
                <template v-if="$slots.image">
                    <div class="col-auto px-2">
                        <slot name="image" />
                    </div>
                </template>
                <div class="col px-2">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        model: {
            prop: 'modelValue',
        },
        props: {
            modelValue: String,
            value: String,
        },
        computed: {
            notSelected() {
                return this.modelValue && this.value !== this.modelValue;
            },
        },
        methods: {
            handleClicked() {
                if(this.modelValue !== this.value) {
                    this.$emit('input', this.value);
                    this.$emit('change', this.value);
                }
            }
        },
    }
</script>
