<template>
    <div class="row row-cols-2 row-cols-md-3 gx-0"
        :class="{ 'row-cols-lg-5 gy-2': compact }"
    >
        <template v-if="compact">
            <template v-for="item in items">
                <div class="col" :key="item.id">
                    <slot
                        name="item"
                        :item="item"
                        :small="true"
                        :compact="true"
                        :show-price="false"
                        :overlap="true"
                    />
                </div>
            </template>
        </template>
        <template v-else>
            <template v-for="(item, index) in items">
                <template v-if="showTile">
                    <template v-if="$slots['top-tiles'] && showTopTile(index)">
                        <div class="col-12 product-list__col--tile" key="top-tile">
                            <div class="my-4">
                                <slot name="top-tiles" />
                            </div>
                        </div>
                    </template>
                    <template v-if="$slots['center-tiles'] && showCenterTile(index)">
                        <div class="col-12 product-list__col--tile" key="center-tile">
                            <div class="my-4">
                                <slot name="center-tiles" />
                            </div>
                        </div>
                    </template>
                    <template v-if="$slots['bottom-tiles'] && showBottomTile(index)">
                        <div class="col-12 product-list__col--tile" key="bottom-tile">
                            <div class="my-4">
                                <slot name="bottom-tiles" />
                            </div>
                        </div>
                    </template>
                </template>

                <div class="col" :key="item.id">
                    <slot
                        name="item"
                        :item="item"
                        :overlap="true"
                    />
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default {
        props: {
            items: Array,
            cols: {
                type: Number,
                default: 6,
            },
            compact: Boolean,
            showTile: Boolean,
        },
        methods: {
            showTopTile(index) {
                const topIndex = Math.floor(this.items.length * .2);
                return index === topIndex - (topIndex % this.cols);
            },
            showCenterTile(index) {
                const centerIndex = Math.floor(this.items.length * .6);
                return index === centerIndex - (centerIndex % this.cols);
            },
            showBottomTile(index) {
                const bottomIndex = this.items.length;
                return index === bottomIndex - (bottomIndex % this.cols || this.cols);
            },
        },
        mounted() {
           // changeItemsCountOnArrowPressed.call(this); // DEV ONLY
        }
    }

    function changeItemsCountOnArrowPressed() {
        if(process.env.NODE_ENV === 'development') {
            let initialItems = [...this.items];
            let offset = 0;
            window.addEventListener('keydown', e => {
                e.key === 'ArrowLeft' && offset--;
                e.key === 'ArrowRight' && offset++;
                Vue.config.silent = true;
                this.items = initialItems.slice(0, initialItems.length + offset);
                Vue.config.silent = false;
            });
        }
    }
</script>
