<template>
    <div>
        <template v-if="cart.deliveryAddress">
            <div class="card">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col">
                            <div class="font-weight-bold">{{ cart.deliveryAddress.label }}</div>
                            <div>{{ cart.deliveryAddress.address1 }}</div>
                            <div>{{ cart.deliveryAddress.address2 }}</div>
                            <div>{{ cart.deliveryAddress.postal_code }} {{ cart.deliveryAddress.city }}</div>
                        </div>
                        <div class="col-auto">
                            <template v-if="cart.parcel_carrier === 'mondial_relay'">
                                <img alt="Mondial Relay" class="img-fluid" src="/images/common/partners/mondial-relay.svg" width="50">
                            </template>
                            <template v-else-if="cart.parcel_carrier === 'colis_prive'">
                                <img alt="Colis Privé" class="img-fluid" src="/images/common/partners/colis-prive.svg" width="40">
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center mt-2">
                <button class="btn btn-link" type="button" @click="handleOpenModalClicked">
                    Changer de point relais...
                </button>
            </div>
        </template>
    </div>
</template>

<script>
    import Loading from "../../Loading.vue";
    import { loadSendcloud } from "./util";

    export default {
        components: {
            Loading,
        },
        props: {
            cart: Object,
            profileAddress: Object,
            sendcloudApiKey: String,
            sendcloudAllowedCarriers: Array,
        },
        data() {
            return {
                servicePointId: null,
            }
        },
        computed: {
            options() {
                return {
                    apiKey: this.sendcloudApiKey,
                    country: 'fr',
                    language: 'fr-fr',
                    postalCode: this.profileAddress?.postal_code,
                    servicePointId: this.cart.sendcloudServicePointId,
                    carriers: this.sendcloudAllowedCarriers.map(carrier => ({
                        'colis_prive': 'colisprive',
                        'mondial_relay': 'mondial_relay',
                    }[carrier])),
                };
            },
        },
        methods: {
            open() {
                sendcloud.servicePoints.open(this.options, this.handleSelected, this.handleClosed);
                const iframe = document.querySelector('#sendcloudshipping_service_point_map');
                iframe.parentElement.classList.add('sendcloud-wrapper');
                iframe.classList.add('container');
            },
            handleOpenModalClicked() {
                this.open();
            },
            /**
             * https://api.sendcloud.dev/docs/sendcloud-public-api/service-points/operations/get-a-service-point#response-body
             */
            handleSelected(servicePoint) {
                this.$emit('update:cart', {
                    ...this.cart,
                    sfs_delivery_type: 'parcel_shop',
                    parcel_shop_id: servicePoint.code,
                    parcel_carrier: {
                        'mondial_relay': 'mondial_relay',
                        'colisprive': 'colis_prive',
                    }[servicePoint.carrier],
                    deliveryAddress: {
                        label: servicePoint.name,
                        address1: `${servicePoint.house_number ?? ''} ${servicePoint.street}`.trim(),
                        postal_code: servicePoint.postal_code,
                        city: servicePoint.city,
                        country: servicePoint.country,
                    },
                    sendcloudServicePointId: servicePoint.id,
                });
                this.$emit('update:visible', false);
            },
            handleClosed() {
                if(!this.cart.parcel_shop_id) {
                    this.$emit('update:cart', {
                        ...this.cart,
                        sfs_delivery_type: null,
                        deliveryAddress: null,
                    });
                }
                this.$emit('update:visible', false);
            },
            async init() {
                await loadSendcloud();
                if(!this.cart.deliveryAddress) {
                    this.open();
                }
            },
        },
        created() {
            this.init();
        },
    }
</script>
