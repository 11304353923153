<template>
    <swiperSlide>
        <slot />
    </swiperSlide>
</template>

<script>
    import { swiperSlide } from 'vue-awesome-swiper';

    export default {
        components: {
            SwiperSlide: swiperSlide,
        }
    }
</script>
