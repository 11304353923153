<template>
    <div class="marketing-label marketing-label--macaroon">
        <img class="marketing-label__image" :src="thumbnail" :alt="label" width="100" height="100">
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
            },
            thumbnail: {
                type: String,
                required: true,
            }
        },
    }
</script>