<template>
    <div class="compact-toggle">
        <ais-hits-per-page :items="items" ref="perPage" v-slot="{ items, refine }">
            <div class="row align-items-center gx-0">
                <div class="col-auto">
                    <b-form-checkbox
                        :checked="checked(items)"
                        :value="perPageCompact"
                        :unchecked-value="perPage"
                        button
                        button-variant="custom"
                        @change="refine"
                    >
                        <template v-if="checked(items) === perPageCompact">
                            <svg width="1em" height="1em" style="opacity: .75" viewBox="0 0 16 16"><g fill="currentColor"><path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/></g></svg>
                            <template v-if="showLabel">
                                <span class="ml-2" style="font-size: .875em">
                                    Afficher en grand
                                </span>
                            </template>
                        </template>
                        <template v-else>
                            <svg width="1em" height="1em" style="opacity: .75" viewBox="0 0 16 16"><g fill="currentColor"><path d="M4 2v2H2V2h2zm1 12v-2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V7a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm5 10v-2a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V7a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V2a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zM9 2v2H7V2h2zm5 0v2h-2V2h2zM4 7v2H2V7h2zm5 0v2H7V7h2zm5 0h-2v2h2V7zM4 12v2H2v-2h2zm5 0v2H7v-2h2zm5 0v2h-2v-2h2zM12 1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zm-1 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zm1 4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-2z"/></g></svg>
                            <template v-if="showLabel">
                                <span class="ml-2" style="font-size: .875em">
                                    Afficher en petit
                                </span>
                            </template>
                        </template>
                    </b-form-checkbox>
                </div>
            </div>
        </ais-hits-per-page>
    </div>
</template>

<script>
    import { BFormCheckbox } from 'bootstrap-vue';
    import { AisHitsPerPage } from 'vue-instantsearch';

    export default {
        components: {
            BFormCheckbox,
            AisHitsPerPage,
        },
        props: {
            perPage: Number,
            perPageCompact: Number,
            showLabel: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            items() {
                return [
                    { value: this.perPage, default: true },
                    { value: this.perPageCompact },
                ]
            },
        },
        methods: {
            checked(items) {
                return items.find(item => item.isRefined)?.value;
            },
        },
    }
</script>
