<template>
    <div class="search">
        <ais-instant-search :search-client="searchClient" :index-name="indexName" ref="instantsearch">
            <ais-configure
                v-bind="searchParameters"
                ref="configure"
                v-once
            />
            <template v-for="index in autocompleteIndices">
                <ais-index :index-name="index" />
            </template>
            <ais-state-results v-slot="{ status }">
                <ais-autocomplete v-slot="{ currentRefinement, refine, indices }" ref="autocomplete">
                    <slot
                        v-bind="{
                        query: currentQuery,
                        loading: loading || status === 'stalled',
                        handleInputChanged,
                        handleInputFocus,
                        handleInputFocusout,
                        handleInputKeydown
                    }"
                    />
                    <b-popover
                        target="#header"
                        container="#header"
                        :show.sync="popoverVisible"
                        custom-class="search__popover"
                        :boundary-padding="0"
                        :delay="0"
                        no-fade
                        triggers=""
                        placement="bottom"
                        :fallback-placement="[]"
                        @show="handlePopoverShow"
                        @shown="handlePopoverShown"
                        @hide="handlePopoverHide"
                        ref="popover"
                    >
                        <div class="container py-4 py-md-5" @keydown="handlePopoverKeydown" @focusout="handlePopoverFocusout" ref="results">
                            <button class="close popover-close d-none d-md-inline-block" @click="handleCloseClicked" ref="close">&times;</button>

                            <div class="row mx-n2 mx-md-n3">
                                <template v-for="(item, i) in transformResults(indices)">
                                    <div class="col-6 col-md-4 col-lg-3 mb-3 px-2 px-md-3"
                                        :class="colClasses(i)"
                                        :key="itemKey(item)"
                                    >
                                        <component :is="itemComponent(item)" :item="item" @keydown.native="handleItemKeydown"  />
                                    </div>
                                </template>
                            </div>
                            <template v-if="hasMoreResults(indices)">
                                <div class="text-right mt-3">
                                    <a :href="searchUrl(currentRefinement)" class="search__more-link">
                                        voir les {{ productIndex(indices).results.nbHits }} produits
                                        <svg class="align-baseline ml-2" width="14" height="12">
                                            <use xlink:href="#sprite-line-arrow"></use>
                                        </svg>
                                    </a>
                                </div>
                            </template>
                        </div>
                    </b-popover>
                </ais-autocomplete>
            </ais-state-results>
        </ais-instant-search>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import { AisAutocomplete, AisConfigure, AisIndex, AisInstantSearch, AisSearchBox, AisStateResults } from 'vue-instantsearch';
    import { BPopover } from 'bootstrap-vue';
    import {
        algoliaEmptyResponse,
        meilisearchClient,
        productStoreFilter, scoutIndexName
    } from "../../util/algolia";
    import { forcePopoverPosition, popoverEl } from "../../util/popover";
    import NavMenu from "../NavMenu.vue";
    import NavMenuList from "../NavMenuList.vue";

    import ProductResultItem from './result-items/Product.vue';
    import CategoryResultItem from './result-items/Category.vue';
    import StoreResultItem from './result-items/Store.vue';
    import BrandResultItem from './result-items/Brand.vue';
    import { getAutocompleteBrands, getAutocompleteCategories, getAutocompleteStores } from "../../api";

    function mod(n, m) {
        return ((n % m) + m) % m;
    }

    export default {
        components: {
            AisInstantSearch,
            AisSearchBox,
            AisConfigure,
            AisAutocomplete,
            AisIndex,
            AisStateResults,
            NavMenu,
            NavMenuList,
            BPopover,
        },
        props: {
            url: String,
            minLength: {
                type: Number,
                default: 3,
            },
            maxResults: {
                type: Number,
                default: 12,
            },
            query: String,
            debounceDelay: {
                type: Number,
                default: 250,
            },
            storeId: String,
        },
        data() {
            return {
                searchClient: {
                    search: this.search,
                },
                instantSearchInstance: null,
                // own API results
                results: null,
                popoverVisible: false,
                showCloseButton: false,
                hasResults: false,
                loading: false,
                // take query from querystring on small screens
                currentQuery: this.query,
            }
        },
        computed: {
            indices() {
                return [
                    scoutIndexName('products'),
                ];
            },
            searchParameters() {
                return {
                    hitsPerPage: 12,
                    analyticsTags: ['autocomplete', 'not-cached'],
                    filters: productStoreFilter(this.storeId),
                }
            },
            indexName() {
                return this.indices[0];
            },
            autocompleteIndices() {
                return this.indices.slice(1);
            },
        },
        methods: {
            async getAutocompleteCategories(query) {
                const categories = await getAutocompleteCategories({ query, limit: 3 })
                return (categories ?? [])
                    .map(category => ({ ...category, type: 'category' }));
            },
            async getAutocompleteStores(query) {
                const stores = await getAutocompleteStores({ query, limit: 3 });
                return (stores ?? [])
                    .map(store => ({ ...store, type: 'store' }))
            },
            async getAutocompleteBrands(query) {
                const brands = await getAutocompleteBrands({ query, limit: 3 });
                return (brands ?? [])
                    .map(brand => ({ ...brand, type: 'brand' }));
            },
            async executeSearch(requests, query) {
                const client = meilisearchClient();
                const responses = await Promise.allSettled([
                    client.search(requests),
                    this.getAutocompleteStores(query),
                    this.getAutocompleteCategories(query),
                    this.getAutocompleteBrands(query),
                ]);

                const algoliaResponse = responses[0].value;
                const results = responses.slice(1).reduce((res, response) => [
                    ...res, ...(response.value ?? []),
                ], []);

                return {
                    algoliaResponse,
                    results,
                }
            },
            search(requests) {
                const query = requests[0] ? requests[0].params.query : null;
                if(!query || query.length < this.minLength) {
                    this.loading = false;
                    this.hasResults = false;
                    this.popoverVisible = false;
                    return algoliaEmptyResponse(requests);
                }
                return this.executeSearch(requests, query)
                    .then(({ algoliaResponse, results }) => {
                        this.results = results;
                        this.hasResults = algoliaResponse?.results.some(result => result.nbHits > 0) || results.length > 0;
                        this.popoverVisible = this.hasResults;
                        this.loading = false;
                        return algoliaResponse;
                    });
            },
            itemKey(item) {
                return `${item.indexName ?? item.type}-${item.id}`;
            },
            itemComponent(item) {
                if(item.indexName === scoutIndexName('products')) {
                    return ProductResultItem;
                } else if(item.type === 'store') {
                    return StoreResultItem;
                } else if(item.type === 'category') {
                    return CategoryResultItem;
                } else if(item.type === 'brand') {
                    return BrandResultItem;
                }
            },
            searchUrl(query) {
                return `${this.url}?query=${query}`;
            },
            productIndex(indices) {
                return indices.find(index => index.indexId === scoutIndexName('products'));
            },
            hasMoreResults(indices) {
                const index = this.productIndex(indices);
                return index.results && index.results.nbHits > 0;
            },
            transformResults(indices) {
                const res = [
                    ...(this.results ?? []),
                    ...indices.reduce((res, index) => [
                        ...res,
                        ...index.hits.map(item => ({ ...item, indexName: index.indexName })),
                    ], []),
                ];
                return res.slice(0, this.maxResults);
            },
            colClasses(i) {
                if(i >= 4) {
                    return 'd-none d-sm-block';
                } else if(i >= 6) {
                    return 'd-none d-lg-block';
                }
            },
            input() {
                return this.$el.querySelector('input[name=query]');
            },
            handlePopoverShow(e) {
                if(this.$store.state.menuOpened) {
                    e.preventDefault();
                    this.popoverVisible = false;
                    return;
                }
                this.$store.dispatch('setMenuOpened', true);
            },
            handlePopoverShown(e) {
                forcePopoverPosition(e);
            },
            handlePopoverHide() {
                this.popoverVisible = false;
                this.$store.dispatch('setMenuOpened', false);
            },
            handleCloseClicked() {
                this.popoverVisible = false;
                this.$emit('blur');
            },
            refine(query) {
                this.$refs.autocomplete.state.refine(query);
            },
            handleInputChanged(e) {
                const query = e.target.value || '';
                this.currentQuery = query ;
                this.loading = true;
                this.refine(this.currentQuery);
            },
            handleInputFocus() {
                if(this.hasResults) {
                    this.popoverVisible = true;
                }
                this.$emit('focus');
            },
            handleInputFocusout(e) {
                const popover = popoverEl(this.$refs.popover);
                if(!popover || !popover.contains(e.relatedTarget)) {
                    this.popoverVisible = false;
                    this.$emit('blur');
                }
            },
            handleInputKeydown(e) {
                if(e.key === "ArrowDown") {
                    if(this.$refs.results) {
                        const firstItem = this.$refs.results.querySelector('.search-result');
                        firstItem && firstItem.focus();
                        e.preventDefault();
                    }
                }
            },
            handleItemKeydown(e) {
                const items = [...this.$refs.results.querySelectorAll('.search-result')];
                const index = items.indexOf(e.target);
                if(e.key === "ArrowDown") {
                    items[mod(index + 1, items.length)].focus();
                    e.preventDefault();
                } else if(e.key === "ArrowUp") {
                    items[mod(index - 1, items.length)].focus();
                    e.preventDefault();
                }
            },
            handlePopoverKeydown(e) {
                if(e.key === "Escape") {
                    this.popoverVisible = false;
                    this.input().focus();
                }
            },
            handlePopoverFocusout(e) {
                if(!this.popoverVisible) {
                    return;
                }
                if(e.target === this.$refs.close) {
                    return;
                }
                if(!e.relatedTarget || this.$refs.results && !this.$refs.results.contains(e.relatedTarget)) {
                    // this.popoverVisible = false;
                    setTimeout(() => {
                        this.input().focus();
                    }, 0);
                }
            },
            handleClickout(e) {
                if(e.target === this.input()) {
                    return;
                }
                if(this.$refs.results && !this.$refs.results.contains(e.target)) {
                    this.popoverVisible = false;
                }
            },
        },
        created() {
            document.addEventListener('touchstart', () => {
                this.showCloseButton = true;
            });

            this.refine = debounce(this.refine, this.debounceDelay);
        },
        mounted() {
            // this.$refs.configure.$destroy();
            document.addEventListener('click', this.handleClickout);

            //dev only
            // if(process.env.NODE_ENV === 'development') {
            //     this.$watch('popoverVisible', visible => !visible && (this.popoverVisible = true), { sync:true, });
            //     this.minLength = 1;
            // }
        },
        destroyed() {
            document.removeEventListener('click', this.handleClickout);
        }
    };
</script>
