<template>
    <div>
        <template v-if="loading">
            <slot />
        </template>
        <template v-else>
            <div class="row">
                <template v-for="order in orders">
                    <div class="col-lg-6 profile-page__col">
                        <div class="card profile-page__card">
                            <div class="card-body">
                                <div class="mb-2"><span class="badge badge-light text-muted">Réf : {{order.ref}}</span></div>
                                <div class="profile-page__date mb-2">
                                    Passée le {{order.date}}
                                    <template v-if="order.store.length > 0">
                                        — {{order.store}}
                                    </template>
                                </div>

                                <table class="table table-light table-responsive-sm mb-0">
                                    <template v-for="item in order.items">
                                        <tr class="trow">
                                            <td width="65%">
                                                <h6 class="bold-title mb-0">
                                                    {{ item.name }}
                                                </h6>
                                                <div class="small text-muted">Réf: {{ item.ref }}</div>
                                            </td>

                                            <td width="20%" class="text-right align-middle">
                                                <strong>{{ item.qty }}</strong>
                                                <template v-if="item.qty > 1">
                                                    <small>x</small>
                                                    <span class="font-family-title">{{ item.price }}</span>
                                                </template>
                                            </td>
                                            <td width="15%" class="text-right align-middle">
                                                <div class="mb-0 h5">
                                                    {{ item.subtotal }}
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    <tr class="trow--footer">
                                        <td colspan="2" class="text-right">
                                            <div class="bold-title">Total : </div>
                                        </td>
                                        <td class="text-right">
                                            <h5 class="mb-0 text-primary bold-title">{{ order.total }}</h5>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
    import { getAccountStoreOrders } from "../api";
    import { alertError } from "../partials/helpers";


    export default {
        data() {
            return {
                orders: [],
                loading: true
            };
        },

        methods: {
            init() {
                this.loading = true;
                getAccountStoreOrders()
                    .then(orders => {
                        this.orders = orders;
                    })
                    .catch(() => {
                        alertError(
                            "Erreur",
                            "Erreur lors de la récupération de l'historique; veuillez recharger la page, et réessayer."
                        );
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },

        created() {
            this.init();
        }
    };
</script>
