<template>
    <div>
        <ais-search-box v-slot="{ currentRefinement, isSearchStalled }" ref="search">
            <form action="" @submit.prevent="handleSubmitted">
                <div class="input-group">
                    <input
                        class="form-control bg-white pl-0 mr-2"
                        name="query"
                        :value="query"
                        autocapitalize="off"
                        autocomplete="off"
                        spellcheck="false"
                        aria-label="Rechercher un article"
                        type="search"
                        placeholder="Marque, désignation, famille de produits, ..."
                        @input="handleInputChanged"
                        ref="input"
                    />
                    <div class="input-group-append">
                        <button class="btn btn-primary px-4" type="submit">Rechercher</button>
                    </div>
                </div>
            </form>
            <template v-if="isSearchStalled">
                <Loading />
            </template>
        </ais-search-box>
        <ais-clear-refinements class="d-none" :excluded-attributes="['query']" ref="clear" />
    </div>
</template>

<script>
    import {AisClearRefinements, AisSearchBox} from 'vue-instantsearch';
    import Loading from '../Loading.vue';

    export default {
        components: {
            AisSearchBox,
            AisClearRefinements,
            Loading,
        },

        data() {
            return {
                query: null,
            }
        },

        methods: {
            handleSubmitted() {
                this.$refs.clear.state.refine();
                this.$refs.search.state.refine(this.query);
                this.$refs.input.blur();
            },
            handleInputChanged(e) {
                this.query = e.target.value;
            },
        },

        mounted() {
            // update from querystring
            this.$refs.search.$watch('state.query', query => {
                this.query = query;
            });
        },
    }
</script>
