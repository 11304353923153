<template>
    <ais-range-input class="filter price-filter" attribute="price" v-slot="{ currentRefinement, range, canRefine }" ref="range">
        <FilterCollapse
            v-show="canRefine"
            id="price"
            label="Prix"
            body-class="px-4 pb-5"
            :collapsed="collapsed && !currentRefinement.min && !currentRefinement.max"
        >
            <PriceSlider
                :value="currentRefinement"
                :range="range"
                :max-interval="5"
                @change="handleChanged"
            />
        </FilterCollapse>
    </ais-range-input>
</template>

<script>
    import {AisRangeInput} from 'vue-instantsearch';
    import PriceSlider from "./PriceSlider.vue";
    import FilterCollapse from "../../FilterCollapse.vue";

    // https://www.algolia.com/doc/api-reference/widgets/range-slider/vue/#examples
    export default {
        components: {
            AisRangeInput,
            PriceSlider,
            FilterCollapse,
        },

        props: {
            interval: {
                type: Number,
                default: 5,
            },
            collapsed: Boolean,
        },

        methods: {
            handleChanged(value) {
                this.$refs.range.refine({
                    min: value.min,
                    max: value.max,
                });
            },
        },
    }
</script>
