<template>
    <div>
        <vue-slider
            :value="transformedValue"
            :min="rangeMin"
            :max="rangeMax"
            :interval="resolvedInterval"
            :duration="0"
            lazy
            silent
            use-keyboard
            process
            :tooltip="mergeTooltips ? 'none' : 'always'"
            tooltip-placement="bottom"
            @change="handleChanged"
            @drag-start="handleDragStart"
            @dragging="handleDragging"
            @drag-end="handleDragEnd"
            @focusout.native="handleFocusout"
            ref="slider"
        >
            <template v-if="mergeTooltips" v-slot:process="{ start, end, style }">
                <div class="vue-slider-process price-filter__process" :style="style">
                    <div class="vue-slider-dot-tooltip vue-slider-dot-tooltip-bottom price-filter__tooltip price-filter__tooltip--merged">
                        {{ tooltipValues[0] }}&nbsp;€ - {{ tooltipValues[1] }}&nbsp;€
                    </div>
                </div>
            </template>
            <template v-slot:tooltip="{ value }">
                <div class="price-filter__tooltip">
                    {{ value }}&nbsp;€
                </div>
            </template>
        </vue-slider>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import VueSlider from 'vue-slider-component';

    // https://nightcatsama.github.io/vue-slider-component/#/
    export default {
        components: {
            VueSlider,
        },

        props: {
            value: {
                type: Object,
                required: true,
            },
            range: {
                type: Object,
                required: true,
            },
            steps: {
                type: Number,
                default: 10,
            },
            maxInterval: {
                type: Number,
                default: 5
            },
        },

        data() {
            return {
                tooltipValues: [],
                mergeTooltips: false,
                dragging: false,
            }
        },

        computed: {
            transformedValue() {
                return [
                    this.value.min != null ? Math.max(this.value.min / 100, this.rangeMin) : this.rangeMin,
                    this.value.max != null ? Math.min(this.value.max / 100, this.rangeMax) : this.rangeMax,
                ];
            },
            rangeMin() {
                const interval = this.resolvedInterval;
                return Math.floor((this.range.min/100) / interval) * interval;
            },
            rangeMax() {
                const interval = this.resolvedInterval;
                return Math.ceil((this.range.max/100) / interval) * interval;
            },
            resolvedInterval() {
                const diff = (this.range.max - this.range.min) / 100;
                return Math.round(Math.min(Math.max(diff / this.steps, 1), this.maxInterval));
            },
        },

        watch: {
            range() {
                this.a11yRange();
            },
            value() {
                this.a11yValueNow();
            }
        },

        methods: {
            updateValue([min, max]) {
                this.$emit('change', {
                    min: Math.max(min * 100, this.range.min),
                    max: Math.min(max * 100, this.range.max),
                });
            },
            handleChanged([min, max]) {
                this.tooltipValues = [min, max];
                if(this.dragging) {
                    this.updateValue([min, max]);
                } else {
                    // debounce on keydown events
                    this.debouncedUpdateValue([min, max]);
                }
            },
            handleDragging(values) {
                this.tooltipValues = values;
                this.a11yValueNow(values);
            },
            handleDragStart() {
                this.dragging = true;
            },
            handleDragEnd() {
                this.dragging = false;
            },
            handleFocusout(e) {
                if(!e.relativeTarget || !this.$el.contains(e.relativeTarget)) {
                    this.$refs.slider.blur();
                }
            },
            a11yBase() {
                const labels = ['Prix minimum', 'Prix maximum'];
                [...this.$el.querySelectorAll('.vue-slider-dot')].forEach((dot, i) => {
                    dot.setAttribute('tabindex', 0);
                    dot.setAttribute('role', 'slider');
                    dot.setAttribute('aria-label', labels[i]);
                    dot.addEventListener('focus', () => {
                        this.$refs.slider.focus(i);
                    });
                });
            },
            a11yValueNow(values = this.transformedValue) {
                const dots = [...this.$el.querySelectorAll('.vue-slider-dot')];
                dots[0].setAttribute('aria-valuenow', values[0]);
                dots[1].setAttribute('aria-valuenow', values[1]);
            },
            a11yRange() {
                [...this.$el.querySelectorAll('.vue-slider-dot')].forEach(dot => {
                    dot.setAttribute('aria-valuemin', this.rangeMin);
                    dot.setAttribute('aria-valuemax', this.rangeMax);
                });
            },
        },

        created() {
            this.debouncedUpdateValue = debounce(this.updateValue, 500);
        },

        mounted() {
            this.a11yBase();
            this.a11yRange();
            this.a11yValueNow();

            this.tooltipValues = this.transformedValue;

            this.$refs.slider.$watch('focusDotIndex', index => {
                const dots = [...this.$el.querySelectorAll('.vue-slider-dot')];
                dots[index].focus();
            });

            this.$refs.slider.$watch('processArray', array => {
                this.mergeTooltips = array[0].end - array[0].start < 15;
            }, { immediate: true });
        }
    }
</script>
