import once from 'lodash/once';

function loadScript(src) {
    const element = document.createElement('script');
    element.src = src;
    document.head.appendChild(element);
    return new Promise((resolve, reject) => {
        element.addEventListener('load', resolve);
        element.addEventListener('error', () => reject(`The script "${src}" failed to load`));
    });
}

export const loadSendcloud = once(async () => {
    await loadScript('https://embed.sendcloud.sc/spp/1.0.0/api.min.js');
});
