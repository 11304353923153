

export function isItemAvailable(item, cart) {
    return isShipFromStore(cart)
        ? item.sfs
        : item.cnc;
}

export function cartActiveItems(cart) {
    return (cart.items || []).filter(item => isItemAvailable(item, cart));
}

// export function cartUnavailableItems(cart) {
//     return (cart.items || []).filter(item => !isItemAvailable(item, cart));
// }

export function isShipFromStore(cart) {
    return cart.delivery_mode === 'sfs';
}

export function isClickAndCollect(cart) {
    return cart.delivery_mode === 'cnc';
}

export function cartTotal(cart) {
    return cart.total;
}

export function isMinimumShippingFees(cart) {
    return cart.shipping_fees?.sfs === 0;
}

export function cartShippingFees(cart) {
    return cart.shipping_fees?.sfs || cart.shipping_fees?.minimum_sfs;
}

export function hasOffer(item) {
    return item.has_offer;
}

export function isOfferApplied(item) {
    return item.offer_applied;
}

export function hasQuantityDiscountOffer(item) {
    return hasOffer(item) && item.offer_name === 'quantity-discount';
}

export function hasPackDiscountOffer(item) {
    return hasOffer(item) && item.offer_name === 'pack-discount';
}

export function hasAdditionalProductOffer(item) {
    return hasOffer(item) && item.offer_name === 'additional-product';
}

export function hasLeastExpensiveIsFreeOffer(item) {
    return hasOffer(item) && item.offer_name === "least-expensive-is-free";
}
