<template>
    <div class="marketing-label marketing-label--circle">
        <svg class="marketing-label__icon" width="50" height="50">
            <use xlink:href="#sprite-label-circle" />
        </svg>
        <div class="marketing-label__text" ref="text">
            {{ label }}
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';

    function hasOverflow(el) {
        return el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight;
    }

    export default {
        props: {
            label: {
                type: String,
                required: true,
            }
        },
        methods: {
            layout() {
                const textEl = this.$refs.text;
                textEl.style.fontSize = '';
                if(hasOverflow(textEl)) {
                    const style = window.getComputedStyle(textEl);
                    let fontSize = parseInt(style.fontSize, 10);

                    while (--fontSize >= 10 && hasOverflow(textEl)) {
                        textEl.style.fontSize = `${fontSize}px`;
                    }
                }
            },
        },
        mounted() {
            this.layout();
            this.handleResize = debounce(this.layout, 200);
            window.addEventListener('resize', this.handleResize);
        },
        beforeDestroy() {
            this.handleResize.cancel();
            window.removeEventListener('resize', this.handleResize);
        }
    }
</script>
