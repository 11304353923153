<template>
    <div class="GiftcardStoreSelector">
        <slot v-bind="this" />
    </div>
</template>

<script>
    import Loading from '../Loading.vue';
    import {findStores, handleFindStoresError} from "../../util/stores";

    export default {
        components: {
            Loading
        },
        props: {
            route: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                query: null,
                stores: null,
                loading: false,
            }
        },
        methods: {
            hasGiftCard(store) {
                return store.is_gift_card && store.allow_card_payments;
            },
            storeUrl(store) {
                return this.hasGiftCard(store) ? `${this.route}/${store.id}` : undefined;
            },
            handleQueryChanged(e) {
                this.query = e.target.value;
            },
            handleQuerySubmitted() {
                this.loading = true;

                findStores({
                    query: this.query
                })
                .then(stores => {
                    this.stores = stores;
                })
                .catch(error => {
                    handleFindStoresError(error);
                }).finally(() => {
                    this.loading = false;
                });
            },
        }
    }
</script>
