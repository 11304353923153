<template>
    <div
        :data-menu="menuId"
        tabindex="-1"
        @focus="handleFocus"
        @focusin="handleFocusin"
        @focusout="handleFocusout"
        @mouseover="handleMouseover"
        @mouseleave="handleMouseleave"
        @keydown="handleKeydown"
    >
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            id: String,
            currentMenu: String,
        },
        data() {
            return {
                hasFocus: false,
            }
        },
        watch: {
            hasFocus(hasFocus) {
                // when the list is focused allow tab navigation
                const navLinks = [...this.$el.querySelectorAll('.nav-link')];
                navLinks.forEach(navLink => {
                    navLink.setAttribute('tabindex', hasFocus ? '0' : '-1');
                });
            },
            currentMenu(currentMenu) {
                if(!currentMenu) {
                    return;
                }
                const navLinks = [...this.$el.querySelectorAll('.nav-link[data-target]')];
                navLinks.forEach(navLink => {
                    navLink.classList.toggle('active', navLink.getAttribute('data-target') === currentMenu);
                });
            },
        },
        computed: {
            menuId() {
                return this.id || '';
            },
        },
        methods: {
            handleFocus() {
                this.$el.querySelector('.nav-link')?.focus();
            },
            handleFocusin() {
                this.hasFocus = true;
            },
            handleFocusout(e) {
                const navLinks = [...this.$el.querySelectorAll('.nav-link')];

                if(!e.relatedTarget) {
                    return;
                }
                if(this.$el.contains(e.relatedTarget)) {
                    return;
                }

                this.hasFocus = false;

                // if the next link is a submenu link, continue
                if(e.relatedTarget.getAttribute('data-menu')) {
                    return;
                }
                // if the next link is the parent menu link, continue
                if(e.relatedTarget.getAttribute('data-target') === this.id) {
                    return;
                }
                if(navLinks.length === 0) {
                    return;
                }

                if(e.target === navLinks[0]) {
                    navLinks[navLinks.length - 1].focus();
                } else if(e.target === navLinks[navLinks.length - 1]) {
                    navLinks[0].focus();
                }
            },
            handleMouseover(e) {
                if(e.target.matches('.nav-link')) {
                    clearTimeout(this.hoverTimeout);
                    // select after mouse hover a certain amount of time to prevent unwanted behavior when moving mouse to submenus
                    if(e.target.matches('[data-target]')) {
                        this.hoverTimeout = setTimeout(() => {
                            const link = e.target;
                            this.$emit('select', this.id, link.getAttribute('data-target'));
                        }, this.hoverTimeout==null ? 0 : 250);
                    }
                }
            },
            handleMouseleave() {
                clearTimeout(this.hoverTimeout);
            },
            handleKeydown(e) {
                if(e.key === 'Escape') {
                    this.$emit('escape', this.id, e);
                    return;
                }
                if(!e.target.matches('.nav-link[data-target]')) {
                    return;
                }
                const link = e.target;
                const submenu = link.getAttribute('data-target');
                if(e.key === 'Enter' && submenu) {
                    e.preventDefault();
                    this.$emit('select', this.id, submenu, { focus:true });
                }
            }
        },
        mounted() {
            //if only one submenu, automatically select the first
            const navLinks = [...this.$el.querySelectorAll('.nav-link[data-target]')];
            if(navLinks.length === 1) {
                this.$emit('select', this.id, navLinks[0].getAttribute('data-target'));
            }
        }
    }
</script>
