import Vue from "vue";
import * as Sentry from '@sentry/vue';
import { HttpClient as HttpClientIntegration } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/tracing";
import { config } from "./config";

if(process.env.NODE_ENV === 'production' && config.sentry.enabled) {
    Sentry.init({
        Vue,
        dsn: config.sentry.dsn,
        environment: config.sentry.env,
        integrations: [
            new HttpClientIntegration({
                failedRequestStatusCodes: [[400, 599]],
                failedRequestTargets: [
                    config.meilisearch.host,
                ],
            }),
            new BrowserTracing({
                tracePropagationTargets: [/^\//, location.host],
            }),
        ],
        ignoreErrors: [
            // ignore cancelled / offline network errors
            'Network Error',
            'Request failed with status code 419',
            'Could not load "stats"',
            'safari-extension',
            "Unexpected token '<'",
            "timeout of 0ms exceeded",
            "Unexpected end of input",
            'Could not load "util"'
        ],
        denyUrls: [
            /maps\.googleapis\.com/i
        ],
    });

    Sentry.setTag('language', 'javascript');
}
