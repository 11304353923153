<template>
    <div class="Cart__message" style="max-width: 22em">
        Aucun de vos articles n'est disponible
        <template v-if="isShipFromStore">
            en livraison, vous pouvez néanmoins <a href="#" @click.prevent="handleDeliveryModeClicked('cnc')">choisir le retrait en magasin</a>
        </template>
        <template v-else>
            en retrait
            <template v-if="storeName">
                à {{ storeName }},
            </template>
            <template v-else>
                en magasin
            </template>
            vous pouvez néanmoins <a href="#" @click.prevent="handleDeliveryModeClicked('sfs')">choisir la livraison à domicile</a>
        </template>
    </div>
</template>

<script>
    import { isShipFromStore } from "../../../util/cart";

    export default {
        props: {
            cart: Object,
            storeName: String,
        },
        computed: {
            isShipFromStore() {
                return isShipFromStore(this.cart);
            },
        },
        methods: {
            handleDeliveryModeClicked(mode) {
                this.$emit('delivery-mode-click', mode);
            }
        }
    }
</script>
