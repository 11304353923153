<template>
    <div class="InterlisteList">
        <slot v-bind="this"></slot>
        <div v-if="message" class="alert alert-danger">
            <div>{{ message }}</div>
        </div>
    </div>
</template>

<script>
    import { api } from "../../api";
    import {formatPrice} from "../../util/price";

    export default {
        name:'InterlisteList',
        props: {
            data: Object,
            orderEndpoint: String,
            orderPageUrl: String
        },
        data() {
            return {
                list: this.data.list,
                order: {
                    products: [],
                    donation: 0,
                    ...this.data.order
                },
                message: null,
                errors: null
            }
        },
        computed: {
            listProducts() {
                return this.list.categories.reduce((res, category) => [...res, ...category.products], []);
            },
            listProductsMap() {
                return this.mapProducts(this.listProducts);
            },
            orderProductsMap() {
                return this.mapProducts(this.order.products);
            },
            orderProductsTotal() {
                return (
                    this.order.products.reduce((res, product) =>
                        res + product.qty * this.listProductsMap[product.id].price,
                    0)
                )
            },
            orderTotal() {
                // x100 price number format
                return this.orderProductsTotal + Number(this.order.donation)*100;
            },
        },
        methods: {
            handleQuantityChanged(id, qty) {
                this.$set(this.orderProductsMap[id],'qty',qty);
            },
            handleSubmit() {
                this.message = null;
                this.errors = null;
                api.post(this.orderEndpoint, this.reduceOrder(this.order))
                    .then(() => {
                        location.href = this.orderPageUrl;
                    })
                    .catch(({ response }) => {
                        if(response.status === 417) {
                            this.message = response.data.error;
                        } else if(response.status === 422) {
                            this.errors = response.data.errors;
                            this.message = 'Commande invalide';
                        } else {
                            this.message = "Impossible d\'effectuer la commande pour le moment, veuillez réessayer plus tard"
                        }
                    })
            },
            mapProducts(products, transform = v=>v) {
                return products.reduce((res, product) => ({ ...res, [product.id]: transform(product) }), {});
            },
            price(raw) {
                return formatPrice(raw);
            },
            reduceOrder({ products, donation }) {
                const donationNumber = Number(donation);
                return {
                    products: products.filter(product => product.qty > 0).map(({ id, qty })=>({ id, qty })),
                    donation: donationNumber || null
                }
            },
            error(field) {
                const error = (this.errors||{})[field];
                return Array.isArray(error) ? error[0] : null;
            }
        },
        created() {
            this.order.products = this.listProducts.map(product => ({
                ...product, qty:0, ...this.orderProductsMap[product.id]
            }));
            this.order.donation /= 100;
        }
    }
</script>
