<template>
    <ais-sort-by
        :items="items"
        v-slot="{ refine, items, currentRefinement }"
    >
        <select
            :class="small
                ? 'sort-select custom-select custom-select-sm border-0 bg-transparent'
                : 'sort-select custom-select'
            "
            @change="refine($event.currentTarget.value)"
        >
            <option
                v-for="item in items"
                :key="item.value"
                :value="item.value"
                :selected="item.value === currentRefinement"
            >
                {{ item.label }}
            </option>
        </select>
    </ais-sort-by>
</template>

<script>
    import { AisSortBy } from 'vue-instantsearch';
    import { scoutIndexName } from "../../util/algolia";

    export default {
        components: {
            AisSortBy,
        },
        props: {
            showBestSales: Boolean,
            small: Boolean,
        },
        computed: {
            items() {
                const items = [];

                if(this.showBestSales) {
                    items.push({
                        value: `${scoutIndexName('products')}:best_sales:desc`,
                        label: 'Meilleures ventes',
                    });
                } else {
                    items.push({
                        value: scoutIndexName('products'),
                        label: 'Pertinence',
                    });
                }

                return [
                    ...items,
                    {
                        value: `${scoutIndexName('products')}:price:asc`,
                        label: 'Prix les moins chers',
                    },
                    {
                        value: `${scoutIndexName('products')}:price:desc`,
                        label: 'Prix les plus chers'
                    },
                ];
            },
        },
    }
</script>
