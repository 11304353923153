<template>
    <a class="search-result search-result--product" :href="item.url">
        <div class="row align-items-center mx-n1 search-result__row">
            <div class="col-md-6 px-1">
                <img class="search-result__image img-fluid" :src="item.thumbnail" :alt="item.name">
            </div>
            <div class="col-md-6 px-1">
                <h3 class="search-result__title w-100 text-md-left">
                    {{ item.name }}
                </h3>
            </div>
        </div>
    </a>
</template>

<script>
    export default {
        props: {
            item: Object,
        },
    }
</script>