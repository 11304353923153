<template>
    <div class="InterlisteThumbnail">
        <slot v-bind="this"></slot>
    </div>
</template>

<script>
    import { api } from "../../api";
    import { showMessage } from "../../partials/helpers";
    import ElUpload from 'element-ui/lib/upload';

    export default {
        props: {
            data: {
                type: Object,
                default: () => ({
                    src:''
                })
            },
            uploadEndpoint: String,
            deleteEndpoint: String,
            maxSize: {
                type: Number,
                default: 8 // Mo
            },
        },
        data() {
            return {
                src: this.data.src,
                isUploading: false,
                progress: 0,
                error: null,
            }
        },
        computed: {
            showImage() {
                return !!this.src && !this.isUploading;
            },
            uploader() {
                return ElUpload
            },
            uploaderProps() {
                return {
                    action: this.uploadEndpoint,
                    onSuccess: this.handleThumbnailSuccess,
                    onProgress: this.handleThumbnailProgress,
                    onError: this.handleThumbnailError,
                    beforeUpload:(file) => {
                        this.error = null;
                        if(file.size / 1024 / 1024 > this.maxSize) {
                            this.error = `Le fichier selectionné est tros volumineux (${this.maxSize} Mo max.)`;
                            return false;
                        }
                        return true;
                    },
                    showFileList: false,
                    headers: {
                        'Accept': 'application/json, text/plain, */*', // send as ajax
                        'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content
                    },
                    accept: '.jpg,.png,.gif'
                }
            },
            progressStyle() {
                return {
                    width: `${this.progress}%`
                }
            }
        },
        methods: {
            handleThumbnailProgress(e) {
                this.isUploading = true;
                this.progress = e.percent || 0;
            },
            handleThumbnailSuccess(res, file) {
                this.src = URL.createObjectURL(file.raw);
                this.$nextTick(() => this.isUploading = false)
            },
            handleThumbnailError(res) {
                this.isUploading = false;
                if (res.status === 422) {
                    let {errors} = JSON.parse(res.message);
                    this.error = errors.file[0];
                } else {
                    this.error = "Impossible de télécharger l'image pour le moment, veillez réessayer plus tard";
                }
            },
            deleteThumbnail() {
                api.delete(this.deleteEndpoint).then(() => {
                    // showMessage('La photo de a été supprimée avec succès', '', {
                    //     type: 'success'
                    // });
                });
                this.src = null;
            },
            handleDeleteClicked() {
                showMessage('Confirmation', 'Êtes-vous sûr de vouloir supprimer la photo actuelle ?',{
                    buttons: [{ text:'Oui', action:this.deleteThumbnail }, {text:'Annuler'}]
                });
            }
        }
    }
</script>
