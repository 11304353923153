<template>
    <div class="cart-item">
        <div class="cart-item__content">
            <div class="row mx-n2 align-items-center">
                <div class="col-4 col-md-2 col-lg-3 col-xl-2 px-2">
                    <a :href="item.url" tabindex="-1">
                        <img class="Cart__item__thumbnail img-fluid" :src="item.thumbnail" :alt="item.name">
                    </a>
                </div>

                <div class="col px-2">
                    <div class="row align-items-center mx-n1 mx-n2">
                        <div class="col-12 col-md-7 px-1 px-sm-2">
                            <div class="position-relative mb-2">
                                <h3 class="Cart__item__name">
                                    <a :href="item.url" class="stretched-link">
                                        {{ item.name }}
                                    </a>
                                </h3>

                                <CartItemAlert class="d-none d-md-block" v-bind="alertProps">
                                    <template v-slot:empty>
                                        <div class="Cart__item__details" v-html="item.details"></div>
                                    </template>
                                </CartItemAlert>
                            </div>
                        </div>

                        <div class="col align-self-start align-self-md-center px-1 px-sm-2 d-md-none mt-2">
                            <template v-if="removable">
                                <button class="Cart__item__remove btn btn-link p-0" :disabled="loading" @click="handleRemoveClicked">
                                    <span class="Cart__item__remove-icon">&times;</span>
                                    retirer<span class="d-none d-sm-inline"> du panier</span>
                                </button>
                            </template>
                        </div>

                        <div class="col-auto col-md-2 px-1 px-sm-2 text-right">
                            <QuantitySelector
                                :disabled="!editable"
                                :loading="loading"
                                :quantity="item.quantity"
                                :steps="item.steps"
                                @change="handleQuantityChanged"
                            >
                                <template v-if="!editable">
                                    <small class="font-weight-normal">x</small>
                                </template>
                                {{ item.quantity }}
                                <template v-if="hasQuantityDiscountOffer(item) && isOfferApplied(item)">
                                    +{{ item.offer_data.qty_modifier }}
                                </template>
                            </QuantitySelector>
                        </div>
                        <div class="col-auto col-md-3 px-1 px-sm-2 text-right">
                            <div class="pr-2">
                                <h3 class="Cart__item__subtotal">
                                    <template v-if="hasPackDiscountOffer(item) && isOfferApplied(item)">
                                        <span class="Cart__item__price--old">{{ item.offer_data.undiscounted_subtotal }}</span>
                                        <span class="Cart__item__price" data-test="price">{{ item.offer_data.subtotal }}</span>
                                    </template>
                                    <template v-else-if="hasLeastExpensiveIsFreeOffer(item) && isOfferApplied(item)">
                                        <span class="Cart__item__price--old">{{ item.offer_data.undiscounted_subtotal }}</span>
                                        <span class="Cart__item__price" data-test="price">{{ item.subtotal }}</span>
                                    </template>
                                    <template v-else>
                                        <span class="Cart__item__price" data-test="price">
                                            {{ item.subtotal }}
                                        </span>
                                    </template>
                                </h3>

                                <template v-if="item.price && !hasMinimumQuantity(item)">
                                    <span class="Cart__item__subtotal__price d-none d-sm-inline">
                                        (unitaire : {{ item.price }})
                                    </span>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-n2">
                <div class="col-4 col-md-2 col-lg-3 col-xl-2 px-2 d-none d-md-block">

                </div>
                <div class="col px-2">
                    <CartItemExtra
                        v-bind="extraProps"
                        @toggle-offer="handleToggleOfferClicked"
                    />
                    <CartItemAlert class="d-md-none" v-bind="alertProps" />
                </div>
            </div>
            <template v-if="removable">
                <div class="d-none d-md-block">
                    <button class="Cart__item__remove btn text-primary p-1" aria-label="Supprimer l'article du panier" @click="handleRemoveClicked">
                        <span class="Cart__item__remove-icon">&times;</span>
                    </button>
                </div>
            </template>
        </div>

        <template v-if="available && hasAdditionalProductOffer(item) && isOfferApplied(item)">
            <div class="Cart__item mt-2">
                <div class="row mx-n2 align-items-center">
                    <div class="col-4 col-md-2 col-lg-3 col-xl-2 px-2">
                        <div class="row align-items-center mx-n2">
                            <div class="col-auto px-2">
                                <span class="text-primary" style="font-size: 2em">+</span>
                            </div>
                            <div class="col px-2">
                                <a :href="item.offer_data.url" tabindex="-1">
                                    <img class="img-fluid" :src="item.offer_data.thumbnail" :alt="item.offer_data.name">
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col px-2">
                        <div class="row align-items-center justify-content-end mx-n1 mx-n2">
                            <div class="col-12 col-md-7 px-1 px-sm-2">
                                <h3 class="Cart__item__name">
                                    <a class="stretched-link" :href="item.offer_data.url">
                                        {{ item.offer_data.name }}
                                    </a>
                                </h3>
                                <div class="Cart__item__details d-none d-md-block" v-html="item.offer_data.details"></div>
                            </div>
                            <div class="col-auto col-md-2 px-1 px-sm-2 text-right">
                                <QuantitySelector disabled>
                                    <small class="font-weight-normal">x</small>1
                                </QuantitySelector>
                            </div>
                            <div class="col-auto col-md-3 px-1 px-sm-2 text-right">
                                <h3 class="Cart__item__subtotal pr-2">
                                    {{ item.offer_data.price }}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import {
        hasAdditionalProductOffer,
        hasLeastExpensiveIsFreeOffer,
        hasPackDiscountOffer,
        hasQuantityDiscountOffer,
        isOfferApplied,
    } from "../../util/cart";
    import QuantitySelector from "../QuantitySelector.vue";
    import CartItemExtra from "./CartItemExtra.vue";
    import CartItemAlert from "./CartItemAlert.vue";


    export default {
        components: {
            QuantitySelector,
            CartItemExtra,
            CartItemAlert,
        },
        props: {
            item: Object,
            editable: Boolean,
            removable: Boolean,
            loading: Boolean,
            alertMessage: String,
            showStockMessage: Boolean,
            available: Boolean,
        },
        computed: {
            extraProps() {
                return {
                    item: this.item,
                    editable: this.editable && this.available,
                }
            },
            alertProps() {
                return {
                    item: this.item,
                    alertMessage: this.alertMessage,
                    showStockMessage: this.showStockMessage,
                }
            }
        },
        methods: {
            hasAdditionalProductOffer,
            hasPackDiscountOffer,
            hasQuantityDiscountOffer,
            hasLeastExpensiveIsFreeOffer,

            isOfferApplied,

            hasMinimumQuantity(item) {
                return item.quantity <= item.steps;
            },

            handleQuantityChanged(qty) {
                this.$emit('change', {
                    ...this.item,
                    old_quantity: this.item.quantity,
                    quantity: qty,
                });
            },
            handleToggleOfferClicked() {
                this.$emit('toggle-offer');
            },
            handleRemoveClicked() {
                this.$emit('remove');
            },
        }
    }
</script>
