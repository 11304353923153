<template>
    <header :class="classes" @animationend="handleAnimationEnd">
        <slot v-bind="this" />
    </header>
</template>

<script>
    import debounce from 'lodash/debounce';

    const isIE = !!document.documentMode;

    export default {
        data() {
            return {
                searchFocused: false,
                sticky: false,
                collapsed: this.shouldBeCollapsed(),
                showCollapse: false,
                height: 0,

                animate: false,
                disappearing: false,
                resizing: false,
            }
        },
        computed: {
            classes() {
                return {
                    'header--search-focused': this.searchFocused,
                    'header--sticky': this.isSticky,
                    'header--nav-collapsed': this.collapsed,
                    'header--animate': this.animate,
                    'header--slide-up': this.disappearing,
                    'header--resizing': this.resizing,
                }
            },
            isSticky() {
                return this.sticky || this.disappearing;
            },
        },
        watch: {
            sticky(sticky) {
                if(sticky) {
                    this.collapseLayout();
                }
            },
        },
        methods: {
            handleAnimationEnd() {
                this.disappearing = false;
                this.animate = false;
            },
            handleSearchFocused() {
                this.searchFocused = true;
                this.showCollapse = false;
            },
            handleSearchBlur() {
                this.searchFocused = false;
            },
            handleCollapseChanged(show) {
                this.showCollapse = show;
            },
            shouldBeCollapsed() {
                return window.innerWidth < 768;
            },
            async layout() {
                await this.collapseLayout();
                await this.heightLayout();
                this.resizing = false;
            },
            async collapseLayout() {
                if(this.shouldBeCollapsed()) {
                    this.collapsed = true;
                    return;
                }
                if(this.sticky) {
                    this.collapsed = false;
                    await this.$nextTick();
                    this.collapsed = [...this.$el.querySelectorAll('[data-nav-overflow]')]
                        .some(nav => nav.scrollWidth > nav.offsetWidth)
                } else {
                    this.collapsed = false;
                }
            },
            async heightLayout() {
                if(isIE) return;
                if(this.viewportWidth === window.innerWidth) {
                    return;
                }
                this.viewportWidth = window.innerWidth;
                const oldSticky = this.sticky;
                this.sticky = false;
                await this.$nextTick();
                this.$el.style.minHeight = '';
                if(this.collapsed) {
                    this.$el.querySelector('.collapse').style.display = 'none';
                }
                this.height = this.$el.offsetHeight;
                this.$el.style.minHeight = `${this.height}px`;
                this.sticky = oldSticky;
                if(this.showCollapse) {
                    this.$el.querySelector('.collapse').style.display = '';
                }
            },
            handleScroll() {
                if(isIE) return;
                const sticky = window.pageYOffset > this.height + 50;
                if(this.sticky !== sticky) {
                    this.sticky = sticky;
                    this.$store.dispatch('setHeaderSticky', sticky);
                    this.animate = true;
                    this.disappearing = !sticky;
                }
                if(!this.sticky && !this.shouldBeCollapsed()) {
                    this.collapsed = false;
                }
                if(window.pageYOffset <= this.height) {
                    this.disappearing = false;
                }
            }
        },
        mounted() {
            const debouncedLayout = debounce(this.layout, 100);
            this.handleResize = () => {
                this.resizing = true;
                debouncedLayout();
            }
            this.layout();
            window.addEventListener('resize', this.handleResize);
            window.addEventListener('scroll', this.handleScroll);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize);
            window.removeEventListener('scroll', this.handleScroll);
        }
    }
</script>
