<template>
    <span :class="classes">
        {{ amount }}€<span v-if="cents" :class="centClasses">{{ cents }}</span>
    </span>
</template>

<script>
    export default {
        props: {
            price: {
                type: [Number, String],
                required: true,
            },
            old: Boolean,
            thin: Boolean,
            inline: Boolean,
        },
        computed: {
            parts() {
                return (this.price || '').split('€');
            },
            amount() {
                if(typeof this.price === 'string') {
                    return this.parts[0];
                }
                return Math.floor(this.price / 100);
            },
            cents() {
                if(typeof this.price === 'string') {
                    return this.parts[1];
                }
                const cents = this.price % 100;
                return cents
                    ? `${cents}`.padStart(2, '0')
                    : null;
            },
            classes() {
                return {
                    'price': !this.inline,
                    'price--current': !this.old,
                    'price--old': this.old,
                    'price--thin': this.thin,
                }
            },
            centClasses() {
                return {
                    'price__cents': !this.inline,
                }
            },
        },
    }
</script>
