import Default from "./Default.vue";
import Circle from "./Circle.vue";
import Stamp from "./Stamp.vue";
import Calendar from "./Calendar.vue";
import Macaroon from "./Macaroon.vue";
import Frame from "./Frame.vue";

export function getMarketingLabelByType(type) {
    if(type === 'stamp') {
        return Stamp;
    } else if(type === 'circle') {
        return Circle;
    } else if(type === 'calendar') {
        return Calendar;
    } else if(type === 'macaroon') {
        return Macaroon;
    } else if(type === 'frame') {
        return Frame;
    } else {
        return Default;
    }
}
