<template>
    <div class="confirm">
        <slot />
    </div>
</template>


<script>
    import { BModal } from 'bootstrap-vue';

    export default {
        components: {
            BModal
        },
        props: {
            text: {
                type: String,
                default: 'Êtes-vous sûr de vouloir effectuer cette action&nbsp;?'
            },
            title: {
                type: String,
                default: 'Confirmation'
            },
            buttonColor: {
                type: String,
                default: 'primary',
            },
            buttonText: {
                type: String,
                default: 'Ok',
            },
            cancelText: {
                type: String,
                default: 'Annuler',
            },
        },
        methods: {
            form() {
                return this.$el.querySelector('form');
            },
            handleSubmitted(e) {
                if(!this.confirmed) {
                    e.preventDefault();
                    const text = this.$createElement('div', { domProps: { innerHTML:this.text } });
                    const title = this.$createElement('span', { domProps: { innerHTML:this.title } });
                    this.$bvModal.msgBoxConfirm([text], {
                        title: [title],
                        size: 'sm',
                        okVariant: this.buttonColor,
                        okTitle: this.buttonText,
                        cancelTitle: this.cancelText,
                        footerClass: 'pt-2',
                        modalClass: 'confirm__modal',
                        centered: true
                    })
                    .then(ok => {
                        if(ok) {
                            this.confirmed = true;
                            e.target.submit();
                        }
                    })
                }
            },
        },
        mounted() {
            const form = this.form();
            if(form) {
                form.addEventListener('submit', this.handleSubmitted);
            } else {
                console.error('no <form> found inside confirmation', this.text);
                console.error(this.$el);
            }
        }
    }
</script>
