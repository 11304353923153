<template>
    <div>
        <template v-if="showCategory">
            <template v-if="isSingleCategory">
                <CategoryMenuFilter :collapsed="modal" :title="category.name" />
            </template>
            <template v-else>
                <CategoryTreeFilter :collapsed="modal" :sort-by="categoriesSortBy" />
            </template>
        </template>
        <div :class="{ 'text-right': modal }">
            <ClearFilters link-class="mb-2" />
        </div>
        <template v-if="showTags">
            <TagsMenuFilter :tags="tags" :collapsed="modal" />
        </template>
        <template v-if="showBrand">
            <CheckboxesFilter attribute="brand" label="Marque" collapsed />
        </template>
        <template v-if="showPrice">
            <PriceFilter collapsed />
        </template>
        <ColorFilter collapsed />
        <CheckboxesFilter attribute="material" label="Matériaux" collapsed />
    </div>
</template>

<script>
    import CategoryMenuFilter from "../filters/CategoryMenu.vue";
    import CategoryTreeFilter from "../filters/category-tree/CategoryTree.vue";
    import PriceFilter from '../filters/price/Price.vue';
    import ColorFilter from '../filters/Color.vue';
    import CheckboxesFilter from "../filters/Checkboxes.vue";
    import ClearFilters from "../ClearFilters.vue";
    import TagsMenuFilter from "../filters/TagsMenu.vue";

    export default {
        components: {
            CategoryMenuFilter,
            CategoryTreeFilter,
            PriceFilter,
            ColorFilter,
            CheckboxesFilter,
            ClearFilters,
            TagsMenuFilter,
        },

        props: {
            showBrand: Boolean,
            showCategory: Boolean,
            showTags: Boolean,
            showPrice: Boolean,
            tags: Array,
            isSingleCategory: Boolean,
            categoriesSortBy: Array,
            modal: Boolean,
            category: Object,
        },
    }
</script>
