
export default {
    inserted(el) {
        el.addEventListener('click', el._inputAnchorListener = e => {
            const href = e.target.getAttribute('href');
            if(!href.match(/^#/)) {
                return;
            }
            e.preventDefault();
            const input = document.querySelector(href);
            if(input) {
                window.scrollBy(0, input.getBoundingClientRect().top - window.innerHeight / 2);
                input.focus();
            }
        });
    },
    unbind(el) {
        el.removeEventListener('click', el._inputAnchorListener);
    }
}
