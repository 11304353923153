<template>
    <div class="PaymentConfirm" :class="{ 'PaymentConfirm--has-method': !!method }">
        <PaymentForm
            v-bind="$attrs"
            :endpoint="resolvedEndpoint"
            :card-payment-endpoint="cardPaymentEndpoint"
            :card-payment-enabled="cardPaymentEnabled"
            v-slot="form"
            @error="handleError"
        >
            <slot
                :method="method"
                :loading="form.loading || loading"
                :validation-error="validationError"
                :card-payment-enabled="cardPaymentEnabled"
                :giftcard="giftcard"
                :handleMethodRadioChanged="handleMethodRadioChanged"
                :handleGiftcardChanged="handleGiftcardChanged"
                :handleLoadingChanged="handleLoadingChanged"
            />
        </PaymentForm>
    </div>
</template>

<script>
    import PaymentForm from './PaymentForm.vue';

    export default {
        inheritAttrs: false,
        components: {
            PaymentForm,
        },
        props: {
            endpoint: String,
            cardPaymentEndpoint: String,
            giftcardPaymentEndpoint: String,
            cardOnly: Boolean,
            paymentMethods: Array,
        },
        data() {
            return {
                method: this.paymentMethods.length === 1
                    ? this.paymentMethods[0]
                    : null,
                errors: {},
                giftcard: null,
                loading: false,
            }
        },
        computed: {
            cardPaymentEnabled() {
                if(this.hasFullGiftcardPayment) {
                    return false;
                }
                return this.method === 'card' || this.cardOnly;
            },
            validationError() {
                const errors = Object.values(this.errors || {});
                return errors.length > 0
                    ? (errors[0] || []).join(', ')
                    : null;
            },
            hasFullGiftcardPayment() {
                return this.giftcard?.transaction_status === 'full_giftcard';
            },
            resolvedEndpoint() {
                if(this.hasFullGiftcardPayment) {
                    return this.giftcardPaymentEndpoint;
                }
                return this.endpoint;
            },
        },
        methods: {
            handleMethodRadioChanged(value) {
                this.method = value;
                this.errors.payment_method = null;
            },
            handleGiftcardChanged(giftcard) {
                this.giftcard = giftcard;
            },
            handleError(errors) {
                this.errors = { ...errors };
            },
            // only disable submit button
            handleLoadingChanged(loading) {
                this.loading = loading;
            }
        }
    }
</script>
