import Vue from 'vue';
import once from 'lodash/once';
import {loadGmapApi} from '../vendor/vue2-google-maps';
import {CRS} from './maps-utils';

export function gmapsKey() {
    return 'AIzaSyARbDzUO3_TQ9LUrqe2iJQ8qdlPt81XA_Q';
}

export const loadGmaps = once((options = {}) => {
    loadGmapApi({
        v: '3',
        key: options.key || gmapsKey(),
    });
    return Vue.$gmapApiPromiseLazy();
});

export function getGmapsStyles() {
    return [{"featureType":"administrative","elementType":"all","stylers":[{"visibility":"on"},{"lightness":33}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2e5d4"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#c5dac6"}]},{"featureType":"poi.park","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":20}]},{"featureType":"road","elementType":"all","stylers":[{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"color":"#c5c6c6"}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#e4d7c6"}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#fbfaf7"}]},{"featureType":"water","elementType":"all","stylers":[{"visibility":"on"},{"color":"#acbcc9"}]}];
}

export function serializeGmapsStyles(styles) {
    const result = [];
    styles.forEach(v => {
        let style = '';
        if (v.stylers.length > 0) { // Needs to have a style rule to be valid.
            style += (v.hasOwnProperty('featureType') ? 'feature:' + v.featureType : 'feature:all') + '|';
            style += (v.hasOwnProperty('elementType') ? 'element:' + v.elementType : 'element:all') + '|';
            v.stylers.forEach(val => {
                const propertyname = Object.keys(val)[0];
                const propertyval = val[propertyname].toString().replace('#', '0x');
                style += propertyname + ':' + propertyval + '|';
            });
        }

        result.push(style);
    });
    return result;
}

export function getBoundsZoomLevel(bounds, { width, height, maxZoom=21 }) {
    const WORLD_DIM = { height: 256, width: 256 };

    function latRad(lat) {
        const sin = Math.sin(lat * Math.PI / 180);
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    const lngDiff = ne.lng() - sw.lng();
    const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    const latZoom = zoom(height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, maxZoom);
}


export function pan(latlng, zoom, x=0, y=0) {
    const point = CRS.latLngToPoint(latlng, zoom);
    point.x += x;
    point.y += y;
    return CRS.pointToLatLng(point, zoom);
}
