<template>
    <div v-if="visible" class="touch-only">
        <slot />
    </div>
</template>

<script>
import {isTouch} from "../util/device";

export default {
        computed: {
            visible() {
                return isTouch();
            }
        },
    }
</script>
