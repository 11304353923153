<template>
    <div class="InterlisteLists">
        <div class="list-group list-group-flush">
            <template v-for="list in formattedLists">
                <a :href="list.url" class="list-group-item list-group-item-action">
                    <div class="row">
                        <div class="col-auto">
                            {{ list.date }}
                        </div>
                        <div class="col d-flex justify-content-center align-items-center">
                            <div v-html="list.label"></div>
                        </div>
                    </div>
                </a>
            </template>
        </div>
    </div>
</template>

<script>
    import {formatDate} from "../../util/date";

    export default {
        props: {
            value: Array
        },
        computed: {
            formattedLists() {
                return this.value.map(list => ({
                    ...list,
                    date: formatDate(list.event_date)
                }))
            }
        }
    }
</script>