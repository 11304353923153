<template>
    <div class="InterlisteHeader">
        <slot v-bind="this" />
        <b-modal v-model="showEditInfoModal" title="Modifier les informations de la liste" hide-footer>
            <template v-if="showEditInfoModal">
                <EditInfo
                    :info="info"
                    :endpoint="infoUpdateEndpoint"
                    @submit="handleInfoSubmitted"
                    @cancel="handleEditCancelled"
                />
            </template>
        </b-modal>
    </div>
</template>

<script>
    import { BModal } from 'bootstrap-vue';
    import EditInfo from './modal/EditInfo.vue';

    export default {
        components: {
            EditInfo,
            BModal,
        },
        props: {
            data: {
                type: Object,
                default:() => ({
                    welcomeText:'',
                    externalUrl:''
                })
            },
            infoUpdateEndpoint: String
        },
        data() {
            return {
                info: {
                    welcomeText: this.data.welcomeText,
                    externalUrl: this.data.externalUrl
                },
                showEditInfoModal: false
            }
        },
        methods: {
            handleEditInfoClicked() {
                this.showEditInfoModal = true;
            },
            handleEditCancelled() {
                this.showEditInfoModal = false;
            },
            handleInfoSubmitted() {
                location.reload();
            },
        }
    }
</script>
