<template>
    <div>
        <template v-for="comment in comments">
            <div class="mb-4">
                <div class="row" :class="{'justify-content-end': !comment.isFromStore}">
                    <div class="col-auto">
                        <div class="card card-light comment" :class="commentClasses(comment)">
                            <div class="card-body">
                                <h4 class="h6 mb-2">
                                    <strong class="mr-2">
                                        {{ commentName(comment) }}
                                    </strong>
                                    {{ comment.date }}
                                </h4>
                                <div v-html="comment.text"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <div class="mt-4 mt-lg-5">
            <div class="card shadow">
                <div class="card-body">
                    <template v-if="readOnly">
                        <div id="comment">
                            <slot name="read-only-message" />
                        </div>
                    </template>
                    <template v-else>
                        <label class="mb-0 font-weight-normal h5 mb-3" for="comment">Écrire à {{ storeName }}</label>
                        <div class="form-group">
                            <b-form-textarea
                                id="comment"
                                v-model="text"
                                :class="{ 'is-invalid border-danger': error }"
                                class="form-control bg-white border border-secondary"
                                max-rows="8"
                                name="comment"
                                no-auto-shrink
                                rows="4"
                            ></b-form-textarea>
                            <template v-if="error">
                                <div class="invalid-feedback">{{ error }}</div>
                            </template>
                        </div>
                        <div class="text-right mt-2">
                            <button :disabled="loading" class="btn btn-primary" @click="handleSendClicked">
                                <template v-if="loading">
                                    <Loading inline />
                                </template>
                                <template v-else>
                                    Envoyer
                                </template>
                            </button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { api } from "../../api";
    import Loading from "../Loading.vue";
    import { BFormTextarea } from 'bootstrap-vue';

    export default {
        components: {
            Loading,
            BFormTextarea,
        },
        props: {
            endpoint: String,
            data: {
                type: Object,
                default: () => ({
                    comments: null,
                }),
            },
            storeName: String,
            userName: String,
            readOnly: Boolean,
        },
        data() {
            return {
                comments: this.data.comments,
                loading: false,
                text: null,
                error: null,
            }
        },
        methods: {
            commentName(comment) {
                return comment.isFromStore ? this.storeName : this.userName;
            },
            commentClasses(comment) {
                return [
                    comment.isFromStore
                        ? 'comment--store'
                        : 'comment--user',
                ]
            },
            handleSendClicked() {
                this.loading = true;
                this.error = null;
                api.post(this.endpoint, { text:this.text })
                    .then(response => {
                        this.text = null;
                        this.error = null;
                        this.comments = response.data.data;
                    })
                    .catch(error => {
                        if(error.response?.status === 422) {
                            const data = error.response.data;
                            this.error = data.errors.text?.[0];
                        }
                        if(!error.request && !error.response) {
                            return Promise.reject(error);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    }
</script>
