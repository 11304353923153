<template>
    <div>
        <template v-if="loading">
            <slot />
        </template>
        <template v-else>
            <template v-if="fidelityNumber">
                <div class="list-group">
                    <div class="list-group-item">
                        Ce compte est lié à la Carte Club n°
                        <strong style="letter-spacing: .05em" class="text-primary">
                            {{ fidelityNumber }}
                        </strong>
                    </div>
                    <div class="list-group-item">
                        <div class="mb-n3">
                            <template v-if="hasFidelityData">
                                <template v-if="cardPoints">
                                    <p>
                                        Vous avez accumulé <strong>{{ cardPoints }} point{{ cardPoints > 1 ? "s" : "" }}</strong> sur cette carte.
                                    </p>
                                </template>
                                <template v-for="credit in credits">
                                    <p>
                                        Vous disposez d'un chèque cadeau d'une valeur
                                        de <strong>{{ credit.value }}</strong> à utiliser
                                        avant le <strong>{{ credit.validity }}</strong>.
                                    </p>
                                </template>
                            </template>
                            <template v-else>
                                <p>
                                    <em>Les informations concernant cette Carte Club ne sont pas disponibles actuellement.</em>
                                </p>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <template v-if="potentialFidelityNumber">
                    <div class="alert alert-warning text-center">
                        Nous avons trouvé une carte club à votre nom, <a class="text-nowrap" :href="registerPotentialUrl">rattachez cette carte&nbsp;&gt;</a>
                    </div>
                </template>
                <template v-else>
                    <div class="list-group-item">
                        La carte club vous donne accès à de <a :href="fidelitePageUrl">nombreux avantages</a>. Si vous n'en possédez pas, n'hesitez pas à la demander en magasin.
                    </div>
                </template>
                <div class="list-group">
                    <div class="list-group-item">
                        Ce compte n'est lié à aucune Carte Club
                    </div>
                    <div class="list-group-item">
                        <a :href="registerUrl" class="btn btn-primary btn-sm">
                            Associer une Carte Club
                        </a>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import Loading from '../Loading.vue';
    import { getFidelityData } from "../../api";

    export default {
        components: {
            Loading,
        },
        props: {
            registerUrl: String,
            registerPotentialUrl: String,
            fidelitePageUrl: String
        },
        data() {
            return {
                data: null,
                error: null,
                loading: false,
            }
        },
        computed: {
            hasFidelityData() {
                return !!this.data?.fidelity;
            },
            fidelityNumber() {
                return this.data?.fidelityNumber;
            },
            potentialFidelityNumber() {
                return this.data?.potentialFidelityNumber;
            },
            cardPoints() {
                return this.data?.fidelity?.card?.points;
            },
            credits() {
                return this.data?.fidelity?.credits;
            },
        },
        methods: {
            init() {
                this.loading = true;
                getFidelityData()
                    .then(data => {
                        this.data = data;
                    })
                    .catch(error => {
                        this.error = `Les informations ne sont pas disponible pour le moment`;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            }
        },
        created() {
            this.init();
        }
    }
</script>
