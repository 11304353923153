<template>
    <div class="add-to-cart">
        <button class="add-to-cart__button btn btn-primary btn-lg px-4 px-md-5" :disabled="loading" @click="handleAddClicked">
            <slot />
        </button>
        <template v-if="loading">
            <Loading class="ml-3" inline />
        </template>
    </div>
</template>

<script>
    import Loading from './Loading.vue';

    export default {
        components: {
            Loading,
        },
        props: {
            loading: Boolean,
        },
        methods: {
            handleAddClicked() {
                this.$emit('click');
            },
        }
    };
</script>
