<script>
    export default {
        data() {
            return {
                ...this.$attrs,
            }
        },
        render(h) {
            return h('div', this.$scopedSlots.default(this));
        },
    }
</script>
