
const earthRadius = 6378137;

const SphericalMercator = {
    R: earthRadius,
    MAX_LATITUDE: 85.0511287798,
    project: function (latlng) {
        const d = Math.PI / 180,
            max = this.MAX_LATITUDE,
            lat = Math.max(Math.min(max, latlng.lat), -max),
            sin = Math.sin(lat * d);

        return {
            x: this.R * latlng.lng * d,
            y: this.R * Math.log((1 + sin) / (1 - sin)) / 2,
        }
    },

    unproject: function (point) {
        const d = 180 / Math.PI;

        return {
            lat: (2 * Math.atan(Math.exp(point.y / this.R)) - (Math.PI / 2)) * d,
            lng: point.x * d / this.R
        };
    },
};

function Transformation(a, b, c, d) {
    this._a = a;
    this._b = b;
    this._c = c;
    this._d = d;
}

Transformation.prototype = {
    transform: function (point, scale) {
        return this._transform({x:point.x, y:point.y}, scale);
    },
    _transform: function (point, scale) {
        scale = scale || 1;
        point.x = scale * (this._a * point.x + this._b);
        point.y = scale * (this._c * point.y + this._d);
        return point;
    },
    untransform: function (point, scale) {
        scale = scale || 1;
        return {
            x: (point.x / scale - this._b) / this._a,
            y: (point.y / scale - this._d) / this._c
        };
    }
};

export function toTransformation(a, b, c, d) {
    return new Transformation(a, b, c, d);
}

export const CRS = {
    latLngToPoint: function (latlng, zoom) {
        const projectedPoint = this.projection.project(latlng),
            scale = this.scale(zoom);

        return this.transformation._transform(projectedPoint, scale);
    },
    pointToLatLng: function (point, zoom) {
        const scale = this.scale(zoom),
            untransformedPoint = this.transformation.untransform(point, scale);

        return this.projection.unproject(untransformedPoint);
    },
    scale: function (zoom) {
        return 256 * Math.pow(2, zoom);
    },
    projection: SphericalMercator,
    transformation: (function () {
        const scale = 0.5 / (Math.PI * SphericalMercator.R);
        return toTransformation(scale, 0.5, -scale, 0.5);
    }())
}
