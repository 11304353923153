<template>
    <div>
        <slot v-bind="this" />
    </div>
</template>

<script>
    export default {
        props: {
            show: Boolean,
            data: {
                type: Object,
                default: ()=>({
                    value: null,
                })
            }
        },
        data() {
            return {
                value: this.data.value,
                showCustomInput: this.show,
                focus: false
            }
        },
        methods: {
            handleRadioChanged(value) {
                this.value = value;
                this.showCustomInput = false;
            },
            handleCustomRadioChanged(show) {
                if(show) {
                    this.value = null;
                }
                this.showCustomInput = show;
                this.focus = show;
            }
        }
    }
</script>