<template>
    <div class="Spinner loading" :class="{ 'Spinner--inline':inline, 'Spinner--white':inverted }">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>
</template>

<script>
    export default {
        props: {
            inline: Boolean,
            inverted: Boolean,
        }
    }
</script>
