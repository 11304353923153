<template>
    <div class="filter category__tree">
        <FilterCollapse id="category" label="Catégories" :collapsed="collapsed">
            <div class="mb-1">
                <ais-clear-refinements :included-attributes="['category']" v-slot="{ createURL, refine, canRefine }">
                    <a
                        class="category-tree__link category-tree__link--root"
                        :class="{ 'category-tree__link--active': !canRefine }"
                        :href="createURL()"
                        @click.prevent="refine"
                    >
                        Tous les articles
                    </a>
                </ais-clear-refinements>
            </div>

            <ais-hierarchical-menu
                :attributes="['category', 'subcategory_trail']"
                :sort-by="sortBy"
                v-slot="{ items, createURL, refine }"
                ref="menu"
            >
                <CategoryTreeList
                    :items="items"
                    :create-url="createURL"
                    :refine="refine"
                    root
                />
            </ais-hierarchical-menu>
        </FilterCollapse>
    </div>
</template>

<script>
    import {AisClearRefinements, AisHierarchicalMenu} from 'vue-instantsearch';
    import FilterCollapse from "../../FilterCollapse.vue";
    import CategoryTreeList from "./CategoryTreeList.vue";

    export default {
        components: {
            AisHierarchicalMenu,
            AisClearRefinements,
            FilterCollapse,
            CategoryTreeList,
        },

        props: {
            collapsed: Boolean,
            sortBy: Array,
        },
    }
</script>
