
export function meta(name, required=true) {
    const meta = document.head.querySelector(`meta[name=${name}]`);
    if(!meta) {
        if(required) {
            throw `can't find meta[name=${name}]`;
        } else {
            console.warn(`can't find meta[name=${name}]`);
        }
    }
    return meta?.getAttribute('content');
}

export function setMeta(name, value) {
    let meta = document.head.querySelector(`meta[name=${name.replace(':', '\\:')}]`);
    if(!meta) {
        meta = document.createElement('meta');
        meta.setAttribute('name', name);
        document.head.appendChild(meta);
    }
    meta.setAttribute('content', value);
}
