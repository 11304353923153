<template>
    <ais-pagination v-slot="{ currentRefinement, isFirstPage, isLastPage, createURL, refine, pages }" ref="pagination">
        <nav v-show="pages.length > 1" aria-label="Pages des produits">
            <ul class="pagination">
                <li class="page-item" :class="{ 'disabled':isFirstPage }">
                    <template v-if="isFirstPage">
                        <span class="page-link">
                            ‹
                        </span>
                    </template>
                    <template v-else>
                        <a :href="createURL(currentRefinement - 1)"
                            class="page-link"
                            @click.prevent="handleClicked(currentRefinement - 1)"
                        >
                            ‹
                        </a>
                    </template>
                </li>
                <template v-for="page in pages">
                    <li class="page-item"
                        :class="{ 'active':page===currentRefinement }"
                        :aria-current="page===currentRefinement ? 'page' : null"
                        :key="page"
                    >
                        <a class="page-link" :href="createURL(page)" @click.prevent="handleClicked(page)">
                            {{ page + 1 }}
                        </a>
                    </li>
                </template>
                <li class="page-item" :class="{ 'disabled':isLastPage }">
                    <template v-if="isLastPage">
                        <span class="page-link">
                            ›
                        </span>
                    </template>
                    <template v-else>
                        <a :href="createURL(currentRefinement + 1)"
                            class="page-link"
                            @click.prevent="handleClicked(currentRefinement + 1)"
                        >
                            ›
                        </a>
                    </template>

                </li>
            </ul>
        </nav>
    </ais-pagination>
</template>

<script>
    import { AisPagination } from 'vue-instantsearch';

    export default {
        components: {
            AisPagination,
        },
        methods: {
            handleClicked(page) {
                this.$refs.pagination.refine(page);
                this.$emit('change', page);
            },
        },
    }
</script>
