<template>
    <div>
        <template v-if="alertMessage">
            <b-collapse appear visible key="alert_message">
                <div class="pt-2">
                    <div class="alert alert-danger Cart__alert mb-0" role="alert" v-html="alertMessage"></div>
                </div>
            </b-collapse>
        </template>
        <template v-else-if="showStockMessage && item.cnc_stock_message">
            <div class="Cart__alert Cart__alert--stock mb-0 pl-3 mt-2" role="alert" v-html="item.cnc_stock_message"></div>
        </template>
        <template v-else>
            <slot name="empty" />
        </template>
    </div>
</template>

<script>
    export default {
        props: {
            item: Object,
            alertMessage: String,
            showStockMessage: Boolean,
        },
    }
</script>
