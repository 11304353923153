import {getCartCount} from "../api";


export const UPDATE_CART = 'UPDATE_CART';
export const SET_CART_UPDATING = 'SET_CART_UPDATING';
export const SET_MENU_OPENED = 'SET_MENU_OPENED';
export const SET_HEADER_STICKY = 'SET_HEADER_STICKY';


export default {
    state: {
        cart: null,
        cartUpdating: false,
        menuOpened: false,
        headerSticky: false,
    },

    mutations: {
        [UPDATE_CART](state, cart) {
            state.cart = cart;
        },
        [SET_CART_UPDATING](state, updating) {
            state.cartUpdating = updating;
        },
        [SET_MENU_OPENED](state, opened) {
            state.menuOpened = opened;
        },
        [SET_HEADER_STICKY](state, sticky) {
            state.headerSticky = sticky;
        },
    },

    actions: {
        getCart({ state, commit, dispatch }) {
            if(state.cartUpdating) {
                return;
            }
            commit(SET_CART_UPDATING, true);
            return getCartCount()
                .then(cart => {
                    dispatch('updateCart', cart);
                })
                .finally(() => {
                    commit(SET_CART_UPDATING, false);
                });
        },
        updateCart({ commit }, cart) {
            commit(UPDATE_CART, cart);
        },
        updateCartStore({ state, commit }, store) {
            commit(UPDATE_CART, {
                ...state.cart,
                store,
            })
        },
        setMenuOpened({ commit }, opened) {
            commit(SET_MENU_OPENED, opened);
        },
        setHeaderSticky({ commit }, sticky) {
            commit(SET_HEADER_STICKY, sticky)
        },
    },
}
