<template>
    <div class="QuickCart">
        <slot :size="currentSize" :store="currentStore" />
    </div>
</template>

<script>
    export default {
        props: {
            size: Number,
            store: Object,
        },
        computed: {
            currentSize() {
                return this.$store.state.cart?.size ?? this.size;
            },
            currentStore() {
                return this.$store.state.cart?.store ?? this.store;
            }
        },
        created() {
            if(!this.$store.state.cart) {
                this.$store.dispatch('getCart');
            }
        },
    };
</script>

