<template>
    <div>
        <template v-if="hasCode">
            <label for="coupon-code">Code promotionnel</label>
            <div class="row align-items-center mx-n2">
                <div class="col-auto px-2">
                    <input id="coupon-code"
                        class="form-control pr-2 mw-100"
                        style="width: 12em"
                        :value="currentCode"
                        disabled
                    >
                </div>
                <div class="col-auto px-2">
                    <a class="btn btn-link btn-sm p-0" style="text-transform: none" href="#" @click.prevent="handleDeleteClicked">
                        supprimer le coupon
                    </a>
                </div>
            </div>
        </template>
        <template v-else>
            <b-form-checkbox class="custom-control--small-label" v-model="active">
                Indiquer un code promotionnel
            </b-form-checkbox>
            <b-collapse :visible="active" @shown="handleCollapseShown">
                <form @submit.prevent="handleSubmitted">
                    <div class="py-3">
                        <label class="sr-only" for="coupon-code">Code promotionnel</label>
                        <div class="input-group">
                            <input id="coupon-code"
                                class="form-control pr-2"
                                :class="{ 'is-invalid': error }"
                                v-model="currentCode"
                                style="max-width: 12em"
                                placeholder="Code coupon"
                                @input="handleChanged"
                                ref="input"
                            >
                            <button type="submit" class="btn btn-primary" :disabled="loading">
                                <template v-if="loading">
                                    <Loading inline></Loading>
                                </template>
                                <template v-else>
                                    Appliquer
                                </template>
                            </button>
                        </div>
                        <template v-if="error">
                            <div class="invalid-feedback d-block">
                                {{ error }}
                            </div>
                        </template>
                    </div>
                </form>
            </b-collapse>
        </template>
    </div>
</template>

<script>
    import {BFormCheckbox} from 'bootstrap-vue';
    import Loading from '../Loading.vue';

    export default {
        components: {
            BFormCheckbox,
            Loading,
        },
        props: {
            code: String,
            error: String,
        },
        data() {
            return {
                active: !!this.code,
                currentCode: this.code,
                loading: false
            }
        },
        watch: {
            code(code) {
                this.currentCode = code;
                this.active = !!code;
            }
        },
        computed: {
            hasCode() {
                return !!this.code;
            }
        },
        methods: {
            handleSubmitted() {
                this.$emit('submit', this.currentCode);
            },
            handleDeleteClicked() {
                this.$emit('delete');
            },
            handleCollapseShown() {
                this.$refs.input.focus();
            },
            handleChanged() {
                this.$emit('change');
            },
        }
    }
</script>
