<template>
    <div class="filter category-menu">
        <FilterCollapse id="tags" label="Thèmes" :collapsed="isCollapsed">
            <ais-clear-refinements :included-attributes="['tags']" v-slot="{ createURL, refine, canRefine }">
                <template v-if="!canRefine">
                    <h2 class="category-menu__link category-menu__link--root category-menu__link--active">
                        Tous les thèmes
                    </h2>
                </template>
                <template v-else>
                    <a class="category-menu__link category-menu__link--root" :href="createURL()" @click.prevent="refine">
                        Tous les thèmes
                    </a>
                </template>
            </ais-clear-refinements>

            <ais-menu attribute="tags" :transform-items="transformItems" :limit="100" v-slot="{ items, createURL, refine }" ref="menu">
                <ul class="category-menu__list">
                    <template v-for="item in items">
                        <li class="category-menu__item" :key="item.value">
                            <template v-if="item.isRefined">
                                <h2 class="category-menu__link category-menu__link--active mb-0">
                                    {{ item.label }}
                                </h2>
                            </template>
                            <template v-else>
                                <a class="category-menu__link" :href="createURL(item.value)" @click.prevent="refine(item.value)">
                                    {{ item.label }}
                                </a>
                            </template>
                        </li>
                    </template>
                </ul>
            </ais-menu>
        </FilterCollapse>
    </div>
</template>


<script>
    import isEqual from 'lodash/isEqual';
    import {AisClearRefinements, AisMenu} from 'vue-instantsearch';
    import FilterCollapse from "../FilterCollapse.vue";

    export default {
        components: {
            AisClearRefinements,
            AisMenu,
            FilterCollapse,
        },

        props: {
            collapsed: Boolean,
            tags: Array,
        },

        data() {
            return {
                items: null,
            }
        },

        computed: {
            isRefined() {
                return (this.items ?? []).some(item => item.isRefined);
            },
            isCollapsed() {
                return this.collapsed && !this.isRefined;
            },
        },

        methods: {
            setItems(items) {
                if(!isEqual(items, this.items)) {
                    this.items = items;
                }
                return items;
            },
            transformItems(items) {
                return this.setItems(
                    (this.tags ?? [])
                        .map(tag => {
                            const item = items.find(item => tag.id === Number(item.value));
                            return item
                                ? { ...item, label: tag.name }
                                : null;
                        })
                        .filter(item => !!item)
                );
            },
        },

    }
</script>
