<template>
    <div class="InterlistOrders">
        <slot v-bind="this"></slot>
    </div>
</template>

<script>
    export default {
        props: {
            data: {
                type: Object,
                default: () => ({
                    orders:[],
                })
            },
            filter: String
        },
        data() {
            return {
                orders: this.data.orders,
                currentFilter: this.filter
            }
        },
        computed: {
            filterRE() {
                return new RegExp(this.currentFilter, 'i');
            },
            filteredOrders() {
                let filteredOrders = !this.currentFilter
                    ? this.orders
                    : this.orders.filter(order =>
                        [order.id, order.from, order.client.first_name, order.client.last_name].some(this.matchesFilter)
                    );
                return filteredOrders.reduce((res, order)=>
                        ({ ...res, [order.id]:order }),
                    {})
            }
        },
        methods: {
            isVisible(id) {
                return !!this.filteredOrders[id];
            },
            matchesFilter(prop) {
                return !!prop && this.filterRE.test(prop);
            },
            handleFilterInput(e) {
                this.currentFilter = e.target.value;
            }
        }
    }
</script>