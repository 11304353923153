<template>
    <div>
        <div class="row mx-n2">
            <div class="col-md-6 px-2">
                <div class="form-group">
                    <label for="lastname">Nom</label>
                    <input id="lastname" class="form-control" type="text" name="lastname" v-model="address.lastname" :class="{ 'is-invalid': error('lastname') }">
                    <div class="invalid-feedback">
                        {{ error('lastname') }}
                    </div>
                </div>
            </div>

            <div class="col-md-6 px-2">
                <div class="form-group">
                    <label for="firstname">Prénom</label>
                    <input id="firstname" class="form-control" type="text" name="firstname" v-model="address.firstname" :class="{ 'is-invalid': error('firstname') }">
                    <div class="invalid-feedback">
                        {{ error('firstname') }}
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="address1">Adresse</label>
            <input id="address1" class="form-control" type="text" name="address1" v-model="address.address1" :class="{ 'is-invalid': error('address1') }">
            <div class="invalid-feedback">
                {{ error('address1') }}
            </div>
        </div>

        <div class="form-group">
            <label for="address2">
                Complément
                <span class="font-weight-normal">(facultatif)</span>
            </label>
            <input id="address2" class="form-control" type="text" name="address2" v-model="address.address2" :class="{ 'is-invalid': error('address2') }">
            <div class="invalid-feedback">
                {{ error('address2') }}
            </div>
        </div>

        <div class="row mx-n2">
            <div class="col-md-4 px-2">
                <div class="form-group">
                    <label for="postal_code">Code postal</label>
                    <input id="postal_code" class="form-control" type="text" name="postal_code" v-model="address.postal_code" :class="{ 'is-invalid': error('postal_code') }">
                    <div class="invalid-feedback">
                        {{ error('postal_code') }}
                    </div>
                </div>
            </div>
            <div class="col-md-8 px-2">
                <CityField @change="handleCityChanged" v-slot="{ handleErrorClicked }">
                    <div class="form-group">
                        <label for="city">Ville</label>
                        <input id="city" class="form-control" type="text" name="city" v-model="address.city" :class="{ 'is-invalid': error('city') }">
                        <div class="invalid-feedback" @click="handleErrorClicked">
                            <div v-html="error('city')"></div>
                        </div>
                    </div>
                </CityField>
            </div>
        </div>

        <div class="mb-3">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group mb-0">
                        <label for="country">Pays</label>

                        <CountrySelect
                            id="country"
                            name="country"
                            v-model="address.country"
                            :allowed-countries="allowedCountries"
                        />

                        <template v-if="error('country')">
                            <div class="invalid-feedback d-block">
                                {{ error('country') }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <template v-if="countryHelp">
                <div class="form-text small">
                    {{ countryHelp }}
                </div>
            </template>
        </div>
    </div>
</template>
<script>
    import CountrySelect from "../CountrySelect.vue";
    import { isValidDeliveryPostalCode } from "../../util/address";
    import CityField from "../CityField.vue";

    export default {
        components: {
            CityField,
            CountrySelect,
        },
        props: {
            value: Object,
            errors: Object,
            allowedCountries: Array,
            countryHelp: String,
            isDelivery: Boolean,
        },
        data() {
            return {
                address: {
                    city: null,
                    ...this.value,
                },
            }
        },
        watch: {
            address: {
                handler: 'handleChanged',
                deep: true,
            }
        },
        methods: {
            handleChanged(address) {
                this.$emit('input', {
                    ...address,
                });
            },
            handleCityChanged(city) {
                this.address.city = city;
                this.$emit('update:errors', {
                    ...this.errors,
                    city: null,
                });
            },
            validate() {
                const errors = {};
                if(this.isDelivery && !isValidDeliveryPostalCode(this.address)) {
                    errors.postal_code = ["La livraison n'est pas disponible dans les DOM-TOM"];
                }
                if(Object.keys(errors).length > 0) {
                    this.$emit('update:errors', errors);
                    return Promise.reject(errors);
                }
            },
            error(name) {
                return this.errors?.[name]?.[0];
            }
        },
    }
</script>
