<template>
    <div>
        <template v-if="isLocallyUnavailable">
            <div class="product-stock product-stock--unavailable">
                indisponible dans ce magasin
            </div>
        </template>
        <template v-else>
            <template v-if="isAvailable">
                <span class="product-stock product-stock--available" :class="classes">disponible</span>
            </template>
            <template v-if="hasLowStock">
                <span class="product-stock product-stock--low" :class="classes">quelques pièces disponibles</span>
            </template>
            <template v-if="isOrderable">
                <span class="product-stock product-stock--out" :class="classes">sur commande</span>
            </template>
            <template v-if="isUnavailable">
                <span class="product-stock product-stock--unavailable" :class="classes">indisponible</span>
            </template>
        </template>
    </div>
</template>

<script>
    export default {
        props: {
            stock: Number,
            stockThreshold: Number,
            orderableWithoutStock: Boolean,
            isLocallyUnavailable: Boolean,
            labelClass: [String, Object, Array],
            badge: Boolean,
        },

        computed: {
            classes() {
                return [
                    this.labelClass, {
                        'product-stock--badge': this.badge,
                    }
                ]
            },
            isAvailable() {
                return this.stock > this.stockThreshold;
            },

            hasLowStock() {
                return this.stock > 0 && this.stock <= this.stockThreshold;
            },

            isUnavailable() {
                return !this.stock && !this.orderableWithoutStock;
            },

            isOrderable() {
                return !this.stock && this.orderableWithoutStock;
            },
        },
    };
</script>
