<template>
    <div class="marketing-label marketing-label--calendar">
        <svg class="marketing-label__icon" width="40" height="40">
            <use xlink:href="#sprite-label-calendar" />
        </svg>
        <div class="marketing-label__text">
            {{ label }}
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: true,
            },
        },
    }
</script>