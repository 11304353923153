<template>
    <select v-model="currentValue" class="form-control" :disabled="isDisabled" @change="handleChanged">
        <option :value="null" disabled>Sélectionnez un pays...</option>
        <template v-for="(name, code) in countries">
            <option :value="code">{{ name }}</option>
        </template>
    </select>
</template>

<script>
    export default {
        props: {
            countries: {
                type: Object,
                default: () => window.countries,
            },
            value: {
                type: String,
                default: 'FR',
            },
            allowedCountries: Array,
            disabled: Boolean,
        },
        data() {
            return {
                currentValue: null,
            }
        },
        computed: {
            filteredCountries() {
                if(!this.allowedCountries?.length) {
                    return this.countries;
                }
                return Object.fromEntries(
                    Object.entries(this.countries)
                        .filter(([code]) => this.allowedCountries.includes(code))
                );
            },
            isDisabled() {
                return this.disabled || Object.keys(this.filteredCountries).length === 1 && this.currentValue;
            },
        },
        methods: {
            findCountry(query) {
                query = (query || '').trim().toLowerCase();
                const [code] = Object.entries(this.countries)
                    .find(([code, name]) => query === name.toLowerCase()) || [];
                return code;
            },
            handleChanged(e) {
                this.$emit('input', e.target.value);
            },
        },
        created() {
            this.currentValue = this.filteredCountries[this.value]
                ? this.value
                : null;

            this.$emit('input', this.currentValue);
        }
    }
</script>
