<template>
    <div>
        <slot v-bind="this" />
    </div>
</template>

<script>
    import { addToCart } from "../../util/product";

    export default {
        props: {
            productId: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                detailsVisible: false,
            }
        },
        methods: {
            handleAddToCartClicked() {
                this.loading = true;
                addToCart({ productId:this.productId, store:this.$store })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            handleDetailsCollapseChanged(visible) {
                this.detailsVisible = visible;
            },
        },
    }
</script>
