<template>
    <div :style="style">
        <slot />
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';

    export default {
        data() {
            return {
                stickyHeaderHeight: 0,
            }
        },
        watch: {
            isHeaderSticky: 'handleHeaderStickyChanged',
        },
        computed: {
            style() {
                return {
                    'position': this.isHeaderSticky ? 'sticky' : 'static',
                    'top': `${this.stickyHeaderHeight}px`,
                }
            },
            isHeaderSticky() {
                return this.$store.state.headerSticky;
            },
        },
        methods: {
            layout() {
                const stickyHeader = document.querySelector('[data-sticky-header="true"]');
                this.stickyHeaderHeight = stickyHeader
                    ? stickyHeader.offsetHeight - 1
                    : 70;
            },
            handleHeaderStickyChanged(sticky) {
                if(sticky) {
                    this.layout();
                }
            },
        },
        async mounted() {
            this.handleResize = debounce(this.layout, 100);
            window.addEventListener('resize', this.handleResize);
            await this.$nextTick();
            this.layout();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize);
        },
    }
</script>
