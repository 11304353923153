<template>
    <Portal to="panel">
        <div class="StorePanel sidepanel" :class="{ 'sidepanel--open':isVisible }">
            <div class="sidepanel-overlay" @click="handleBackdropClicked"></div>
            <transition name="sp-slide">
                <template v-if="isVisible">
                    <div class="StorePanel__content sidepanel-content">
                        <template v-if="loading">
                            <div class="StorePanel__loading">
                                <div class="StorePanel__loading__content">
                                    <div class="Spinner Spinner--white">
                                        <div class="bounce1"></div>
                                        <div class="bounce2"></div>
                                        <div class="bounce3"></div>
                                    </div>
                                    <h3>Chargement en cours</h3>
                                </div>
                            </div>
                        </template>

                        <div class="position-relative pt-4 p-3 h-100 overflow-auto">
                            <button class="close absolute-close p-3" @click="handleCloseClicked">&times;</button>

                            <div class="py-2 pr-4 mb-4">
                                <h2 class="bold-title h4 mb-0">
                                    Choisir un magasin de&nbsp;retrait
                                </h2>
                            </div>

                            <div class="row align-items-center gx-4 gy-3">
                                <div class="col-sm">
                                    <form @submit.prevent="handleQuerySubmitted">
                                        <StoresAutocomplete
                                            :query="query"
                                            @query-change="query = $event"
                                            @input="handleCitySelected"
                                            ref="autocomplete"
                                        />
                                    </form>
                                </div>
                                <div class="col-sm-auto">
                                    <div class="row justify-content-center align-items-center">
                                        <div class="col-auto">
                                            <div class="text-muted">
                                                ou
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <button type="button" class="btn btn-primary btn-block" @click="handleGeolocateButtonClicked">
                                                Me géolocaliser
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="StorePanel__results mt-4.5">
                                <template v-for="store in stores">
                                    <div class="card mb-3">
                                        <div class="card-body">
                                            <h3 class="bold-title StorePanel__store__title"
                                                :class="{ 'text-muted': itemDisabled(store) }"
                                            >
                                                {{ store.full_name }}
                                            </h3>
                                            <div class="position-relative">
                                                <template v-if="store.is_drive">
                                                    <template v-if="store.product">
                                                        <div class="mt-2">
                                                            <StockLabel
                                                                :stock="store.product.stock"
                                                                :stock-threshold="stockThreshold"
                                                                :orderable-without-stock="orderableWithoutStock"
                                                                :is-locally-unavailable="store.product.is_locally_unavailable"
                                                            />
                                                        </div>
                                                    </template>
                                                    <template v-else-if="store.address">
                                                        <div class="StorePanel__store__address">
                                                            {{ store.address.address1 }}
                                                            <template v-if="store.address.address2"><br>{{ store.address.address2 }}</template>
                                                            <br>{{ store.address.postal_code }} {{ store.address.city }}
                                                        </div>
                                                        <hr class="my-2">
                                                    </template>

                                                    <template v-if="store.closed_period_message">
                                                        <div class="alert alert-danger fs-7 mt-3 mb-0" style="opacity: .75" v-html="store.closed_period_message">
                                                        </div>
                                                    </template>

                                                    <template v-if="!itemDisabled(store)">
                                                        <div class="StorePanel__store__opening mt-2">
                                                            {{ store.opening_hours_string }}
                                                        </div>
                                                        <div class="mt-2">
                                                            <button type="button" class="btn btn-primary btn-sm" @click.prevent="handleStoreSelected(store)">
                                                                Choisir ce magasin
                                                            </button>
                                                        </div>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    Service non proposé<br><a :href="store.url">Plus d'informations</a>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </transition>
        </div>
    </Portal>
</template>

<script>
    import { alertError } from "../partials/helpers";
    import { findStores, handleFindStoresError } from "../util/stores";
    import { postUserStore } from "../api";
    import { Portal } from 'portal-vue';
    import StockLabel from "./StockLabel.vue";
    import StoresAutocomplete from "./stores/StoresAutocomplete.vue";

    export default {
        components: {
            StoresAutocomplete,
            StockLabel,
            Portal,
        },
        props: {
            visible: Boolean,
            productId: String,
            stockThreshold: Number,
            orderableWithoutStock: Boolean,
            store: Object,
            updateProfile: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                loading: false,
                query: '',
                stores: null
            };
        },

        computed: {
            isVisible() {
                return this.visible;
            },
        },

        watch: {
            isVisible() {
                if(this.isVisible) {
                    this.handleShow();
                }
            },
        },

        methods: {
            itemDisabled(store) {
                if(store.closed_period_message) {
                    return true;
                }
                if(store.product) {
                    return !store.product.stock && !this.orderableWithoutStock
                        || store.product.is_locally_unavailable
                }
                return false;
            },

            handleQuerySubmitted() {
                this.search({ query:this.query });
            },

            handleCitySelected({ lat, lng }) {
                this.search({ lat, lng });
            },

            handleShow() {
                if(this.store) {
                    this.search({
                        lat: this.store.pos_lat,
                        lng: this.store.pos_lng,
                    });
                } else {
                    setTimeout(() => this.$refs.autocomplete.focus(), 200);
                }
            },

            handleGeolocateButtonClicked() {
                navigator.geolocation.getCurrentPosition(position => {
                    this.query = 'Ma localisation';
                    this.search({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                }, () => {
                    alertError(
                        'Géolocalisation indisponible',
                        'Imposible de vous localiser automatiquement ; veuillez utiliser la localisation par code postal ou ville.'
                    );
                }, { maximumAge:600000, timeout:5000 });
            },

            handleBackToSearchButtonClicked() {
                this.stores = null;
            },

            async handleStoreSelected(store) {
                if(this.updateProfile) {
                    await this.updateUserStore(store.id);
                }

                this.$emit('select', store);
                this.hide(true);
            },

            search({ query, lat, lng }) {
                this.loading = true;

                findStores({
                    query,
                    lat,
                    lng,
                    productId: this.productId,
                })
                .then(stores => {
                    this.stores = stores;
                })
                .catch(error => {
                    console.error(error);
                    handleFindStoresError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
            },

            updateUserStore(storeId) {
                this.loading = true;

                return postUserStore(storeId)
                    .catch(e => {
                        alertError(
                            "Erreur",
                            "Erreur lors de la mise à jour de votre profil ; veuillez recharger la page, et réessayer."
                        );
                        return Promise.reject(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },

            handleBackdropClicked() {
                this.hide(false);
            },

            handleCloseClicked() {
                this.hide(false);
            },

            hide(selected) {
                this.$emit('hide', selected);
                this.$emit('update:visible', false);
                this.loading = false;
                this.query = '';
                this.stores = null;
            }
        },
    };
</script>

