<template>
    <div class="product-list">
        <div class="product-list__container container">
            <template v-if="!ready">
                <slot />
            </template>

            <div class="d-lg-none">
                <slot name="prepend-tiles" />
            </div>

            <ais-instant-search
                :search-client="searchClient"
                :search-function="searchFunction"
                :index-name="indexName"
                :routing="routingOptions"
                ref="instantsearch"
            >
                <ais-configure v-bind="searchParameters" ref="configure" v-once />

                <ais-state-results v-slot="state">
                    <ais-clear-refinements v-slot="{ canRefine }">
                        <div :data-search-recorded="recordSearch({ state, hasRefinements: canRefine })+''" aria-hidden="true">
                        </div>
                    </ais-clear-refinements>
                </ais-state-results>

                <FiltersTopBar class="product-list__top-bar d-lg-none mb-3 pb-1 pt-3">
                    <div class="row align-items-center mx-n2">
                        <div class="col d-flex px-2">
                            <SortSelect small :show-best-sales="sortBestSales" />
                        </div>
                        <div class="col-auto px-2">
                            <ais-clear-refinements v-slot="{ canRefine }">
                                <button class="btn product-list__filter-btn" @click="showFiltersModal = true">
                                    <template v-if="canRefine">
                                        <span class="bullet"></span>
                                    </template>
                                    Filtrer&nbsp;&nbsp;&gt;
                                </button>
                            </ais-clear-refinements>
                            <b-modal
                                v-model="showFiltersModal"
                                title="Filtrer"
                                footer-class="justify-content-center border-top position-sticky bg-white bottom-0 shadow-lg z-10"
                                ok-title="Appliquer"
                                centered
                                ok-only
                                static
                            >
                                <ProductFilters v-bind="filtersProps" modal />
                            </b-modal>
                        </div>
                    </div>
                </FiltersTopBar>

                <div class="row">
                    <div class="col-lg-3 d-none d-lg-block">
                        <ProductFilters class="mb-4" v-bind="filtersProps" />

                        <slot name="filter-tiles" />
                    </div>
                    <div class="col-lg-9">
                        <div class="d-none d-lg-block">
                            <slot name="prepend-tiles" />
                            <template v-if="$slots['prepend-tiles']">
                                <hr class="my-4">
                            </template>
                        </div>

                        <template v-if="searchable || sortable">
                            <div class="row mb-3 d-none d-lg-flex flex-nowrap align-items-center">
                                <template v-if="searchable">
                                    <div class="col-lg">
                                        <ProductSearchForm class="d-none" />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col px-0 d-none d-lg-block"></div>
                                </template>
                                <template v-if="sortable">
                                    <div class="col-auto">
                                        <SortSelect :show-best-sales="sortBestSales" />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col px-0"></div>
                                </template>
                            </div>
                        </template>

                        <ais-hits v-slot="{ items }">
                            <ais-state-results v-slot="state">
                                <!-- to check if some filters have been selected -->
                                <ais-clear-refinements
                                    :excluded-attributes="hasRefinementsExcludedAttributes"
                                    v-slot="{ canRefine }"
                                >
                                    <div>
                                        <template v-if="!items.length && hasInitialState({ state, hasRefinements: canRefine })">
                                            <slot name="empty" />
                                        </template>
                                        <template v-else>
                                            <slot
                                                name="row"
                                                :items="transformItems(items)"
                                                :compact="isCompactMode(state)"
                                                :show-tile="hasInitialState({ state, hasRefinements: canRefine })"
                                            />
                                        </template>
                                    </div>
                                </ais-clear-refinements>
                            </ais-state-results>
                        </ais-hits>

                        <Pagination @change="handlePageChanged" />

                        <div class="mt-5 d-lg-none">
                            <slot name="filter-tiles" />
                        </div>
                    </div>
                </div>
            </ais-instant-search>
        </div>
    </div>
</template>

<script>
    import { history } from 'instantsearch.js/es/lib/routers';
    import { singleIndex } from 'instantsearch.js/es/lib/stateMappings';
    import { AisConfigure, AisHits, AisInstantSearch, AisStateResults, AisClearRefinements } from 'vue-instantsearch';
    import { BModal, VBModal } from 'bootstrap-vue';

    import { algoliaHelper, meilisearchClient, nextResults, productStoreFilter, scoutIndexName, throwOnError, } from "../../util/algolia";
    import { algoliaCategoryRouter, appendProductsToMetaDescription } from "../../util/seo";

    import Pagination from './Pagination.vue';
    import ProductSearchForm from './ProductSearchForm.vue';
    import ProductFilters from './ProductFilters.vue';
    import SizeObserver from "../SizeObserver.vue";
    import SortSelect from '../filters/SortSelect.vue';
    import FiltersTopBar from "./FiltersTopBar.vue";
    import CompactToggle from '../filters/CompactToggle.vue';
    import ProductRow from "./ProductRow.vue";
    import vStickyAside from '../../directives/stickyAside';
    import { api } from "../../api";

    export default {
        components: {
            ProductRow,
            AisInstantSearch,
            AisHits,
            AisConfigure,
            AisStateResults,
            AisClearRefinements,
            // AisCurrentRefinements,

            BModal,

            Pagination,
            ProductSearchForm,
            ProductFilters,
            SizeObserver,
            SortSelect,
            FiltersTopBar,
            CompactToggle,
        },

        props: {
            categoryId: String,
            brandId: String,
            tagId: String,
            supplierId: String,
            discountId: String,
            storeId: String,
            searchQuery: String,

            perPage: {
                type: Number,
                default: 48,
            },
            perPageCompact: {
                type: Number,
                default: 60,
            },

            routing: {
                type: Boolean,
                default: true,
            },

            searchable: Boolean,
            sortable: {
                type: Boolean,
                default: true,
            },
            sortBestSales: Boolean,
            filterSales: Boolean,

            scrollContainer: String,

            showCategoryMenu: Boolean,
            metaDescriptionProducts: Boolean,
            categoryRouting: Boolean,
            category: Object,
            categoriesSortBy: Array,

            showTagsMenu: Boolean,
            tags: Array,

            showCompactToggle: {
                type: Boolean,
                default: true,
            },

            data: String,
            analyticsTags: Array,
        },

        data() {
            return {
                searchClient: {
                    search: this.clientSearch,
                },
                ready: false,
                hydrated: false,
                showFiltersModal: false,
            }
        },

        computed: {
            indexName() {
                return this.sortBestSales
                    ? `${scoutIndexName('products')}:best_sales:desc`
                    : scoutIndexName('products');
            },
            routingOptions() {
                if(!this.routing) {
                    return null;
                }
                const router = this.categoryRouting
                    ? algoliaCategoryRouter(this.category)
                    : history();

                return {
                    router,
                    stateMapping: singleIndex(this.indexName),
                }
            },
            searchParameters() {
                return {
                    filters: this.filters,
                    hitsPerPage: this.perPage,
                    analyticsTags: this.analyticsTags,
                }
            },
            idFilter() {
                if(this.categoryId) {
                    return `filter_category = ${this.categoryId}`;
                } else if(this.brandId) {
                    return `filter_brand = ${this.brandId}`;
                } else if(this.tagId) {
                    return `filter_tags = ${this.tagId}`;
                } else if(this.supplierId) {
                    return `filter_supplier = ${this.supplierId}`;
                } else if(this.discountId) {
                    return `filter_discounts = ${this.discountId}`;
                }
            },
            filters() {
                return [
                    productStoreFilter(this.storeId),
                    this.idFilter,
                    this.filterSales && this.storeId
                        ? `filter_in_sales_stores = ${this.storeId}`
                        : null,
                ]
                .filter(f => !!f)
                .join(' AND ');
            },
            filtersProps() {
                return {
                    showCategory: this.showCategoryMenu,
                    showBrand: !this.brandId,
                    showTags: this.showTagsMenu,
                    showPrice: !this.filterSales,
                    tags: this.tags,
                    isSingleCategory: !!this.categoryId,
                    perPage: this.perPage,
                    perPageCompact: this.perPageCompact,
                    categoriesSortBy: this.categoriesSortBy,
                    category: this.category,
                }
            },
            hasRefinementsExcludedAttributes() {
                return this.categoryId ? ['category'] : []; // show tiles for category pages
            },
        },

        watch: {
            searchParameters: 'handleSearchParametersChanged',
        },

        methods: {
            hasInitialState({ state, hasRefinements }) {
                return state.page === 0 && !hasRefinements;
            },
            recordSearch({ state, hasRefinements }) {
                if(this.recorded != null) {
                    return this.recorded;
                }
                if(this.searchable
                    && this.searchQuery?.length > 2
                    && !hasRefinements
                    && state.page === 0
                ) {
                    this.ready = !!state.nbHits;
                    api.post('/record-search', {
                        query: this.searchQuery,
                        has_results: state.nbHits > 0,
                        results_count: state.nbHits || null,
                    }).finally(() => {
                        if(!state.nbHits) {
                            location.replace('/rechercher/aucun-resultat'+location.search);
                        }
                    });
                    return this.recorded = true;
                }
                this.ready = true;
                return this.recorded = false;
            },
            // called before each state change
            searchFunction(helper) {
                // to allow sentry debug
                throwOnError(this.$refs.instantsearch);
                helper.search();
            },
            // returns algolia results
            async clientSearch(requests) {
                const client = meilisearchClient();
                return client.search(requests)
                    .then(results => {
                        this.handleFilterChanged();
                        return results;
                    });
            },
            async scrollToTop() {
                await this.$nextTick();
                const scrollContainer = this.scrollContainer
                    ? document.querySelector(this.scrollContainer)
                    : this.$el;
                if(scrollContainer?.getBoundingClientRect().top < 0) {
                    window.scrollBy(0, scrollContainer?.getBoundingClientRect().top - 80);
                }
            },
            async handlePageChanged() {
                await nextResults(this.$refs.instantsearch);
                this.scrollToTop();
            },
            handleFilterChanged() {
                if(window.innerWidth < 576) {
                    this.scrollToTop();
                }
            },
            handleSearchParametersChanged() {
                const helper = algoliaHelper(this.$refs.instantsearch);
                if(helper) {
                    helper.setState({
                        ...helper.state,
                        ...this.searchParameters,
                    });
                }
            },
            transformItems(items) {
                return items.map(item => {
                    if(item.filter_is_local) {
                        const undiscountedPrice = item.store_undiscounted_local_prices?.[this.storeId];
                        const price = item.store_local_prices[this.storeId];
                        return {
                            ...item,
                            price,
                            undiscounted_price: undiscountedPrice,
                            has_discounted_price: price < undiscountedPrice,
                        };
                    }
                    return item;
                });
            },
            isCompactMode(state) {
                return state.hitsPerPage === this.perPageCompact;
            },
        },

        async mounted() {
            // destroy configure to prevent unnecessary calls
            // this.$refs.configure.$destroy();
            await this.$nextTick();
            const results = await nextResults(this.$refs.instantsearch);
            if(this.metaDescriptionProducts) {
                appendProductsToMetaDescription(results.hits);
            }
        },

        directives: {
            'b-modal': VBModal,
            'sticky-aside': vStickyAside,
        },
    }
</script>
