<template>
    <div class="StoresMap" @click="handleMapClicked">
        <template v-if="showInteractive">
            <GmapMap class="h-100"
                :center="center"
                :zoom="currentZoom"
                :options="options"
                ref="map"
                @idle="handleMapIdle"
            >
                <template v-for="marker in markers">
                    <GmapMarker
                        :position="marker.position"
                        :key="marker.id"
                        @click="handleMarkerClicked(marker)"
                    >
                        <GmapInfoWindow :opened="isMarkerSelected(marker)" @closeclick="handleInfoWindowClosed">
                            <div class="StoresMap__info-window">
                                <slot name="infowindow">
                                    <StoreItem class="store-item--infowindow" :store="marker.store" name-tag="h3" />
                                </slot>
                            </div>
                        </GmapInfoWindow>
                    </GmapMarker>
                </template>
            </GmapMap>
        </template>
        <template v-else>
            <img class="w-100 h-100 shadow-sm object-fit-cover"
                :src="staticMapUrl"
                alt="Google maps"
                @load="handleStaticMapLoaded"
            >
            <div class="StoresMap__overlay d-flex align-items-center justify-content-center text-white p-3">
                <span class="mr-2">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" style="fill:currentColor"><g><path fill-rule="evenodd" d="M6.5 8a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1H6a.5.5 0 0 0 .5-.5z"/><path fill-rule="evenodd" d="M3.854 5.646a.5.5 0 0 0-.708 0l-2 2a.5.5 0 0 0 0 .708l2 2a.5.5 0 0 0 .708-.708L2.207 8l1.647-1.646a.5.5 0 0 0 0-.708zM9.5 8a.5.5 0 0 1 .5-.5h4.5a.5.5 0 0 1 0 1H10a.5.5 0 0 1-.5-.5z"/><path fill-rule="evenodd" d="M12.146 5.646a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L13.793 8l-1.647-1.646a.5.5 0 0 1 0-.708zM8 9.5a.5.5 0 0 0-.5.5v4.5a.5.5 0 0 0 1 0V10a.5.5 0 0 0-.5-.5z"/><path fill-rule="evenodd" d="M5.646 12.146a.5.5 0 0 0 0 .708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8 13.793l-1.646-1.647a.5.5 0 0 0-.708 0zM8 6.5a.5.5 0 0 1-.5-.5V1.5a.5.5 0 0 1 1 0V6a.5.5 0 0 1-.5.5z"/><path fill-rule="evenodd" d="M5.646 3.854a.5.5 0 0 1 0-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8 2.207L6.354 3.854a.5.5 0 0 1-.708 0z"/></g></svg>
                </span>
                Cliquez pour intéragir avec la carte
            </div>
        </template>

        <template v-if="loading">
            <slot />
        </template>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import { InfoWindow as GmapInfoWindow, Map as GmapMap, Marker as GmapMarker } from '../../vendor/vue2-google-maps';
    import qs from 'qs';
    import {
        getBoundsZoomLevel,
        getGmapsStyles,
        gmapsKey,
        loadGmaps,
        pan,
        serializeGmapsStyles
    } from "../../util/gmaps";
    import StoreItem from './StoreItem.vue';


    export default {
        props: {
            stores: Array,
            zoom: Number,
            zoomToMarker: Boolean,
            // width in which scroll zoom is allowed
            scrollableMinWidth: {
                type: Number,
                default: Infinity,
            },
            static: Boolean,
        },
        components: {
            GmapMap,
            GmapMarker,
            GmapInfoWindow,
            StoreItem,
        },
        data() {
            return {
                ready: false,
                loading: true,
                showInteractive: !this.static,
                selectedMarker: null,
                bounds: null,
                currentZoom: this.zoom ?? 5,
                initialStores: [
                    ...(this.stores ?? [])
                ],
                viewportWidth: window.innerWidth,
                containerWidth: 0,
                containerHeight: 0,
            }
        },
        watch: {
            markers: 'updateBounds'
        },
        computed: {
            options() {
                return {
                    styles: getGmapsStyles(),
                    gestureHandling: this.viewportWidth >= this.scrollableMinWidth
                        ? 'greedy'
                        : 'auto',
                    streetViewControl: false,
                    fullscreenControl: false,
                    mapTypeControl: false,
                }
            },
            center() {
                return { lat:46.1445458, lng:2.4343779 };
            },
            markers() {
                return (this.stores ?? [])
                    .filter(store => !!store.pos_lat)
                    .map(store => ({
                        id: store.id,
                        position: {
                            lat: Number(store.pos_lat),
                            lng: Number(store.pos_lng)
                        },
                        store
                    }));
            },
            isInitial() {
                return this.stores.length === this.initialStores.length;
            },
            icon() {
                return {
                    path: 'M22-48h-44v43h16l6 5 6-5h16z',
                    scale: 1,
                    strokeWeight: 0.5,
                    strokeColor: '#000000',
                    strokeOpacity: 1,
                    fillColor: '#ffffff',
                    fillOpacity: 1
                }
            },
            staticMapUrl() {
                if(!this.ready && !this.zoom || !this.containerWidth) {
                    return null;
                }
                const width = Math.round(this.containerWidth);
                const height = Math.round(this.containerHeight);
                const { center, zoom } = this.getCoordinates({ width, height });
                const params = {
                    markers: 'size:medium|' + this.markers.map(marker => `${marker.position.lat},${marker.position.lng}`).join('|'),
                    size: `${width}x${height}`,
                    key: gmapsKey(),
                    format: 'jpg',
                    scale: window.devicePixelRatio > 1 ? 2 : 1,
                    center: `${center.lat},${center.lng}`,
                    zoom,
                    style: serializeGmapsStyles(getGmapsStyles()),
                }
                const query = qs.stringify(params, { indices: false });
                return `https://maps.googleapis.com/maps/api/staticmap?${query}`;
            },
        },
        methods: {
            isMarkerSelected(marker) {
                return this.selectedMarker && this.selectedMarker.id === marker.id;
            },
            handleMarkerClicked(marker) {
                this.selectedMarker = marker;
            },
            handleInfoWindowClosed() {
                this.selectedMarker = null;
            },
            handleMapIdle() {
                this.loading = false;
            },
            async handleMapClicked() {
                if(this.showInteractive) {
                    return;
                }
                this.showInteractive = true;
                await this.$nextTick();
                await this.$gmapApiPromiseLazy();
                this.updateBounds();
            },
            handleStaticMapLoaded() {
                this.loading = false;
            },
            updateView({ padding, strict }={}) {
                if(!this.showInteractive) {
                    return;
                }
                const map = this.$refs.map.$mapObject;
                map.fitBounds(this.bounds, strict ? 0 : padding);

                google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
                    if(!strict) {
                        // marker size correction
                        map.panBy(0, -20);
                    }
                    map.setZoom(this.zoom || Math.min(map.getZoom(), 13));
                });
            },
            updateBounds() {
                if(!this.ready) {
                    return;
                }
                if(this.isInitial && !this.zoomToMarker) {
                    this.bounds = { north:51, south:41.2611155, east:9.8282225, west:-5.4517733 };
                    this.updateView({ strict:true });
                }
                else if(this.markers.length > 0) {
                    this.bounds = new google.maps.LatLngBounds();
                    this.markers.forEach(marker => this.bounds.extend(marker.position));
                    this.updateView({ padding:20 });
                }
            },
            getCoordinates({ width, height }) {
                if(this.markers.length === 1 && this.zoom) {
                    return {
                        center: this.markers[0].position,
                        zoom: this.zoom,
                    }
                }
                const { south, west, north, east } = this.bounds
                const bounds = north ? new google.maps.LatLngBounds({ lat:south, lng:west }, { lat:north, lng:east }) : this.bounds;
                const center = bounds.getCenter().toJSON();
                const zoom = getBoundsZoomLevel(bounds, { width, height, maxZoom: 13 });
                return {
                    center: pan(center, zoom, 0, -20),
                    zoom,
                }
            },
            init() {
                this.ready = true;
                this.updateBounds();
            },
        },
        created() {
            loadGmaps();
            window.addEventListener('resize', this.handleResize = debounce(() => {
                this.viewportWidth = window.innerWidth;
            }, 100));
        },
        async mounted() {
            this.containerWidth = this.$el.offsetWidth;
            this.containerHeight = this.$el.offsetHeight;
            await this.$gmapApiPromiseLazy();
            this.init();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        }
    }
</script>
