<template>
    <div>
        <template v-if="isTouch">
            <input
                :id="id"
                :name="name"
                type="date"
                class="form-control"
                :class="inputClass"
                placeholder="JJ Mois AAAA"
                ref="input"
            >
        </template>
        <template v-else>
            <Datepicker
                :id="id"
                :value="date"
                :input-class="inputClass"
                language="fr"
                placeholder="JJ Mois AAAA"
                bootstrap-styling
                v-bind="$attrs"
                @input="handleInput"
            />
            <input class="d-none" type="text" :value="formattedDate" :name="name">
        </template>
    </div>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';

    function pad(n) {
        return n<10?`0${n}`:`${n}`;
    }

    export default {
        inheritAttrs: false,
        props: {
            id: String,
            value: String,
            error: String,
            name: String,
            inputClass: [String, Object],
        },
        components: {
            Datepicker
        },
        data() {
            return {
                date: this.value ? new Date(this.value) : null,
                isTouch: false,
            }
        },
        computed: {
            formattedDate() {
                return this.date ? `${this.date.getFullYear()}-${pad(this.date.getMonth()+1)}-${pad(this.date.getDate())}` : null;
            }
        },
        methods: {
            handleInput(date) {
                date.setHours(0,0,0,0);
                this.date = date;
                this.$emit('input', this.formattedDate);
            },
            handleWindowTouchStart() {
                this.isTouch = true;
            },
        },
        mounted() {
            window.addEventListener('touchstart', this.handleWindowTouchStart);
        },
        destroyed() {
            window.removeEventListener('touchstart', this.handleWindowTouchStart);
        }
    }
</script>