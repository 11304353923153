<template>
    <div>
        <slot v-bind="this" />
    </div>
</template>

<script>
    import {alertError, showMessage} from "../../partials/helpers";
    import {postUserStore} from "../../api";

    export default {
        props: {
            store: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                selected: false
            }
        },
        methods: {
            handleChooseAsUserStoreClicked() {
                this.loading = true;
                postUserStore(this.store.id)
                    .then(() => {
                        this.selected = true;
                        showMessage(`C’est fait !`, `Votre magasin préféré est desormais ${this.store.full_name}`, {
                            type: 'success',
                            buttons: [{ text: 'Fermer' }]
                        });
                    })
                    .catch(e => {
                        alertError(
                            "Erreur",
                            "Erreur lors de la mise à jour de votre profil ; veuillez recharger la page, et réessayer."
                        );
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            }
        }
    }
</script>
