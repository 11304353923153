
export function forcePopoverPosition(shownEvent) {
    const popper = shownEvent.vueTarget.$_tip.$_popper;
    const flipModifier = popper.modifiers.find(modifier => modifier.name === 'flip');
    const preventOverflowModifier = popper.modifiers.find(modifier => modifier.name === 'preventOverflow');
    const onUpdate = popper.options.onUpdate;

    flipModifier.enabled = false;
    preventOverflowModifier.escapeWithReference = true;

    // console.log(popper);

    popper.options.onUpdate = (data) => {
        onUpdate(data);
        if(/^bottom/.test(popper.options.placement)) {
            popper.popper.style.marginBottom = `${Math.floor(data.offsets.popper.top)}px`;
        }
    };

    popper.scheduleUpdate();
}

export function popoverEl(popoverComponent) {
    const tip = popoverComponent.$_toolpop.$_tip;
    return tip ? tip.$el : null;
}
