import once from 'lodash/once';
import { config } from "./config";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";

export const meilisearchClient = once(() => createMeilisearchClient());

function createMeilisearchClient(options = {}) {
    const host = config.meilisearch.host;
    const apiKey = config.meilisearch.search_only_key;

    return instantMeiliSearch(host, apiKey, {
        finitePagination: true,
        // matchingStrategy: 'all', // force results to match all words
        ...options
    });
}

export function scoutIndexName(name) {
    return `${config.scout.prefix}${name}`;
}

export function algoliaEmptyResponse(requests) {
    return Promise.resolve({
        results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            processingTimeMS: 0,
        })),
    });
}

export function algoliaHelper(instantSearchComponent) {
    const instantSearchInstance = instantSearchComponent.instantSearchInstance;
    return instantSearchInstance.helper ?? instantSearchInstance.mainHelper;
}

export function algoliaDerivedHelper(instantSearchComponent) {
    return instantSearchComponent.instantSearchInstance.mainHelper.derivedHelpers[0];
}

export function nextResults(instantSearchComponent) {
    const helper = algoliaDerivedHelper(instantSearchComponent);
    return new Promise(resolve => {
        helper.once('result', resolve);
    });
}

export function throwOnError(instantSearchComponent) {
    const helper = algoliaHelper(instantSearchComponent);
    if(helper) {
        helper._dispatchAlgoliaError = (code, error) => { throw error; }
    }
}

export function productStoreFilter(storeId) {
    if(storeId) {
        return `(filter_is_local = false OR filter_local_stores = ${storeId})`;
    }
    return 'filter_is_local = false';
}

