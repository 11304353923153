<template>
    <div>
        <slot v-bind="this" />
    </div>
</template>

<script>
    import {deleteCartCoupon, getCart, postCartCoupon} from "../../api";
    import {alertError, showMessage} from "../../partials/helpers";

    export default {
        data() {
            return {
                ready: false,
                cart: null,
                couponError: null,
                showCouponAlert: false,
            }
        },
        computed: {
            couponCode() {
                return this.cart
                    ? this.cart.coupon.code
                    : null;
            },
        },
        methods: {
            handleCouponSubmitted(code) {
                this.loading = true;
                this.showCouponAlert = false;
                postCartCoupon({ code })
                    .then(cart => {
                        this.cart = cart;
                        this.couponError = null;
                        this.showCouponAlert = true;
                        this.scrollToCart();
                    })
                    .catch(error => {
                        if(error.response?.status === 403 || error.response?.status === 419) {
                            showMessage('Erreur', `Vous n'êtes pas connecté, appuyez sur Ok pour vous reconnecter`,{
                                type: 'error',
                                buttons: [{ text:'Ok', action:() => location.reload() }]
                            });
                        } else if(error.response?.status === 422) {
                            const data = error.response.data;
                            this.couponError = data.errors.code?.[0];
                        } else {
                            alertError('Erreur', `Impossible d'ajouter un code coupon`);
                            if(!error.request && !error.response) {
                                return Promise.reject(error);
                            }
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            handleCouponDeleted() {
                this.loading = true;
                this.showCouponAlert = false;
                deleteCartCoupon()
                    .then(cart => {
                        this.cart = cart;
                    })
                    .catch(() => {
                        alertError('Erreur', 'Impossible de supprimer le code coupon');
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },
            handleCouponChanged() {
                this.couponError = null;
            },
            scrollToCart() {
                const cart = this.$el.querySelector('.Cart');
                const rect = cart.getBoundingClientRect();
                if(rect.top < 0) {
                    window.scrollBy({ top: rect.top - window.innerHeight * .15, behavior:'smooth' });
                }
            },
            init() {
                getCart()
                    .then(cart => {
                        this.cart = cart;
                        this.ready = true;
                    });
            },
        },
        mounted() {
            this.init();
        },
    }
</script>
