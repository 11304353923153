<template>
    <div class="store-item p-3">
        <div class="store-item__header mb-2">
            <div class="row flex-nowrap gx-0">
                <div class="col">
                    <component :is="nameTag" class="store-item__title overflow-hidden mb-0">
                        {{ siteName }}
                        <strong class="store-item__city" :class="cityClasses(store)">
                            {{ store.name }}
                        </strong>
                    </component>
                </div>
                <div class="col-auto pl-2 text-right">
                     <div class="store-item__title font-weight-bold text-primary">
                        {{ store.address.area_postal_code }}
                    </div>
                </div>
            </div>
        </div>
        <div class="store-item__body d-flex flex-column">
            <div class="store-item__address flex-grow-1">
                {{ store.address.address1 }}
                <template v-if="store.address.address2">
                    <br>{{ store.address.address2 }}
                </template>
                <br>
                {{ store.address.postal_code }} {{ store.address.city }}
                <br>
                <div class="store-item__phone">
                    {{ store.address.phone }}
                </div>
            </div>
            <div class="mt-2">
                <a :href="store.url" class="store-item__link stretched-link">
                    <span class="sr-only">{{ store.full_name }} : </span>
                    horaires et actualité
                    <svg width="8" height="8" class="sprite-arrow-right fill-current align-baseline ml-1">
                        <use xlink:href="#sprite-arrow-left" />
                    </svg>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { config } from "../../util/config";

    export default {
        props: {
            store: Object,
            nameTag: {
                type: String,
                default: 'h3',
            },
        },
        computed: {
            siteName() {
                return config.ek.site_name;
            },
        },
        methods: {
            cityClasses(store) {
                const city = store.address.city;
                return {
                    'store-item__city--large': city.length > 25,
                }
            },
        },
    }
</script>
