<template>
    <a class="search-result search-result--border">
        <div class="mb-2">
            <template v-if="item.thumbnail">
                <img :src="item.thumbnail" :alt="item.name" width="60" height="60">
            </template>
            <template v-else>
                <svg width="40" height="60">
                    <use xlink:href="#sprite-store"></use>
                </svg>
            </template>
        </div>
        <a class="search-result__title h3 text-reset stretched-link" :href="item.url">
            {{ siteName }}
            <strong>
                {{ item.name }}
            </strong>
        </a>
    </a>
</template>

<script>
    import { config } from "../../../util/config";

    export default {
        props: {
            item: Object,
        },
        computed: {
            siteName() {
                return config.ek.site_name;
            },
        },
    }
</script>
