import { trackAddToCart } from "./tracker";
import { postCartProduct } from "../api";
import { alertError, showMessage } from "../partials/helpers";
import { Store } from "vuex";

/**
 *
 * @param productId
 * @param {Store} store
 * @returns {Promise<void>}
 */
export function addToCart({ productId, store }) {
    trackAddToCart();
    return postCartProduct({ productId })
        .then(cart => {
            store.dispatch('updateCart', cart);
            showMessage('Ajouté au panier', 'Cet article a bien été ajouté à votre panier.', {
                type: 'success',
                buttons: [{ text: 'Voir mon panier', url: '/panier' }, { text: 'Fermer' }],
                close: true,
            });
        })
        .catch(error => {
            if(error.response?.status === 403) {
                alertError('Article indisponible', `Cet article n'est plus disponible pour le moment`);
            } else if(error.response?.status === 422) {
                showMessage('Ajout impossible', `Votre panier contient trop d'articles`, {
                    type: 'error',
                    buttons: [{ text: 'Voir mon panier', url: '/panier' }, { text: 'Fermer' }],
                });
            } else {
                alertError('Erreur', `Erreur lors de l'ajout au panier ; veuillez recharger la page, et réessayer.`);
                if(!error.request && !error.response) {
                    return Promise.reject(error);
                }
            }
        });
}
