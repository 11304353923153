import { getCity, getStores } from "../api";
import { alertError } from "../partials/helpers";


function normalizeCityQuery(city) {
    const normalized = city
        .replace(/^([0-9]{2})$/, "$1000");
    return normalized;
}

function codesMatch(a, b) {
    return !!a && !!b && a.substring(0, 2) === b.substring(0, 2);
}

async function searchStores({ code, productId, stores }) {
    stores = stores || await getStores();
    const matchedStores = (stores || []).filter(store => codesMatch(store.address.postal_code, code));
    return matchedStores.length > 0
        ? Promise.resolve(matchedStores)
        : Promise.reject('not found');
}

export async function findStores({ query, lat, lng, productId, storeId, stores }) {
    if(query) {
        const normalizedQuery = normalizeCityQuery(query);
        const city = await getCity(normalizedQuery);
        if(!city) {
            return Promise.reject('not found');
        }
        // some overseas countries has no lat/lng provided, so we fallback to code matching current stores
        if(!city.centre) {
            return searchStores({ code: city.code, stores });
        }
        lat = city.centre.coordinates[1];
        lng = city.centre.coordinates[0];
    }

    return getStores({ lat, lng, productId, storeId });
}

export function hasNoResults(error) {
    return error === 'not found';
}

export function handleFindStoresError(error) {
    if(hasNoResults(error)) {
        alertError(
            'Aucun magasin trouvé',
            `Veuillez vérifier votre recherche, nous n'avons pas pu trouver de ville avec ce nom.`
        );
    } else {
        alertError(
            'Erreur',
            'Erreur lors de la recherche ; veuillez recharger la page, et réessayer.'
        );
    }
}
