export default {
    bind(el) {
        el.addEventListener('click', el._scrollHandleClick = e => {
            const target = document.querySelector(el.getAttribute('href'));
            if (target) {
                e.preventDefault();
                target.scrollIntoView({block: 'center'});
            }
        });
    }
}