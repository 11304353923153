<template>
    <b-collapse :visible="isVisible">
        <ul class="category-tree__list pb-1">
            <template v-for="item in items">
                <li class="category-tree__item" :class="{ 'mb-1': root }" :key="item.value">
                    <a class="category-tree__link"
                        :class="linkClasses(item)"
                        :href="createUrl(item.value)"
                        @click.prevent="handleItemClicked(item)"
                    >
                        {{item.label}}
                    </a>

                    <CategoryTreeList
                        class="pl-3"
                        :items="item.data"
                        :refine="refine"
                        :create-url="createUrl"
                    />
                </li>
            </template>
        </ul>
    </b-collapse>
</template>

<script>
    import {BCollapse} from 'bootstrap-vue';

    export default {
        name: 'CategoryTreeList',
        components: {
            BCollapse,
        },
        props: {
            items: Array,
            refine: Function,
            createUrl: Function,
            linkClass: String,
            root: Boolean,
        },

        data() {
            return {
                isVisible: !!this.items,
            }
        },

        watch: {
            async items(items) {
                if(items) {
                    await this.$nextTick();
                }
                this.isVisible = !!items;
            },
        },

        methods: {
            linkClasses(item) {
                return {
                    'category-tree__link--active': item.isRefined,
                    'category-tree__link--root': this.root,
                };
            },
            handleItemClicked(item) {
                if(!item.isRefined) {
                    this.refine(item.value);
                }
            },
        },
    }
</script>