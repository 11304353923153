<template>
    <div class="product-rating">
        <template v-if="isAuth && !readOnly">
            <b-collapse id="product-rating" :visible="formVisible">
                <div class="card d-block">
                    <div class="card-body">
                        <button class="close absolute-close" @click="handleCancelButtonClicked">&times;</button>
                        <div>
                            Quelle note sur 5 attribuez-vous à cet article&nbsp;?
                        </div>
                        <div class="row align-items-end mx-n2">
                            <div class="col-auto px-2">
                                <Stars
                                    :rating="selectedRating"
                                    class="product-rating__editable"
                                    @change="handleRatingChanged"
                                    @select="handleRatingSelected"
                                    @mouseleave.native="handleStarRatingMouseleave"
                                />
                            </div>
                            <div class="col px-2">
                                <div class="product-rating__label">
                                    {{ currentRating }} / {{ maxRating }} : {{ currentLabel }}
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 text-right">
                            <button class="btn btn-primary" :disabled="loading" @click="handleSubmitButtonClicked">
                                <template v-if="loading">
                                    Envoi en cours...
                                </template>
                                <template v-else>
                                    Noter
                                </template>
                            </button>
                        </div>
                    </div>
                </div>
            </b-collapse>
        </template>
        <template v-if="hasRated">
            <div class="product-rating__success">
                <div class="sweet-alert card position-static m-0 d-block">
                    <div class="row">
                        <div class="col-auto">
                            <div class="sa-icon sa-success animate d-block">
                                <span class="sa-line sa-tip animateSuccessTip"></span>
                                <span class="sa-line sa-long animateSuccessLong"></span>
                                <div class="sa-placeholder"></div>
                                <div class="sa-fix"></div>
                            </div>
                        </div>
                        <div class="col">
                            <h2>
                                Merci d'avoir attribué une note à cet article.
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="!formVisible">
            <div class="row align-items-end mx-n1">
                <div class="col-auto px-1">
                    <Stars
                        :rating="rating"
                        :increment=".5"
                        read-only
                    />
                </div>
                <template v-if="count > 0">
                    <div class="col-auto px-1">
                        <div class="product-rating__count">({{ count }} note{{ count>1 ? 's' : '' }})</div>
                    </div>
                </template>
            </div>
            <div>
                <template v-if="!readOnly">
                    <a class="btn btn-link-dark btn-sm product-rating__button text-lowercase" :href="rateUrl" @click="handleRateLinkClicked">
                        <span>
                            Noter cet article
                        </span>
                    </a>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
    import {postProductRating} from "../api";
    import {alertError} from "../partials/helpers";
    import {BModal} from 'bootstrap-vue';
    import Stars from './Stars.vue';

    export default {
        components: {
            BModal,
            Stars,
        },

        props: {
            productId: String,
            rating: Number,
            count: Number,
            readOnly: Boolean,
            isAuth: Boolean,
            loginUrl: String,
            maxRating: {
                type: Number,
                default: 5,
            },
            labels: {
                type: Array,
                default: () => ['Mauvais', 'Passable', 'Moyen', 'Bon', 'Excellent']
            }
        },

        data() {
            return {
                loading: false,
                currentRating: 0,
                selectedRating: 0,
                hasRated: false,
                formVisible: false,
            }
        },

        computed: {
            currentLabel() {
                return Array.isArray(this.labels)
                    ? this.labels[this.currentRating - 1]
                    : null;
            },
            rateUrl() {
                return !this.isAuth ? this.loginUrl : '#note';
            },
        },

        methods: {
            openRating() {
                this.selectedRating = 4;
                this.currentRating = 4;
                this.formVisible = true;

            },
            closeRating() {
                this.formVisible = false;
                if(history && history.replaceState) {
                    history.replaceState(null, null, `${location.pathname}${location.search}`);
                }
            },
            handleRatingChanged(rating) {
                this.currentRating = rating;
            },
            handleRatingSelected(rating) {
                this.selectedRating = rating;
            },
            handleStarRatingMouseleave() {
                this.currentRating = this.selectedRating;
            },
            handleRateLinkClicked(e) {
                if(this.isAuth) {
                    e.preventDefault();
                    this.openRating();
                }
            },
            handleCancelButtonClicked() {
                this.closeRating();
            },
            handleSubmitButtonClicked() {
                this.loading = true;
                postProductRating({
                    productId: this.productId,
                    rating: this.selectedRating,
                }).then(() => {
                    this.hasRated = true;
                    this.closeRating();
                }).catch(error => {
                    alertError('Erreur', 'Impossible de noter cet article pour le moment');
                }).finally(() => {
                    this.loading = false;
                })
            },
            init() {
                if(!this.readOnly && this.isAuth && location.hash === '#note') {
                    window.scrollBy({ top: this.$el.getBoundingClientRect().top - innerHeight/2, behavior:'smooth' });
                    setTimeout(() => {
                        this.openRating();
                    }, 500);
                }
            },
        },
        mounted() {
            this.init();
        }
    }
</script>
