<template>
    <div class="store-selector">
        <StorePanel
            :visible.sync="panelVisible"
            :store="currentStore"
            @select="handleStoreSelected"
        />
        <slot :store="currentStore" :show-panel="handleShowClicked" />
    </div>
</template>

<script>
import StorePanel from "../StorePanel.vue";

export default {
        components: {
            StorePanel,
        },

        props: {
            store: Object,
            reload: Boolean,
        },

        data() {
            return {
                currentStore: this.store,
                panelVisible: false,
            }
        },

        methods: {
            handleStoreSelected(store) {
                this.currentStore = store;
                if(this.reload) {
                    location.reload();
                }
            },
            handleShowClicked() {
                this.panelVisible = true;
            },
        },
    }
</script>
