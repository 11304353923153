<template>
    <div class="Cart__message" style="max-width: 25em">
        Le retrait à {{ storeName }} n'est pas disponible pour le moment.<br>
        Vous pouvez néanmoins
        <a href="#" @click.prevent="handleChangeStoreClicked">
            changer de magasin de retrait</a> ou
        <a href="#" @click.prevent="handleDeliveryModeClicked('sfs')">
            choisir la livraison à domicile
        </a>
    </div>
</template>

<script>
    export default {
        props: {
            cart: Object,
            storeName: String,
        },
        methods: {
            handleChangeStoreClicked() {
                this.$emit('change-store-click');
            },
            handleDeliveryModeClicked(mode) {
                this.$emit('delivery-mode-click', mode);
            }
        }
    }
</script>
