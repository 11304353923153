<template>
    <b-carousel :class="classes" v-bind="$attrs" :controls="controls" :interval="interval" ref="carousel">
        <slot name="prev-icon" />
        <slot name="next-icon" />
        <slot />
    </b-carousel>
</template>

<script>
    export default {
        props: {
            interval: {
                type: Number,
                default: null,
            },
            controls: Boolean,
        },
        data() {
            return {
                slides: [],
            }
        },
        computed: {
            classes() {
                return {
                    'carousel--single': this.slides.length === 1,
                }
            },
        },
        mounted() {
            this.$watch('$refs.carousel.slides', {
                immediate: true,
                handler: slides => this.slides = slides,
            });
            if(this.controls) {
                if(this.$slots['prev-icon']) {
                    this.$el.querySelector('.carousel-control-prev').appendChild(this.$slots['prev-icon'][0].elm);
                }
                if(this.$slots['next-icon']) {
                    this.$el.querySelector('.carousel-control-next').appendChild(this.$slots['next-icon'][0].elm);
                }
            }
        },
    }
</script>