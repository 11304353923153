<template>
    <div class="quantity-selector" :class="classes">
        <button
            class="btn quantity-selector__btn quantity-selector__btn--up"
            type="button"
            data-test="increase"
            :disabled="loading"
            tabindex="-1"
            @click="handleUpClicked"
        >
            <svg class="quantity-selector__arrow" width="15" height="11">
                <use xlink:href="#sprite-arrow-up" />
            </svg>
        </button>
        <div class="quantity-selector__number btn-lg">
            <slot>
                {{ quantity }}
            </slot>
            <input
                class="quantity-selector__input form-control sr-only sr-only-focusable"
                name="qty"
                aria-label="Quantité"
                type="number"
                :value="quantity"
                :min="steps"
                @input="handleInputChanged"
            >
        </div>
        <button
            class="btn quantity-selector__btn quantity-selector__btn--down"
            type="button"
            :disabled="!downEnabled"
            data-test="decrease"
            tabindex="-1"
            @click="handleDownClicked"
        >
            <svg class="quantity-selector__arrow" width="15" height="11">
                <use xlink:href="#sprite-arrow-up" />
            </svg>
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            quantity: Number,
            disabled: Boolean,
            loading: Boolean,
            steps: {
                type: Number,
                default: 1,
            }
        },
        computed: {
            classes() {
                return {
                    'quantity-selector--disabled': this.disabled,
                }
            },
            downEnabled() {
                return !this.loading && this.quantity > this.steps;
            },
        },
        methods: {
            handleInputChanged(e) {
                this.$emit('change', e.target.valueAsNumber);
            },
            handleUpClicked() {
                this.$emit('change', this.quantity + this.steps);
            },
            handleDownClicked() {
                this.$emit('change', this.quantity - this.steps);
            },
        },
    }
</script>
