<template>
    <div>
        <b-form-radio-group name="delivery_mode" :checked="value" stacked @change="handleChanged">
            <div class="card Cart__side-card Cart__side-card--delivery mb-2"
                :class="panelClasses('sfs')"
                style="cursor: pointer"
                @click="handleCardBodyClicked"
            >
                <div class="card-body pb-3">
                    <div class="row align-items-center mx-n1">
                        <div class="col-auto px-1">
                            <svg class="Cart__side-card-icon" width="25" height="25">
                                <use xlink:href="#sprite-delivery" />
                            </svg>
                        </div>
                        <div class="col px-1 pr-4 d-flex justify-content-center">
                            <b-form-radio class="Cart__side-card-radio" :checked="value" value="sfs">Livraison à domicile</b-form-radio>
                        </div>
                    </div>
                </div>
                <div class="text-center text-muted pt-0" style="font-size: .75em; padding: .75em; line-height: 1">
                    <slot name="sfs-alert" />
                    <div class="mb-1 font-weight-bold">Par l'un des magasins de l'enseigne</div>
                    Réservé aux adresses en France métropolitaine
                </div>
            </div>
            <div class="card Cart__side-card Cart__side-card--delivery mb-2" :class="panelClasses('cnc')">
                <div class="card-body pb-3" @click="handleCardBodyClicked">
                    <div class="row align-items-center mx-n1">
                        <div class="col-auto px-1">
                            <svg class="Cart__side-card-icon" width="25" height="25">
                                <use xlink:href="#sprite-click-and-collect" />
                            </svg>
                        </div>
                        <div class="col px-1 pr-4 d-flex justify-content-center">
                            <div>
                                <b-form-radio class="Cart__side-card-radio" :checked="value" value="cnc">Retrait en magasin</b-form-radio>
                                <div class="text-center text-primary font-weight-bold" style="font-size: .75em; line-height: 1">
                                    gratuit
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <template v-if="store">
                    <div class="card-footer text-center">
                        <h5 class="bold-title mb-0">
                            <span class="text-nowrap">{{ siteName }}</span><br>
                            {{ store.name }}
                        </h5>
                    </div>
                </template>
            </div>
        </b-form-radio-group>

        <slot name="append" />
    </div>
</template>

<script>
    import { config } from "../../util/config";

    export default {
        props: {
            value: String,
            store: Object,
        },
        computed: {
            siteName() {
                return config.ek.site_name;
            },
        },
        methods: {
            panelClasses(mode) {
                return {
                    'Cart__side-card--active': this.value === mode,
                }
            },
            handleChanged(mode) {
                this.$emit('input', mode);
            },
            handleCardBodyClicked(e) {
                const radio = e.currentTarget.querySelector('[type=radio]');
                if(radio) {
                    radio.click();
                }
            },
        }
    }
</script>
