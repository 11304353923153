<template>
    <div class="address-list">
        <template v-if="ready">
            <div class="list-group">
                <template v-for="address in addresses">
                    <div class="list-group-item" :class="{
                        'active': isActive(address),
                        'disabled': isDisabled(address),
                        'list-group-item-action': selectable && !isDisabled(address),
                    }">
                        <div class="row align-items-center mx-n2">
                            <div class="col px-2">
                                <template v-if="selectable">
                                    <label :for="itemId(address)" class="stretched-link label-unstyled mb-0">
                                        <AddressLabel :address="address" :show-phone="!isDisabled(address)" />
                                    </label>
                                    <input :id="itemId(address)"
                                        class="sr-only"
                                        type="radio"
                                        :name="name"
                                        :value="address.id"
                                        :disabled="isDisabled(address)"
                                        :checked="isActive(address)"
                                        @change="handleSelected(address)"
                                    >
                                </template>
                                <template v-else>
                                    <AddressLabel :address="address" />
                                </template>
                            </div>
                            <template v-if="address.is_default">
                                <div class="col-12 col-sm-auto px-2">
                                    <span class="badge badge-primary">
                                        adresse par défaut
                                    </span>
                                </div>
                            </template>
                        </div>
                        <template v-if="isDisabled(address) && countryHelp">
                            <div class="alert alert-secondary mt-2 small mb-1 py-1 px-2">
                                {{ countryHelp }}
                            </div>
                        </template>
                        <template v-if="!readonly && !selectable">
                            <div class="mt-1">
                                <div class="row mx-n2">
                                    <div class="col-auto px-2">
                                        <a class="address-list__action-button" href="#" role="button" @click.prevent="handleEditClicked(address)">
                                            modifier...
                                        </a>
                                    </div>
                                    <template v-if="!address.is_default">
                                        <div class="col-auto px-2">
                                            <a class="address-list__action-button" href="#" role="button" @click.prevent="handleSetAsDefaultClicked(address)">
                                                définir par défaut
                                            </a>
                                        </div>
                                    </template>
                                    <div class="col px-0" style="min-width: 0"></div>
                                    <template v-if="!address.is_default">
                                        <div class="col-auto px-2">
                                            <a class="address-list__action-button" href="#" role="button" @click.prevent="handleDeleteClicked(address)">
                                                supprimer
                                            </a>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>

                <slot name="append-list" />
            </div>

            <slot name="append" />

            <template v-if="!readonly">
                <div class="mt-3 mt-md-4 text-center">
                    <button class="btn" :class="addButtonClass" @click="handleCreateClicked">
                        <svg class="fill-current mr-1" width="1em" height="1em" viewBox="0 0 1024 1024" style="vertical-align: -.125em; margin-left: -.25em;">
                            <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z" /><path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z" />
                        </svg>
                        Nouvelle adresse
                    </button>
                </div>
            </template>

            <template v-if="loading">
                <div class="address-list__overlay">
                    <Loading />
                </div>
            </template>
        </template>
        <template v-else>
            <slot>
                <Loading />
            </slot>
        </template>

        <b-modal :visible="!!editingAddress"
            hide-footer
            no-close-on-backdrop
            @hidden="handleModalHidden"
        >
            <template v-slot:modal-title>
                <template v-if="isCreation">
                    Nouvelle adresse
                </template>
                <template v-else>
                    Édition d'une adresse
                </template>
            </template>
            <template v-if="editingAddress" v-slot="{ hide }">
                <form @submit.prevent="handleSubmitted">
                    <AddressForm
                        v-model="editingAddress"
                        :errors.sync="errors"
                        :allowed-countries="allowedCountries"
                        :country-help="countryHelp"
                        :is-delivery="isDelivery"
                        ref="form"
                    />
                    <div class="mt-4 text-right">
                        <button class="btn btn-light mr-2" type="button" @click="hide">
                            Annuler
                        </button>
                        <button type="submit" class="btn btn-primary" style="min-width: 4em" :disabled="loading">
                            <template v-if="loading">
                                <Loading inline />
                            </template>
                            <template v-else>
                                {{ isCreation ? 'Créer' : 'Modifier' }}
                            </template>
                        </button>
                    </div>
                </form>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import AddressForm from "./AddressForm.vue";
    import { getAddresses, postAddress, postAddressAsDefault, deleteAddress } from "../../api";
    import AddressLabel from "./AddressLabel.vue";
    import Loading from "../Loading.vue";
    import { isValidDeliveryPostalCode } from "../../util/address";

    function setDefaultFirst(addresses) {
        return addresses.sort((a, b) => b.is_default - a.is_default);
    }

    function keepOrder(oldAddresses) {
        const ids = oldAddresses.map(a => a.id);
        return (addresses) => addresses
            ?.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
    }

    export default {
        components: {
            AddressLabel,
            AddressForm,
            Loading,
        },
        props: {
            name: {
                type: String,
                default: 'address',
            },
            readonly: Boolean,
            selectable: Boolean,
            allowedCountries: Array,
            countryHelp: String,
            isDelivery: Boolean,
            value: Object,
            addButtonClass: {
                type: String,
                default: 'btn-primary btn-lg'
            }
        },
        data() {
            return {
                ready: false,
                addresses: null,
                editingAddress: null,
                errors: null,
                loading: true,
            }
        },
        computed: {
            isCreation() {
                return !this.editingAddress?.id;
            },
        },
        methods: {
            /**
             * @public
             */
            async update() {
                await this.get(keepOrder(this.addresses));
                const activeAddress = this.addresses
                    ?.find(address => address.id === this.value?.id);

                if(activeAddress) {
                    this.$emit('input', activeAddress);
                }
            },
            async get(mutator = setDefaultFirst) {
                this.loading = true;
                this.addresses = await getAddresses()
                    .catch(error => {
                        if(error.response.status === 401) {
                            alert(`Vous n'êtes plus connecté, cliquez sur Ok pour recharger la page`);
                            location.reload();
                        }
                        return Promise.reject(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });

                if(mutator) {
                    this.addresses = mutator(this.addresses);
                }

                this.ready = true;
            },
            itemId(address) {
                return `${this.name}-${address.id}`;
            },
            isActive(address) {
                return this.value?.id === address.id;
            },
            isDisabled(address) {
                return this.allowedCountries?.every(code => code !== address.country)
                    || this.isDelivery && !isValidDeliveryPostalCode(address);
            },
            handleEditClicked(address) {
                this.editingAddress = {
                    ...address
                };
            },
            handleCreateClicked() {
                this.editingAddress = {};
            },
            async handleDeleteClicked(address) {
                const confirmed = await this.$bvModal.msgBoxConfirm([
                    this.$createElement('div', 'Êtes-vous sûr de vouloir supprimer cette adresse ?'),
                    this.$createElement(AddressLabel, { class:'d-block mt-3 small', props: { address } }),
                ], {
                    okTitle: 'Supprimer',
                    okVariant: 'danger',
                    cancelTitle: 'Annuler',
                    footerClass: 'pt-0',
                    centered: true,
                })
                if(confirmed) {
                    this.loading = true;
                    deleteAddress(address.id)
                        .then(() => this.get())
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            handleSetAsDefaultClicked(address) {
                this.loading = true;
                postAddressAsDefault(address.id)
                    .then(() => this.get(keepOrder(this.addresses)))
                    .finally(() => {
                        this.loading = false;
                    });
            },
            async handleSubmitted() {
                await this.$refs.form.validate();
                this.errors = null;
                this.loading = true;
                postAddress({
                    addressId: this.editingAddress.id,
                    data: this.editingAddress,
                })
                .then(address => {
                    this.get();
                    this.editingAddress = null;
                    this.$emit('input', address);
                })
                .catch(error => {
                    if(error.response?.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                })
                .finally(() => {
                    this.loading = false;
                })
            },
            handleSelected(address) {
                this.$emit('input', address);
            },
            handleModalHidden() {
                this.errors = null;
                this.editingAddress = null;
            }
        },
        created() {
            this.get();
        },
    }
</script>
