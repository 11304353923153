<template>
    <component
        v-if="label || thumbnail"
        :is="marketingLabelComponent"
        :label="label"
        :thumbnail="thumbnail"
    />
</template>

<script>
    import {getMarketingLabelByType} from "./marketing-labels";

    export default {
        props: {
            type: {
                type: String,
                required: true,
            },
            label: String,
            thumbnail: String,
        },
        computed: {
            marketingLabelComponent() {
                return getMarketingLabelByType(this.type);
            },
        },
    }
</script>