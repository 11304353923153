<template>
    <div class="search-result search-result--border d-flex flex-column justify-content-end">
        <div class="mb-2">
            <img class="search-result__image img-fluid" :src="item.thumbnail" width="50" height="50" :alt="item.name">
        </div>
        <a class="search-result__title h3 stretched-link text-reset" :href="item.url">
            <template v-if="item.parent_name">
                {{ item.parent_name }} &gt;
            </template>
            <strong>
                {{ item.name }}
            </strong>
        </a>
    </div>
</template>

<script>
    export default {
        props: {
            item: Object,
        },
    }
</script>
