<template>
    <div class="marketing-label marketing-label--default">
        <div class="marketing-label__text">
            {{ label }}
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: true,
            }
        },
    }
</script>