<template>
    <div>
        <slot v-bind="this" />
        <StorePanel
            :visible.sync="storePanelVisible"
            :store="store"
            @select="handleStoreSelected"
        />
    </div>
</template>

<script>
    import StorePanel from '../StorePanel.vue';

    export default {
        components: {
            StorePanel,
        },
        props: {
            data: {
                type: Object,
                default: ()=>({
                   store: null,
                }),
            }
        },
        data() {
            return {
                store: this.data.store,
                storePanelVisible: false,
                showUpdateAlert: false,
            }
        },
        methods: {
            handleStoreSelected(store) {
                this.store = store;
                this.showUpdateAlert = true;
                setTimeout(() => {
                    this.showUpdateAlert = false;
                }, 3000)
            },
            handleChangeStoreClicked() {
                this.storePanelVisible = true;
            },
        },
    }
</script>
