import swal from 'sweetalert';

export function alertError(title, text) {
    swal({
        title: title,
        text: text,
        type: "error",
        customClass: `sweet-alert--error`,
        confirmButtonText: "Fermer",
        // confirmButtonColor: "#c01f3b"
    });
}

export function showMessage(title, text, {type = 'info', buttons, close} = {}) {

    let confirmButton = buttons && buttons.length
        ? buttons[0]
        : { "text": "OK" };

    let cancelButton = buttons && buttons.length > 1
        ? buttons[1]
        : null;

    swal({
        title, text, type,
        customClass: `sweet-alert--${type}`,
        confirmButtonText: confirmButton.text,
        // confirmButtonColor: "#c01f3b",
        showCancelButton: cancelButton != null,
        cancelButtonText: cancelButton ? cancelButton.text : "Annuler",
        // cancelButtonColor: "#dddddd"

    }, function() {
        if(confirmButton.action) {
            confirmButton.action();
        } else if(confirmButton.url) {
            document.location = confirmButton.url;
        }
    });
    if(close) {
        const alert = document.body.querySelector('.sweet-alert');
        if(!alert.querySelector('.close')) {
            const btn = document.createElement('button');
            btn.classList.add('close');
            btn.style.cssText = 'position: absolute; top: 0; right: 0';
            btn.onclick = swal.close;
            btn.innerHTML = '&times;';
            alert.appendChild(btn);
        }
    }
}
