<template>
    <b-modal
        id="newsletter-modal"
        :visible="visible"
        size="lg"
        title="La newsletter"
        modal-class="newsletter-modal"
        header-class="newsletter-modal__header"
        body-class="newsletter-modal__body"
        footer-class="newsletter-modal__footer"
        title-class="newsletter-modal__title"
        centered
        ok-only
        :hide-footer="success"
        :ok-disabled="loading"
        @ok="handleOk"
        @shown="handleShown"
    >
        <template v-slot:modal-ok>
            <template v-if="loading">
                <Loading inline />
            </template>
            <template v-else>
                Je m'inscris
            </template>
        </template>
        <template v-if="success">
            <div class="alert alert-success">
                {{ message }}
            </div>
        </template>
        <template v-else>
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-7">
                    <p class="newsletter-modal__text">Recevez tout {{ siteName }} dans votre boîte!</p>
                    <template v-if="message">
                        <div class="alert alert-danger">
                            {{ message }}
                        </div>
                    </template>
                    <div class="form-group">
                        <label class="sr-only" for="newsletter-email">Adresse E-mail</label>
                        <input
                            v-model="email"
                            id="newsletter-email"
                            class="form-control newsletter-modal__input"
                            :class="{ 'is-invalid':error }"
                            placeholder="mon adresse mail"
                            type="email"
                            ref="input"
                        >
                        <div class="invalid-feedback">
                            {{ error }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import { api } from "../api";
    import {BModal} from 'bootstrap-vue';
    import Loading from './Loading.vue';
    import { config } from "../util/config";

    export default {
        components: {
            BModal,
            Loading,
        },
        props: {
            show: Boolean,
            endpoint: String,
        },
        data() {
            return {
                email: '',
                loading: false,
                error: null,
                message: null,
                success: false,
                visible: this.show,
            }
        },
        computed: {
            siteName() {
                return config.ek.site_name;
            }
        },
        methods: {
            handleOk(e) {
                e.preventDefault();
                this.loading = true;
                api.post(this.endpoint, { email:this.email })
                    .then(response => {
                        this.success = true;
                        this.message = `Votre adresse email a été ajoutée à notre liste d'abonnés.`;
                    })
                    .catch(error => {
                        if(error.response?.status === 422) {
                            this.error = error.response.data.errors.email[0];
                        } else {
                            return Promise.reject(error);
                        }
                    })
                    .catch(error => {
                        this.message = `Impossible de demander la newsletter pour le moment`;
                        if(!error.request && !error.response) {
                            return Promise.reject(error);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },
            handleShown() {
                this.$refs.input.focus();
            },
            handleHidden() {
                this.error = null;
                this.email = '';
                this.message = null;
                this.success = false;
            },
        },
        created() {
            if(this.show) {
                this.visible = !sessionStorage.getItem('newsletter_modal_shown');
                sessionStorage.setItem('newsletter_modal_shown', '1');
            }
        },
    }
</script>
