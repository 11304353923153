

<script>
    export default {
        props: {
            data: Object,
            profileAddress: Object,
        },
        data() {
            return {
                cart: { ...this.data },
                parcelShopAddress: null, // save parcel shop address if user switches tab
                isSameInvoiceAddress: this.data.deliveryAddress?.id === this.data.invoiceAddress?.id
                    || !this.data.deliveryAddress,
            }
        },
        methods: {
            handleDeliveryTypeChanged() {
                if(this.cart.sfs_delivery_type === 'home') {
                    this.cart.deliveryAddress = { ...this.profileAddress };
                } else if(this.cart.sfs_delivery_type === 'parcel_shop') {
                    this.cart.deliveryAddress = this.parcelShopAddress;
                }
            }
        },
        created() {
            this.$watch(() => this.cart.deliveryAddress,
                (address) => {
                    if(this.isSameInvoiceAddress && this.cart.sfs_delivery_type === 'home' && address) {
                        this.cart.invoiceAddress = {
                            ...address
                        }
                    }
                    if(this.cart.sfs_delivery_type === 'parcel_shop') {
                        this.parcelShopAddress = address;
                    }
                }, { immediate:true });

            this.$watch(() => this.isSameInvoiceAddress,
                () => {
                    if(this.isSameInvoiceAddress) {
                        this.cart.invoiceAddress = { ...this.cart.deliveryAddress };
                    }
                });
        },
        render(h) {
            return h('div', this.$scopedSlots.default(this));
        }
    }
</script>
