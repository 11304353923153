import debounce from 'lodash/debounce';

export default {
    inserted(el) {
        function layout() {
            const { top } = window.getComputedStyle(el);
            el.style.position = el.offsetHeight > window.innerHeight - parseInt(top) ? 'static' : 'sticky';
        }
        layout();
        window.addEventListener('resize', el._stickyAsideListener = debounce(layout, 200));
    },
    unbind(el) {
        window.removeEventListener('resize', el._stickyAsideListener);
    }
}
