<template>
    <div>
        <slot />
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';

    export default {
        props: {
            right: Boolean,
        },
        methods: {
            layout() {
                const style = window.getComputedStyle(this.$el);
                if(style.position !== 'absolute') {
                    this.$el.style.transform = '';
                    return;
                }
                const transform = this.right ? 'translateX(100%)' : 'translateX(-100%)';
                this.$el.style.transform = transform;
                const rect = this.$el.getBoundingClientRect();
                if(this.right) {
                    const diff = document.body.offsetWidth - rect.right;
                    if(diff < 0) {
                        this.$el.style.transform = `${transform} translateX(${diff}px)`
                    }
                } else {
                    if(rect.x < 0) {
                        this.$el.style.transform = `${transform} translateX(${rect.x * -1}px)`
                    }
                }
            },
            handleResize: debounce(function () {
                this.layout();
            }, 0)
        },
        mounted() {
            this.layout();
            window.addEventListener('resize', this.handleResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
    }
</script>
