<template>
    <div class="clear-filters">
        <ais-clear-refinements
            :excluded-attributes="excludedAttributes"
            v-slot="{ createURL, refine, canRefine }"
        >
            <a class="clear-filters__link"
                :class="[
                    linkClass,
                    {
                        'd-none': !canRefine,
                    }
                ]"
                :href="createURL()"
                @click.prevent="refine"
            >
                <div class="row align-items-center mx-n1">
                    <div class="col-auto px-1">
                        Effacer les filtres
                    </div>
                    <div class="col-auto px-1">
                        <svg class="clear-filters__icon d-block" width="16" height="16">
                            <use xlink:href="#sprite-clear"></use>
                        </svg>
                    </div>
                </div>
            </a>
        </ais-clear-refinements>
    </div>
</template>

<script>
    import { AisClearRefinements } from "vue-instantsearch";

    export default {
        components: {
            AisClearRefinements,
        },
        props: {
            linkClass: String,
        },
        computed: {
            excludedAttributes() {
                return [
                    'query',
                    'category',
                    'subcategory',
                ]
            }
        },
    }
</script>
