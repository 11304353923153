<template>
    <div class="filter category-menu mb-4">
        <ais-clear-refinements ref="clear" v-slot="{ createURL, refine, canRefine }" :included-attributes="['subcategory']">
            <template v-if="!canRefine">
                <div aria-current="true" class="category-menu__link category-menu__link--root category-menu__link--active">
                    Tous les articles
                </div>
            </template>
            <template v-else>
                <a :href="createURL()" class="category-menu__link category-menu__link--root">
                    Tous les articles
                </a>
            </template>
        </ais-clear-refinements>

        <ais-menu
            ref="menu"
            v-slot="{ items, createURL, refine }"
            :limit="20"
            :transform-items="transformItems"
            attribute="subcategory"
        >
            <ul class="category-menu__list">
                <template v-for="item in items">
                    <li :key="item.value" class="category-menu__item">
                        <template v-if="item.isRefined">
                            <div aria-current="true" class="category-menu__link category-menu__link--active mb-0">
                                {{ item.label }}
                            </div>
                        </template>
                        <template v-else>
                            <a :href="createURL(item.value)" class="category-menu__link">
                                {{ item.label }}
                            </a>
                        </template>
                    </li>
                </template>
            </ul>
        </ais-menu>
    </div>
</template>

<script>
    import isEqual from 'lodash/isEqual';
    import { AisClearRefinements, AisCurrentRefinements, AisMenu } from 'vue-instantsearch';
    import FilterCollapse from "../FilterCollapse.vue";

    export default {
        components: {
            AisClearRefinements,
            AisCurrentRefinements,
            AisMenu,
            FilterCollapse,
        },

        props: {
            collapsed: Boolean,
            title: String,
        },

        data() {
            return {
                items: null,
            }
        },

        computed: {
            isRefined() {
                return (this.items ?? []).some(item => item.isRefined);
            },
            isCollapsed() {
                return this.collapsed && !this.isRefined;
            },
        },

        methods: {
            transformItems(items) {
                if(!isEqual(items, this.items)) {
                    this.items = items;
                }
                return [...items]
                    .sort((a, b) => a.value.localeCompare(b.value));
            },
        },
    }
</script>
