<template>
    <form class="PaymentForm" :action="endpoint" method="post" @submit.prevent="handleSubmit" ref="form">
        <div class="position-relative">
            <slot :loading="loading" />
            <div v-if="loading" class="PaymentForm__loading">
                <Loading />
            </div>
        </div>
        <template v-if="error">
            <div class="alert alert-danger mt-3" v-html="error">
            </div>
        </template>
    </form>
</template>

<script>
    import { api } from "../api";
    import Loading from './Loading.vue';

    export default {
        components: {
            Loading
        },
        props: {
            endpoint: String,
            cardPaymentEndpoint: String,
            cardPaymentEnabled: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                loading: false,
                error: null,
            }
        },
        methods: {
            handleSubmit() {
                this.loading = true;
                if(this.cardPaymentEnabled) {
                    this.confirmCardPayment();
                } else {
                    this.$refs.form.submit();
                }
            },
            confirmCardPayment() {
                return api.post(this.cardPaymentEndpoint, new FormData(this.$refs.form))
                    .then(response => {
                        this.$emit('error', null);
                        Payplug.showPayment(response.data.payplug_payment_url);
                        this.loading = false;
                    })
                    .catch(error => {
                        if(!window.Payplug) {
                            this.error = `Le service de paiement en ligne n'est pas disponible pour le moment, nous nous excusons pour la gêne occasionnée.
                               Veuillez réessayer plus tard.<br>Si le problème persiste, essayez avec un autre navigateur :
                               il est possible que le vôtre soit trop ancien pour respecter les normes de sécurité du paiement en ligne, ou qu'une extension bloque le système.`;
                        } else if(error.response?.status === 422) {
                            this.$emit('error', error.response.data.errors);
                        } else {
                            this.error = `Une erreur est survenue lors du paiement, veuillez réessayer plus tard`;
                        }
                        // if error is not XHR, throw to log it in sentry
                        if(!error.request && !error.response) {
                            return Promise.reject(error);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        }
    }
</script>
