<template>
    <transition>
        <template v-if="visible">
            <button class="btn back-to-top p-2" aria-label="Revenir en haut de la page" @click="handleClicked">
                <svg width="16" height="16">
                    <use xlink:href="#sprite-arrow-up"></use>
                </svg>
            </button>
        </template>
    </transition>
</template>

<script>
    import throttle from 'lodash/throttle';

    export default {
        data() {
            return {
                visible: false,
            }
        },
        methods: {
            update() {
                this.visible = window.pageYOffset > window.innerHeight * .5;
            },
            handleClicked() {
                window.scrollTo({ top:0, behavior:'smooth' });
            },
        },
        mounted() {
            this.update();
            this.handleScroll = throttle(this.update, 250);
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }
</script>
