<template>
    <div>
        <div class="form-check">
            <input class="form-check-input" type="radio" id="cookies_accept" value="accept" v-model="state" @change="handleAccept">
            <label class="form-check-label" for="cookies_accept">Accepter le suivi de ma navigation</label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" id="cookies_anon" value="anon" v-model="state" @change="handleAnonymize">
            <label class="form-check-label" for="cookies_anon">Anonymiser le suivi de ma navigation</label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" id="cookies_refuse" value="refuse" v-model="state" @change="handleRefuse">
            <label class="form-check-label" for="cookies_refuse">Refuser le suivi de ma navigation</label>
        </div>
    </div>
</template>

<script>
    import { api } from "../../api";

    export default {
        data() {
            return {
                state: null
            }
        },
        watch: {
            state(state, lastState) {
                this.lastState = lastState;
            }
        },
        methods: {
            handleAccept() {
                this.request(api.post('/metrics_cookie'), { state: 'accept' })
            },
            handleAnonymize() {
                this.request(api.patch('/metrics_cookie'), { state: 'anon' })
            },
            handleRefuse() {
                this.request(api.delete('/metrics_cookie'), { state:'refuse' });
            },
            request(request, { state }) {
                request.then(() => this.state=state).catch(this.handleError);
            },
            handleError({ response }) {
                this.state = this.lastState;
                if(response.status === 422) {
                    alert(`Impossible de modifier ce paramètre : vous avez probablement configuré votre navigateur pour ne pas être pisté (Do Not Track), et êtes de fait anonyme sur ce site.`);
                }
            }
        },
        created() {
            api.get('/metrics_cookie').then(({ data }) => {
                let { is_anonymous, is_dnt } = data;
                this.state = is_dnt ? 'refuse' : is_anonymous ? 'anon' : 'accept';
            });
        }
    }
</script>
