<template>
    <div>
        <slot v-bind="this" />
    </div>
</template>

<script>
    import {findStores, handleFindStoresError} from "../../util/stores";
    import {alertError} from "../../partials/helpers";

    export default {
        props: {
            data: {
                type: Object,
                default: ()=>({
                    stores: null,
                }),
            },
        },

        data() {
            return {
                loading: false,
                stores: this.data.stores,
                query: null,
                position: null,
            }
        },

        methods: {
            scroll() {
                if(window.innerWidth >= 768) {
                    const rect = window.innerWidth >= 1200
                        ? this.$el.getBoundingClientRect()
                        : this.$el.querySelector('[data-scroll-target]').getBoundingClientRect();

                    window.scrollBy({ top: rect.top - 125, behavior:'smooth' });
                }
            },
            handleGeolocateButtonClicked() {
                navigator.geolocation.getCurrentPosition(position => {
                    this.search({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                    this.scroll();
                }, () => {
                    alertError(
                        'Géolocalisation indisponible',
                        `Imposible de vous localiser automatiquement ; veuillez l'activer dans vos paramètres ou utiliser la localisation par code postal/ville.`
                    );
                }, { maximumAge:600000, timeout:5000 });
            },
            handleSearchSubmitted() {
                this.search(this.position);
                this.scroll();
            },
            handleAutocompleteCitySelected(result) {
                this.position = {
                    lat: result.lat,
                    lng: result.lng,
                }
                this.search(this.position);
                this.scroll();
            },
            handleAutocompleteQueryChanged(query) {
                this.query = query;
                this.position = null;
            },
            search(position) {
                this.loading = true;

                findStores({
                    query: !position ? this.query : null,
                    lat: position ? position.lat : null,
                    lng: position ? position.lng : null,
                    stores: this.data.stores,
                })
                .then(stores => {
                    this.stores = stores;
                })
                .catch(error => {
                    handleFindStoresError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
            },
        },
    }
</script>
