<template>
    <div class="product-item" :class="classes" @click="handleClicked">
        <div class="product-item__header">
            <div class="product-item__image-container responsive-size responsive-size-1by1 mw-100">
                <picture>
                    <source :srcset="`${resolveThumbnail(.75)} 1.5x, ${resolveThumbnail(1)} 2x`" media="(min-width: 768px)">
                    <img class="product-item__image responsive-size-item"
                        :src="resolveThumbnail(.75)"
                        :alt="product.name"
                        width="200"
                        height="200"
                        loading="lazy"
                    >
                </picture>

                <div class="product-item__marketing-label-container">
                    <MarketingLabel
                        :type="product.marketing_label_type"
                        :label="product.marketing_label"
                        :thumbnail="product.marketing_thumbnail"
                    />
                </div>
            </div>
        </div>

        <div class="product-item__body">
            <div class="product-item__content">
                <component :is="nameTag" class="product-item__title">
                    <a class="product-item__link stretched-link" :href="product.url" ref="link" @click.stop>
                        <span>{{ product.name }}</span>
                    </a>
                </component>
                <template v-if="showBrand && product.brand">
                    <component :is="brandTag" class="product-item__brand d-none d-md-block">
                        {{ product.brand }}
                    </component>
                </template>

                <div class="product-item__price">
                    <template v-if="product.has_discounted_price">
                        <Price :price="product.undiscounted_price" old :thin="thinPrice" />
                    </template>
                    <template v-if="product.price">
                        <Price :price="product.price" :thin="thinPrice" />
                    </template>
                </div>

                <template v-if="product.rating">
                    <div class="product-item__rating d-flex justify-content-center mt-2">
                        <ProductRating :rating="product.rating" read-only />
                    </div>
                </template>

                <slot name="footer" />
            </div>
            <template v-if="showAddButton">
                <div class="product-item__add-container text-center pt-2 d-none d-md-block px-2" @click.stop>
                    <slot name="add-to-cart">
                        <button :disabled="loading" class="btn btn-universe-primary product-item__add-button px-3" tabindex="-1" @click="handleAddClicked">
                            <span>
                                Ajouter au panier
                            </span>
                            <template v-if="loading">
                                <Loading inline inverted />
                            </template>
                        </button>
                    </slot>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import MarketingLabel from "../MarketingLabel.vue";
    import Price from '../Price.vue';
    import ProductRating from "../ProductRating.vue";
    import AddToCart from "../AddToCart.vue";
    import Loading from "../Loading.vue";
    import { addToCart } from "../../util/product";

    export default {
        components: {
            MarketingLabel,
            Price,
            ProductRating,
            AddToCart,
            Loading,
        },
        props: {
            product: {
                type: Object,
                required: true,
            },
            thumbnail: String,
            showName: {
                type: Boolean,
                default: true,
            },
            showBrand: {
                type: Boolean,
                default: true,
            },
            showAddButton: {
                type: Boolean,
                default: true,
            },
            small: Boolean,
            compact: Boolean,
            overlap: Boolean,
            thinPrice: Boolean,
            nameTag: {
                type: String,
                default: 'h3',
                validator: tag => ['h2', 'h3', 'h4', 'h5'].includes(tag),
            },
        },
        data() {
            return {
                loading: false,
            }
        },
        computed: {
            classes() {
                const marketingLabelType = this.product.marketing_label_type;
                return {
                    'product-item--name-hidden': !this.showName,
                    'product-item--small': this.small,
                    'product-item--overlap': this.overlap,
                    'product-item--compact': this.compact,
                    [`product-item--marketing-${marketingLabelType}`]: !!marketingLabelType,
                }
            },
            brandTag() {
                const match = this.nameTag.match(/^h(\d)$/, '$1');
                if(match) {
                    return `h${Number(match[1]) + 1}`
                }
                return 'div';
            },
        },
        methods: {
            handleClicked() {
                this.$refs.link.click();
            },
            handleAddClicked() {
                this.loading = true;
                addToCart({ productId:this.product.id, store:this.$store })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            resolveThumbnail(ratio) {
                const sizeRE = /\/(\d+)x(\d+)\//;
                const url = this.thumbnail ?? this.product.thumbnail;
                const currentWidth = url.match(sizeRE)?.[1];
                if(currentWidth) {
                    const size = currentWidth * ratio;
                    return url.replace(sizeRE, `/${size}x${size}/`);
                }
                return url;
            },
        },
    }
</script>
