import cloakVisible from './cloakVisible';
import focus from './focus';
import scroll from './scroll';
import src from './src';
import stickyAside from "./stickyAside";
import inputAnchor from "./inputAnchor";

export default {
    'focus': focus,
    'cloak-visible': cloakVisible,
    'scroll': scroll,
    'src': src,
    'sticky-aside': stickyAside,
    'input-anchor': inputAnchor,
}
