<template>
    <div class="filter checkboxes-filter">
        <ais-refinement-list
            :attribute="attribute"
            :sort-by="sortBy"
            show-more
            :show-more-limit="25"
            ref="list"
            v-slot="{
                canRefine,
                items,
                refine,
                canToggleShowMore,
                isShowingMore,
                toggleShowMore
            }"
        >
            <FilterCollapse
                v-show="canRefine"
                :id="attribute"
                :label="label"
                :collapsed="isCollapsed(items)"
            >
                <ul class="list-unstyled mb-0">
                    <template v-for="(item, i) in items">
                        <li class="mb-1" :key="item.value">
                            <div class="custom-control custom-checkbox">
                                <input :id="itemId(i)"
                                    type="checkbox"
                                    class="custom-control-input"
                                    :value="item.value"
                                    :checked="item.isRefined"
                                    @input="refine(item.value)"
                                >
                                <label :for="itemId(i)" class="custom-control-label checkboxes-filter__label d-flex align-items-center">
                                    <span class="flex-fill">{{ item.label }}</span>
                                    <span class="checkboxes-filter__count text-muted">{{ item.count }}</span>
                                </label>
                            </div>
                        </li>
                    </template>
                </ul>
                <template v-if="canToggleShowMore">
                    <button class="btn btn-link btn-sm px-0" @click="toggleShowMore">
                        <template v-if="isShowingMore">
                            Voir -
                        </template>
                        <template v-else>
                            Voir +
                        </template>
                    </button>
                </template>
            </FilterCollapse>
        </ais-refinement-list>
    </div>
</template>

<script>
    import FilterCollapse from "../FilterCollapse.vue";
    import {AisRefinementList} from 'vue-instantsearch';

    export default {
        components: {
            FilterCollapse,
            AisRefinementList
        },
        props: {
            attribute: {
                type: String,
                required: true,
            },
            label: String,
            sortBy: {
                type: Array,
                default: () => ['isRefined', 'count:desc', 'name:asc'],
            },
            collapsed: Boolean,
        },
        data() {
            return {
                hasShowMore: false,
            }
        },
        computed: {
            resolvedSortBy() {
                return this.hasShowMore
                    ? ['isRefined', ...this.sortBy]
                    : this.sortBy;
            },
        },
        methods: {
            itemId(i) {
                return `filter-checkbox-${this.attribute}-${i}`;
            },
            isCollapsed(items) {
                return this.collapsed && !items.some(item => item.isRefined);
            },
            // transformItems(items) {
            //     const state = this.$refs.list?.state;
            //     if(state && this.hasShowMore !== state.canToggleShowMore) {
            //         this.hasShowMore = state.canToggleShowMore;
            //     }
            //     return items;
            // },
        },
    }
</script>
