<template>
    <StarRating
        :rating="rating"
        :increment="increment"
        :read-only="readOnly"
        text-class="d-none"
        @current-rating="handleChanged"
        @rating-selected="handleSelected"
    />
</template>

<script>
    import StarRating from 'vue-star-rating';

    export default {
        components: {
            StarRating,
        },
        props: {
            rating: Number,
            increment: Number,
            readOnly: Boolean,
        },
        methods: {
            handleChanged(rating) {
                this.$emit('change', rating);
            },
            handleSelected(rating) {
                this.$emit('select', rating);
            },
        },
    }
</script>
