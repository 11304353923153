<template>
    <form :action="action" @submit.prevent="handleSubmit">
        <template v-if="message">
            <div class="alert" :class="{ 'alert-danger':state==='error', 'alert-secondary':state==='no-results' }">
                {{ message }}
            </div>
        </template>

        <slot v-bind="this" />

        <b-modal title="Choisissez une liste" hide-footer ref="duplicatesModal">
            <InterlisteLists :value="duplicates" />
        </b-modal>

        <div class="Spinner" v-show="state==='loading'">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </form>
</template>

<script>
    import { BModal } from 'bootstrap-vue';
    import { api } from "../../api";
    import InterlisteLists from './Lists.vue';

    export default {
        components: {
            InterlisteLists,
            BModal
        },
        props: {
            action: String
        },
        data() {
            return {
                errors: {},
                message: '',
                state: '',
                duplicates: [],
            }
        },
        methods: {
            handleResponse(response) {
                let { data: { data:results } } = response;
                this.state='success';

                if(results.length === 1) {
                    location.href = results[0].url;
                }
                else {
                    this.duplicates = results;
                    this.$refs.duplicatesModal.show();
                }
            },
            handleError({ response }) {
                this.state='error';
                if(response.status === 422) {
                    this.errors = Object.entries(response.data.errors).reduce((res, [field, errors])=>({
                        ...res, [field]: errors[0]
                    }), {});
                } else if(response.status === 404) {
                    this.state='no-results';
                    this.message = response.data.error;
                }
                else {
                    this.message = "Impossible d'effectuer une recherche pour le moment, veuillez réessayer plus tard";
                }
            },
            handleSubmit() {
                this.message = '';
                this.state = 'loading';
                this.errors = {};
                api.post(this.action, new FormData(this.$el))
                    .then(this.handleResponse, this.handleError)
            }
        },
    }
</script>
