import qs from 'qs';
import { meta, setMeta } from "./dom";
import { history } from 'instantsearch.js/es/lib/routers';

export function appendProductsToMetaDescription(products) {
    if(!Array.isArray(products) || !products.length) {
        return;
    }
    const description = `${meta('description')}. Nos préférées: ${products.slice(0, 5).map(p => p.name).join(', ')}`;

    setMeta('description', description);
    setMeta('og:description', description);
    setMeta('twitter:description', description);
}

function getReplacedTitle(name) {
    return document.title.replace(/.*(- .*)/, `${name} $1`);
}

export function algoliaCategoryRouter(category) {
    return history({
        windowTitle({ menu }) {
            if(menu && menu.subcategory) {
                return getReplacedTitle(menu.subcategory)
            }
            return getReplacedTitle(category.name);
        },
        parseURL({ location }) {
            const state = qs.parse(location.search.slice(1));
            if(`${location.origin}${location.pathname}` === category.url) {
                return state;
            }
            const subcategory = category.children.find(c => c.url === `${location.origin}${location.pathname}`);
            if(subcategory) {
                return {
                    ...state,
                    menu: {
                        ...state.menu,
                        subcategory: subcategory.name
                    }
                }
            }
            return state;
        },
        createURL({ routeState }) {
            const querystring = qs.stringify({
                ...routeState,
                menu: {
                    ...routeState.menu,
                    subcategory: undefined,
                }
            });
            if(routeState.menu && routeState.menu.subcategory) {
                const subcategory = category.children.find(c => c.name === routeState.menu.subcategory);
                if(subcategory) {
                    return url(subcategory.url, querystring);
                }
            }
            return url(category.url, querystring);
        },
    });
}

function url(base, querystring) {
    return querystring
        ? `${base}?${querystring}`
        : base;
}
