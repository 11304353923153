<template>
    <div class="QuantityInput input-group" :class="{ 'QuantityInput--empty':isEmpty }">
        <div class="input-group-prepend">
            <button class="QuantityInput__button btn btn-primary" @click="decrease">-</button>
        </div>
        <input-number :value="value" :min="min" :max="max" @change="handleChanged" ref="input"/>
        <div class="input-group-append">
            <button class="QuantityInput__button btn btn-primary" @click="increase">+</button>
        </div>
    </div>
</template>

<script>
    import InputNumber from 'element-ui/lib/input-number';

    export default {
        components: {
            InputNumber
        },
        props: {
            value: {
                type: Number,
                default:0,
            },
            min: {
                type: Number,
                default:0
            },
            max: {
                type: Number,
                default: Infinity
            },
        },
        computed: {
            isEmpty() {
                return !this.value;
            },
        },
        methods: {
            handleChanged(value) {
                this.$emit('change', value);
            },
            decrease() {
                this.$refs.input.decrease();
            },
            increase() {
                this.$refs.input.increase();
            },
        },
    }
</script>
