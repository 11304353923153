<template>
    <div class="stores-autocomplete position-relative">
        <div class="input-group">
            <input
                v-model="currentQuery"
                aria-label="Chercher un magasin (ville, code postal)"
                class="form-control"
                :class="{ 'form-control-lg px-2': large }"
                placeholder="Ville ou code postal"
                type="search"
                autocomplete="off"
                @input="handleQueryChanged"
                @focus="handleInputFocus"
                @focusout="handleFocusout"
                ref="input"
            >
            <div class="dropdown-menu" :class="{ show:popoverVisible }" style="min-width: 200px" @focusout="handleFocusout">
                <template v-if="loading">
                    <Loading />
                </template>
                <template v-else>
                    <template v-for="result in results">
                        <a class="dropdown-item" href="#" @click.prevent="handleResultClicked(result)">
                            <span class="row flex-nowrap mx-n2">
                                <span class="col px-2 text-truncate" style="min-width: 0">
                                    {{ result.city }}
                                </span>
                                <span class="col-auto px-2">
                                    <span class="text-muted">{{ result.postcode }}</span>
                                </span>
                            </span>
                        </a>
                    </template>
                </template>
            </div>
            <template v-if="large">
                <div class="input-group-append">
                    <button type="submit" class="btn btn-primary px-lg-4" @focus="popoverVisible = false">
                        Ok
                    </button>
                </div>
            </template>
            <template v-else>
                <div class="input-group-append position-absolute h-100" style="right: 0; top: 0; z-index: 4">
                    <button class="btn btn-sm" type="submit" @focus="popoverVisible = false">
                        <svg width="20" height="20" class="d-block fill-current">
                            <use xlink:href="#sprite-search"></use>
                        </svg>
                    </button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import { getCitiesByPostalCode, getCityAutocompleteResults } from "../../api";
    import { BPopover } from 'bootstrap-vue';
    import Loading from "../Loading.vue";

    export default {
        components: {
            BPopover,
            Loading,
        },
        props: {
            query: String,
            large: Boolean,
        },
        data() {
            return {
                currentQuery: null,
                popoverVisible: false,
                results: null,
                loading: false,
            }
        },
        watch: {
           query(query) {
               this.currentQuery = query;
           },
        },
        methods: {
            handleResultClicked(result) {
                this.popoverVisible = false;
                this.currentQuery = result.city;
                this.results = null;
                this.$emit('query-change', result.city);
                this.$emit('input', {
                    lat: result.lat,
                    lng: result.lng,
                    city: result.city,
                });
            },
            handleInputFocus() {
                this.popoverVisible = this.currentQuery?.length > 2 && this.results?.length > 0;
            },
            handleFocusout(e) {
                if(!e.relatedTarget || e.relatedTarget && this.$el.contains(e.relatedTarget)) {
                    return;
                }
                this.popoverVisible = false;
            },
            handleClickOutside(e) {
                if(!this.$el.contains(e.target)) {
                    this.popoverVisible = false;
                }
            },
            async getResults(query) {
                if(/^[0-9]{5}$/.test(query)) {
                    const results = await getCitiesByPostalCode(query);
                    return results.map(result => ({
                        lat: result.centre.coordinates[1],
                        lng: result.centre.coordinates[0],
                        city: result.nom,
                        postcode: query,
                    }));
                }
                const results = await getCityAutocompleteResults(query);
                return results.features.map(feature => ({
                    lat: feature.geometry.coordinates[1],
                    lng: feature.geometry.coordinates[0],
                    city: feature.properties.city,
                    postcode: feature.properties.postcode,
                }));
            },
            search(query) {
                if(query.length < 3) {
                    this.popoverVisible = false;
                    return;
                }
                this.getResults(query)
                    .then(results => {
                        this.results = results;
                        this.popoverVisible = results.length > 0 && document.activeElement === this.$refs.input;
                    })
                    .catch(() => {
                        this.popoverVisible = false;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            handleQueryChanged(e) {
                const query = e.target.value;
                this.loading = query.length > 2;
                this.popoverVisible = query.length > 2;
                this.search(query);
                this.$emit('query-change', query);
            },
            focus() {
                this.$refs.input.focus();
            },
        },
        created() {
            this.search = debounce(this.search, 400);
        },
        mounted() {
            window.addEventListener('click', this.handleClickOutside);
        },
        destroyed() {
            window.removeEventListener('click', this.handleClickOutside);
        }
    }
</script>
