<template>
    <b-modal v-bind="$attrs" hide-footer @hidden="handleHidden" ref="modal" v-slot="{ hide }">
        <template v-if="message">
            <div class="alert alert-danger">
                {{ message }}
            </div>
        </template>
        <form @submit.prevent="handleSubmitted" ref="form">
            <slot :error="error" />

            <div class="modal-footer px-0 pb-0">
                <button class="btn btn-secondary" type="button" @click="hide">Annuler</button>
                <button class="btn btn-primary" type="submit" :disabled="loading">
                    <template v-if="loading">
                        <Loading inline />
                    </template>
                    <template v-else>
                        {{ submitButton }}
                    </template>
                </button>
            </div>
        </form>
    </b-modal>
</template>

<script>
    import {BModal} from 'bootstrap-vue';
    import Loading from './Loading.vue'

    import { api } from "../api";

    export default {
        components: {
            BModal,
            Loading,
        },
        props: {
            endpoint: {
                type: String,
                required: true,
            },
            submitButton: {
                type: String,
                default: 'Envoyer'
            },
            successPage: String,
        },
        data() {
            return {
                loading: false,
                errors: null,
                message: null,
            }
        },
        methods: {
            error(name) {
                return this.errors?.[name]?.[0];
            },
            handleHidden() {
                this.message = null;
                this.errors = null;
                this.loading = false;
            },
            handleSubmitted() {
                this.loading = true;
                this.errors = null;

                api.post(this.endpoint, new FormData(this.$refs.form))
                    .then(response => {
                        this.$emit('submit', response.data);
                        this.$refs.modal.hide();
                        this.message = null;
                        if(this.successPage) {
                            location.href = this.successPage;
                        }
                    })
                    .catch(error => {
                        if(error?.response?.status === 422) {
                            const data = error.response.data;
                            this.errors = data.errors;
                            this.message = 'Il y a une ou plusieurs erreurs dans le formulaire';
                        } else {
                            return Promise.reject(error);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.message = `Impossible d'envoyer le formulaire, veuillez réessayer plus tard`;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            }
        }
    }
</script>
