<template>
    <div>
        <slot v-bind="this" />
    </div>
</template>

<script>
    export default {
        props: {
            data: {
                type: Object,
                default: ()=>({
                    type: false,
                    hasDeliveredDate: false,
                })
            }
        },
        data() {
            return {
                type: this.data.type,
                hasDeliveredDate: this.data.hasDeliveredDate,
            }
        },
        methods: {
            handleTypeChanged(type) {
                this.type = type;
            },
            handleHasDeliveredDateChanged(hasDeliveredDate) {
                this.hasDeliveredDate = hasDeliveredDate;
            }
        }
    }
</script>