<template>
    <div v-show="visible" class="CookieConsent">
        <slot v-bind="this"></slot>
    </div>
</template>

<script>
    import { api } from "../../api";

    export default {
        props: {
            endpoint: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                visible: true
            }
        },
        methods: {
            hide() {
                this.visible=false;
            },
            handleClick() {
                api.post(this.endpoint).then(this.hide);
            }
        }
    }
</script>
