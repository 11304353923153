<template>
    <div>
        <div class="form-group">
            <label for="welcomeText">Texte de bienvenue</label>
            <textarea v-model="welcomeText" class="form-control" id="welcomeText" :class="{ 'is-invalid':!!errors.welcome_text }" rows="5"></textarea>
            <template v-if="errors.welcome_text">
                <div class="invalid-feedback d-block">
                    {{ errors.welcome_text[0] }}
                </div>
            </template>
        </div>

        <div class="form-group">
            <label for="externalLink">Lien</label>
            <input v-model="externalUrl" class="form-control" type="text" id="externalLink" :class="{ 'is-invalid':!!errors.external_url }">
            <small class="form-text text-muted">
                Lien externe : par exemple un blog, un site ou la page Facebook de l'événement.
            </small>
            <template v-if="errors.external_url">
                <div class="invalid-feedback d-block">
                    {{ errors.external_url[0] }}
                </div>
            </template>
        </div>

        <div class="text-right">
            <button class="btn btn-secondary" @click="handleCancelClicked">Annuler</button>
            <button class="btn btn-primary" :disabled="loading" @click="handleSubmit">
                <template v-if="loading">
                    <Loading inverted />
                </template>
                <template v-else>
                    Enregistrer
                </template>
            </button>
        </div>
    </div>
</template>

<script>
    import { api } from "../../../api";
    import Loading from '../../Loading.vue';

    export default {
        components: {
            Loading
        },
        props: {
            info: Object,
            endpoint: String
        },
        data() {
            return {
                welcomeText: this.info.welcomeText,
                externalUrl: this.info.externalUrl,
                errors: {},
                loading: false,
            }
        },
        computed:{
            postData() {
                return {
                    welcome_text: this.welcomeText,
                    external_url: this.externalUrl
                }
            }
        },
        methods: {
            handleCancelClicked() {
                this.$emit('cancel');
            },
            handleSubmit() {
                this.errors = {};
                this.loading = true;
                api.post(this.endpoint, this.postData)
                    .then(()=>{
                        this.$emit('submit');
                    }).catch(({ response }) => {
                        if(response.status === 422) {
                            this.errors = response.data.errors;
                        }
                    }).finally(()=>{
                        this.loading = false;
                    })
            }
        }
    }
</script>
