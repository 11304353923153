<template>
    <div class="search-result search-result--border d-flex flex-column justify-content-end">
        <div class="mb-2">
            <img :alt="item.name" :src="item.thumbnail" class="search-result__image img-fluid" height="50" width="50">
        </div>
        <div class="search-result__title h3">
            <div class="mb-1">
                Nos marques&nbsp;:
            </div>
            <a class="stretched-link text-reset font-weight-bold" :href="item.url">
                {{ item.name }}
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            item: Object,
        },
    }
</script>
