<template>
    <div @mouseenter="handleMouseenter" @mouseleave="handleMouseleave">
        <div class="responsive-size responsive-size-4by3 responsive-size-md-1by1" style="overflow:visible">
            <div class="responsive-size-item d-flex justify-content-center">
                <div class="position-relative product-visuals__image-container mw-100" ref="container">
                    <slot v-bind="this" />

                    <template v-if="zoomRequested">
                        <div class="w-100 h-100 d-none d-md-block" style="position: absolute; top: 0; left: 0">
                            <div ref="zoomContainer">
                                <img class="w-100 h-100 invisible"
                                    :src="largeSrc"
                                    alt=""
                                    aria-hidden="true"
                                    @load="handleImageLoaded"
                                    @error="handleImageError"
                                >
                            </div>
                            <template v-if="loading">
                                <div class="d-flex align-items-center justify-content-center w-100 h-100"
                                    style="position: absolute; top: 0; left: 0; background: rgba(255,255,255,.75)"
                                >
                                    <Loading />
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <template v-if="canZoom">
            <div class="small text-muted text-center w-100" :class="{ 'invisible': zoomActive }">
                Passez la souris sur l'image pour zoomer
            </div>
        </template>
    </div>
</template>

<script>
import ImageZoom from 'js-image-zoom';
import debounce from 'lodash/debounce';
import Loading from "../Loading.vue";
import {isTouch} from "../../util/device";

export default {
        components: {
            Loading
        },
        props: {
            largeSrc: String,
            zoomEnabled: {
                type: Boolean,
                default: true,
            }
        },
        data() {
            return {
                loading: false,
                zoomRequested: false,
                zoomActive: false,
                zoomSize: 0,
                viewportWidth:  window.innerWidth,
            }
        },
        computed: {
            canZoom() {
                return this.zoomEnabled && this.viewportWidth >= 768 && !isTouch();
            },
        },
        methods: {
            load() {
                const container = this.$refs.zoomContainer;
                const width = container.clientWidth;
                const height = container.clientHeight;
                const zoomWidth = Math.min(window.innerWidth / 2, 700);
                const scale = zoomWidth / width;
                this.imageZoom?.kill();
                this.imageZoom = new ImageZoom(container, {
                    width,
                    height,
                    scale,
                    fillContainer: true,
                });
            },
             handleMouseenter() {
                if(!this.canZoom) {
                    return;
                }
                this.zoomActive = true;
                if(!this.zoomRequested) {
                    this.zoomRequested = true;
                    this.loadingTimeout = setTimeout(() => this.loading = true, 300);
                }
            },
            handleMouseleave() {
                this.zoomActive = false;
            },
            handleImageLoaded(e) {
                this.loading = false;
                clearTimeout(this.loadingTimeout);
                if(e.target.naturalWidth < 1000) {
                    return;
                }
                this.load();
            },
            handleImageError() {
                this.loading = false;
                this.imageZoom?.kill();
            },
            handleResize() {
                this.viewportWidth = window.innerWidth;
                this.layout();
                if(this.canZoom && this.imageZoom) {
                    this.load();
                }
            },
            layout() {
                const container = this.$refs.container;
                if(container) {
                    container.style.width = '';
                    const imageWidth = this.$el.querySelector('img').offsetWidth;
                    container.style.width = `${imageWidth}px`;
                }
            },
        },
        mounted() {
            this.handleResize = debounce(this.handleResize, 400);
            window.addEventListener('resize', this.handleResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize);
            this.imageZoom?.kill();
        }
    }
</script>
