<template>
    <form @submit.prevent="handleSubmitted">
        <template v-if="appointment">
            <div class="alert alert-success mx-auto" style="max-width: 40em">
                Votre rendez-vous est confirmé à
                <a class="text-decoration-underline" :href="store.url" target="_blank" style="color: inherit">
                    <strong>{{ store.full_name }}</strong>
                </a>
                <span class="text-nowrap">
                    le <strong>{{ appointment.slot.date }}</strong> entre
                    <strong>{{ formatHour(appointment.slot.start_time) }}</strong> et <strong>{{ formatHour(appointment.slot.end_time) }}</strong>
                </span>.
                Vous allez recevoir un <span class="text-nowrap">e-mail</span> de confirmation à l'adresse <span class="text-decoration-underline">{{ appointment.email }}</span>
            </div>

            <div class="text-center mt-3">
                <a :href="store.url" class="btn btn-primary">
                    &lt;&nbsp;Revenir au magasin
                </a>
            </div>
        </template>
        <template v-else>
            <template v-if="errorMessage">
                <div class="alert alert-danger mx-auto" style="max-width: 40em">
                    {{ errorMessage }}
                </div>
            </template>

            <div class="row justify-content-center mb-n4">
                <div class="col-auto mb-4">
                    <b-calendar
                        class="b-calendar--simple-nav"
                        v-model="currentValue.slot_date"
                        locale="fr"
                        label-no-date-selected="jj/mm/aaaa"
                        label-help=""
                        hide-header
                        nav-button-variant="dark"
                        today-variant="info"
                        :start-weekday="1"
                        :date-info-fn="dateClasses"
                        :date-disabled-fn="isDisabledDate"
                        @selected="handleDateChanged"
                    ></b-calendar>

                    <template v-if="error('slot_date')">
                        <div class="invalid-feedback d-block">{{ error('slot_date') }}</div>
                    </template>

                    <div class="mb-2">
                        <template v-if="loading">
                            <div class="position-absolute mt-2" style="right: 0">
                                <Loading inline />
                            </div>
                        </template>
                    </div>
                </div>
                <div class="col-md-auto mb-4" style="width: 20rem">
                    <template v-if="slots">
                        <div>
                            <div class="mb-3">
                                <select v-model="currentHour"
                                    aria-label="Heure"
                                    class="form-control"
                                    :disabled="loading"
                                    @change="handleHourChanged"
                                >
                                    <option :value="null" disabled>Choisissez une heure</option>
                                    <template v-for="hour in hours">
                                        <option :value="hour" :key="hour">
                                            Entre {{ hour }}h et {{ hour + 1 }}h
                                        </option>
                                    </template>
                                </select>
                            </div>

                            <template v-if="currentHour">
                                <div class="form-group">
                                    <label for="slots">
                                        Créneau
                                    </label>
                                <select v-model="currentValue.slot_start_time"
                                    id="slots"
                                    name="slot_start_time"
                                    class="form-control"
                                    :class="{ 'is-invalid': error('slot_start_time') }"
                                    :disabled="loading"
                                >
                                    <option :value="null" disabled>Choisissez un créneau</option>
                                    <template v-for="slot in slotGroups[currentHour]">
                                        <option :value="slot.start" :key="slotKey(slot)">
                                            {{ slotLabel(slot) }}
                                        </option>
                                    </template>
                                </select>
                                    <div class="invalid-feedback">{{ error('slot_start_time') }}</div>
                                </div>
                            </template>

                            <div class="mt-3" :style="!hasSlotSelected ? 'opacity: .375' : ''">
                                <b-collapse visible appear>
                                    <div class="form-group">
                                        <label for="name">Nom</label>
                                        <input
                                            v-model="currentValue.name"
                                            id="name"
                                            class="form-control"
                                            :class="{ 'is-invalid': error('name') }"
                                            :disabled="!hasSlotSelected"
                                            name="name"
                                        >
                                        <div class="invalid-feedback">{{ error('name') }}</div>
                                    </div>

                                    <div class="form-group">
                                        <label for="email">Adresse E-mail</label>
                                        <input
                                            v-model="currentValue.email"
                                            id="email"
                                            type="email"
                                            class="form-control"
                                            :class="{ 'is-invalid': error('email') }"
                                            :disabled="!hasSlotSelected"
                                            name="email"
                                        >
                                        <div class="invalid-feedback">{{ error('email') }}</div>
                                    </div>

                                    <div class="form-group">
                                        <label for="phone">Numéro de téléphone portable</label>
                                        <input
                                            v-model="currentValue.mobile_phone"
                                            id="phone"
                                            type="tel"
                                            class="form-control"
                                            :class="{ 'is-invalid': error('mobile_phone') }"
                                            :disabled="!hasSlotSelected"
                                            name="mobile_phone"
                                        >
                                        <div class="invalid-feedback">{{ error('mobile_phone') }}</div>
                                    </div>

                                    <div class="mt-4 text-center text-md-left">
                                        <button class="btn btn-primary" :disabled="!canSubmit" type="submit">
                                            <template v-if="loading">
                                                <Loading class="mx-5" inline />
                                            </template>
                                            <template v-else>
                                                Prendre le rendez-vous
                                            </template>
                                        </button>
                                    </div>
                                </b-collapse>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="text-muted" style="font-size: .875rem">
                            Choisissez une date dans le calendrier pour continuer
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </form>
</template>

<script>
    import { BCalendar, BFormRadioGroup, BFormRadio } from 'bootstrap-vue';
    import { getAppointmentSlots, postAppointment } from "../../api";
    import { alertError } from "../../partials/helpers";
    import Loading from "../Loading.vue";

    export default {
        components: {
            BCalendar,
            BFormRadioGroup,
            BFormRadio,
            Loading,
        },
        props: {
            store: Object,
            openedDays: Array,
            email: String,
            phone: String,
            name: String,
        },
        data() {
            return {
                loading: false,
                slots: null,
                currentValue: {
                    slot_date: null,
                    slot_start_time: null,
                    name: this.name,
                    email: this.email,
                    mobile_phone: this.phone,
                },
                currentHour: null,
                appointment: null,
                errors: null,
                errorMessage: null,
            }
        },
        computed: {
            hasSlotSelected() {
                return !!this.currentValue.slot_start_time;
            },
            canSubmit() {
                return this.hasSlotSelected && !this.loading;
            },
            hours() {
                return Object.keys(this.slotGroups)
                    .map(hour => Number(hour));
            },
            slotGroups() {
                return this.slots.reduce((res, slot) => {
                    const key = slot.start.substr(0, 2);
                    return {
                        ...res,
                        [key]: [...(res[key] ?? []), slot],
                    }
                }, {});
            },
        },
        methods: {
            slotKey(slot) {
                return `${slot.start} - ${slot.end}`
            },
            formatHour(time) {
                return time.replace(/^(\d?\d):/, '$1h')
            },
            slotLabel(slot) {
                return `${this.formatHour(slot.start)} - ${this.formatHour(slot.end)}`
            },
            isDisabledDate(ymd) {
                return !(this.openedDays ?? []).some(day => ymd === day);
            },
            dateClasses(ymd, date) {
                const isToday = (new Date()).toDateString() === date.toDateString();
                return isToday ? 'today' : '';
            },
            error(fieldId) {
                return this.errors?.[fieldId]?.[0];
            },
            updateSlots(date = this.currentValue.slot_date) {
                this.loading = true;
                getAppointmentSlots({
                    storeId: this.store.id,
                    date,
                })
                .then(data => {
                    this.slots = data.available_slots;
                })
                .catch(error => {
                    if(error.response.status === 403) {
                        alertError('Erreur', `La prise de rendez-vous n'est plus disponible pour ce magasin`);
                    } else {
                        alertError('Erreur', `Impossible de récupérer les horaires pour le moment, veuillez réessayer plus tard`);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
            },
            handleDateChanged(date) {
                this.updateSlots(date);
                this.currentValue.slot_start_time = null;
                this.currentHour = null;
            },
            handleHourChanged() {
                this.currentValue.slot_start_time = null;
            },
            handleSubmitted() {
                if(!this.canSubmit) {
                    return;
                }
                this.loading = true;
                this.errors = null;
                this.errorMessage = null;
                postAppointment({
                    storeId: this.store.id,
                    data: this.currentValue,
                })
                .then(appointment => {
                    this.appointment = appointment;
                })
                .catch(error => {
                    if(error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if(error.response.status === 403 && error.response.data.message === 'Appointment already exists') {
                        this.handleSlotNoLongerAvailable();
                    } else {
                        return Promise.reject(error);
                    }
                })
                .catch(error => {
                    alertError('Erreur', `Une erreur est survenu lors de l'enregistrement du rendez-vous, veuillez réessayer plus tard`);
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.loading = false;
                })
            },
            handleSlotNoLongerAvailable() {
                this.errorMessage = `Le créneau de ${this.currentValue.slot_start_time} n'est plus disponible, veuillez en choisir un autre`;
                this.updateSlots();
                this.currentHour = null;
                this.currentValue.slot_start_time = null;
                const rect = this.$el.getBoundingClientRect();
                if(rect.top < 125) {
                    window.scrollBy({ top: rect.top - 125, behavior:'smooth' });
                }
            },
        },
    }
</script>
