<template>
    <div class="Cart">
        <template v-if="ready">
            <div class="row">
                <div class="col">
                    <div>
                        <template v-for="item in currentItems">
                            <CartItem
                                class="Cart__item"
                                :class="itemClasses(item)"
                                :item="item"
                                :editable="isItemEditable(item)"
                                :removable="isItemRemovable(item)"
                                :available="isItemAvailable(item)"
                                :loading="isItemLoading(item)"
                                :alert-message="itemAlertMessage(item)"
                                :show-stock-message="!isShipFromStore"
                                @change="updateItem"
                                @toggle-offer="toggleOffer(item)"
                                @remove="remove(item)"
                                :key="item.id"
                            />
                        </template>

                        <template v-if="hasActiveItems">
                            <template v-if="hasCouponActive">
                                <div class="Cart__item">
                                    <div class="row mx-n2 justify-content-end align-items-center">
                                        <div class="col px-2 text-left">
                                        </div>
                                        <div class="col-auto px-2 text-right">
                                            <h3 class="Cart__item__label font-weight-normal">Coupon {{ couponCode }}&nbsp;:</h3>
                                        </div>
                                        <div class="col-auto col-md-2 px-2">
                                            <h3 class="Cart__item__subtotal text-right pr-2">
                                                −&thinsp;{{ couponAmount }}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-if="isShipFromStore && showShippingFees">
                                <div class="Cart__item">
                                    <template v-if="currentCart.shipping_discount_remaining_amount_message">
                                        <div class="d-flex justify-content-end">
                                            <div class="alert alert-success text-right border-0 mb-2 mt-n2 fs-8 px-3 py-2" style="background: #afe7be3b">
                                                {{ currentCart.shipping_discount_remaining_amount_message }}
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="isMinimumShippingFees">
                                        <div class="mt-n2">
                                            <div class="row mx-n2">
                                                <div class="col px-2"></div>
                                                <div class="col-auto col-md-2 px-2">
                                                    <div class="small text-muted text-right pr-2">
                                                        à partir de
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="row mx-n2 justify-content-end align-items-center">
                                        <div class="col px-2 text-left">
                                        </div>
                                        <div class="col-auto px-2 text-right">
                                            <h3 class="Cart__item__label font-weight-normal">Livraison&nbsp;:</h3>
                                        </div>
                                        <div class="col-auto col-md-2 px-2">
                                            <h3 class="Cart__item__subtotal text-right pr-2">
                                                {{ shippingFees }}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <div class="Cart__item border-primary">
                                <div class="row mx-n2 justify-content-end align-items-center">
                                    <div class="col px-2 text-left">
                                        <slot name="action" />
                                    </div>
                                    <div class="col-auto px-2 text-right">
                                        <h3 class="Cart__item__label">Total du panier&nbsp;:</h3>
                                    </div>
                                    <div class="col-auto col-md-2 px-2">
                                        <template v-if="isLoading">
                                            <Loading />
                                        </template>
                                        <template v-else>
                                            <div class="text-right pr-2" data-test="total">
                                                <Price class="Cart__item__total" :price="currentTotal" thin />
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>

                    <slot name="append-left" />
                </div>
                <slot name="aside" v-bind="slotProps" />
            </div>

            <slot name="append" v-bind="slotProps" />
        </template>
        <template v-else>
            <slot />
        </template>

        <StorePanel
            :visible.sync="storePanelVisible"
            :store="currentStore"
            :update-profile="false"
            @hide="handleStorePanelHide"
            @select="handleStoreSelected"
        />

    </div>
</template>

<script>
    import { alertError } from "../../partials/helpers";
    import {
        isShipFromStore,
        cartTotal,
        cartShippingFees,
        cartActiveItems,
        isItemAvailable,
        isMinimumShippingFees,
    } from "../../util/cart"

    import Loading from '../Loading.vue';
    import CartItem from "./CartItem.vue";
    import CouponForm from "./CouponForm.vue";
    import StorePanel from "../StorePanel.vue";
    import Price from '../Price.vue';

    import {
        deleteCartItem,
        getCart,
        postCartDeliveryMode,
        postCartStore,
        putCartItem,
        putCartItemOffer
    } from "../../api";

    export default {
        components: {
            Loading,
            CartItem,
            CouponForm,
            StorePanel,
            Price,
        },

        props: {
            cart: Object,
            readOnly: Boolean,
            showUnavailables: Boolean,
            showShippingFees: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                ready: !!this.cart,
                currentCart: { ...this.cart },
                loading: false,
                loadingItems: [],
                storePanelVisible: false,
            }
        },

        watch: {
            cart(cart) {
                this.currentCart = { ...cart };
            },
        },

        computed: {
            isLoading() {
                return this.loading || this.loadingItems.length > 0;
            },

            isShipFromStore() {
                return isShipFromStore(this.currentCart);
            },

            hasActiveItems() {
                const activeItems = cartActiveItems(this.currentCart);
                return activeItems.length > 0;
            },

            hasCouponActive() {
                return !!this.currentCart.coupon.code;
            },

            currentItems() {
                return this.showUnavailables
                    ? this.currentCart.items
                    : cartActiveItems(this.currentCart);
            },

            currentTotal() {
                return cartTotal(this.currentCart);
            },

            currentStore() {
                return this.currentCart.store;
            },

            couponAmount() {
                return this.currentCart.coupon.amount;
            },

            couponCode() {
                return this.currentCart.coupon.code;
            },

            shippingFees() {
                return cartShippingFees(this.currentCart);
            },

            isMinimumShippingFees() {
                return isMinimumShippingFees(this.currentCart);
            },

            slotProps() {
                return {
                    cart: this.currentCart,
                    store: this.currentStore,
                    loading: this.loading,
                    handleDeliveryModeChanged: this.handleDeliveryModeChanged,
                    showStorePanel: this.handleChangeStoreClicked,
                }
            },
        },

        methods: {
            isItemLoading(item) {
                return this.loadingItems.includes(item.id);
            },
            isItemEditable(item) {
                return !this.readOnly;
            },
            isItemRemovable(item) {
                return !this.readOnly;
            },
            isItemAvailable(item) {
                return isItemAvailable(item, this.currentCart);
            },
            itemAlertMessage(item) {
                if(this.isItemAvailable(item)) {
                    return null;
                }
                if(this.isShipFromStore) {
                    return item.is_shippable
                        ? `Il ne reste pas suffisamment de pièces en stock. ${item.quantity > 1 ? 'Vous pouvez réduire la quantité demandée.' : ''}`
                        : `Cet article n'est pas disponible en livraison`;
                }
                return `Cet article n'est pas disponible en retrait en magasin`;
            },
            itemClasses(item) {
                return {
                    'Cart__item--unavailable': !this.isItemAvailable(item)
                }
            },
            setItemLoading(item, isLoading) {
                this.loadingItems = isLoading
                    ? [...this.loadingItems, item.id]
                    : this.loadingItems.filter(id => item.id !== id);
            },
            setDeliveryMode(mode) {
                this.chooseCncIntent = false;
                if(mode === 'cnc' && !this.currentStore) {
                    this.chooseCncIntent = true;
                    this.storePanelVisible = true;
                    return;
                }
                this.loading = true;
                return postCartDeliveryMode({ mode })
                    .then(cart => {
                        this.setCart(cart);
                    })
                    .catch(error => {
                        alertError('Erreur', `Impossible de modifier le mode de livraison`);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            handleChangeStoreClicked() {
                this.storePanelVisible = true;
            },

            async handleStoreSelected(store) {
                this.loading = true;
                postCartStore(store.id)
                    .then(() => {
                        this.currentCart.store = store;
                        return this.chooseCncIntent
                            ? this.setDeliveryMode('cnc')
                            : this.init();
                    }, () => {
                        alertError('Erreur', `Ce magasin n'est pas disponible, veuillez en choisir un autre`);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },

            resetDeliveryModeRadio(mode) {
                const radio = this.$el.querySelector(`input[name="delivery_mode"][value="${mode}"]`);
                radio.checked = true;
            },

            handleStorePanelHide(selected) {
                if(selected) {
                    return;
                }
                this.chooseCncIntent = false;
                if(this.currentCart.delivery_mode === 'sfs') {
                    this.resetDeliveryModeRadio('sfs');
                }
            },

            handleDeliveryModeChanged(mode) {
                this.setDeliveryMode(mode);
            },

            setCart(cart) {
                this.currentCart = cart;
                this.$store.dispatch('updateCart', cart);
                if(!cart.items.length) {
                    this.loading = true;
                    location.reload();
                }
            },

            remove(item) {
                if(this.isItemLoading(item)) {
                    return;
                }

                this.setItemLoading(item, true);
                deleteCartItem(item.id)
                    .then(cart => {
                        this.setCart(cart);
                    })
                    .catch(()=>{
                        alertError(
                            "Erreur",
                            "Votre panier n'a pas été mis à jour."
                        );
                    })
                    .finally(() => {
                        this.setItemLoading(item, false);
                    });
            },

            updateItem(item) {
                const quantity = item.quantity;
                this.setItemLoading(item, true);

                putCartItem({ itemId:item.id, quantity })
                    .then(cart => {
                        this.setCart(cart);
                    })
                    .catch(error => {
                        item.quantity = item.old_quantity;
                        if(error.response?.status === 423) {
                            alertError(
                                "Article indisponible",
                                "Les stocks de votre magasin ne permettent pas de commander cette quantité."
                            );
                        } else {
                            alertError(
                                "Erreur",
                                "Cet article n'a pas été mis à jour."
                            );
                            if(!error.request && !error.response) {
                                return Promise.reject(error);
                            }
                        }
                    })
                    .finally(() => {
                        this.setItemLoading(item, false);
                    });
            },

            toggleOffer(item, isApplied) {
                if(this.isItemLoading(item)) {
                    return;
                }
                item.offer_applied = isApplied;
                this.setItemLoading(item, true);
                putCartItemOffer({ itemId: item.id })
                    .then(cart => {
                        this.setCart(cart);
                    })
                    .catch(() => {
                        alertError(
                            "Erreur",
                            "Cet article n'a pas été mis à jour."
                        );
                    })
                    .finally(() => {
                        this.setItemLoading(item, false);
                    });
            },

            init() {
                return getCart()
                    .then(cart => {
                        this.setCart(cart);
                        this.ready = true;
                    });
            },
        },
        created() {
            if(!this.cart) {
                this.init();
            }
        }
    };
</script>
