<template>
    <div>
        <slot></slot>

        <b-modal
            size="xl"
            hide-footer
            dialog-class="modal-dialog-centered"
            header-class="modal-header-overlay"
            body-class="p-1"
            :visible="!!currentImage"
            @hidden="currentImage = null"
        >
            <div class="d-flex align-items-center justify-content-center" style="min-height: min(500px, 50vh)">
                <img class="w-auto h-auto mw-100" style="max-height: calc(100vh - 7.5rem)" :src="currentImage">
            </div>
        </b-modal>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                currentImage: null,
            }
        },
        mounted() {
            [...this.$el.querySelectorAll('a')]
                .filter(link => link.href.match(/\.(jpg|jpeg|png)$/i))
                .forEach(link => {
                    link.addEventListener('click', e => {
                        e.preventDefault();
                        this.currentImage = link.href;
                    });
                });
        },
    }
</script>
