<template>
    <div>
        <template v-if="loading">
            <slot />
        </template>
        <template v-else>
            <b-tabs pills
                nav-wrapper-class="tile-popular-products__nav-wrapper mx-n3 mb-2 mb-sm-0"
                :nav-class="{ 'd-none':isUnique }"
            >
                <template v-for="group in categoryGroups">
                    <b-tab :title="group.name" title-item-class="mx-sm-2 mx-md-3 mx-lg-4" :key="group.key">
                        <template v-for="category in activeCategories(group)">
                            <div class="mb-3" :key="category.id">
                                <div class="row mx-n2">
                                    <template v-for="product in category.products">
                                        <div class="col tile-popular-products__col px-2">
                                            <ProductItem
                                                :product="product"
                                                :show-name="false"
                                                :show-brand="false"
                                                :show-add-button="false"
                                                :key="product.id"
                                                small
                                            />
                                        </div>
                                    </template>
                                    <div class="tile-popular-products__col ml-auto px-2">
                                        <a class="tile-popular-products__link btn btn-link p-0" :href="category.url">
                                            <div class="responsive-size responsive-size-1by1">
                                                <div class="responsive-size-item d-flex align-items-center justify-content-center">
                                                    <img width="60" :src="category.thumbnail" :alt="category.name">
                                                </div>
                                            </div>
                                            <div class="name">
                                                <span>
                                                    {{ category.name }}
                                                    <svg width="16" height="14">
                                                        <use xlink:href="#sprite-line-arrow"></use>
                                                    </svg>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="mt-4 mt-md-5">
                            <div class="row mx-md-n3 mb-n3 justify-content-end align-items-center">
                                <div class="col-auto px-md-3 mb-3">
                                    <div class="row mx-n2">
                                        <template v-for="category in minifiedCategories(group)">
                                            <div class="col-auto px-2">
                                                <a class="tile-popular-products__mini-link" :title="category.name" :href="category.url">
                                                    <img :src="category.thumbnail" :alt="category.name" width="35">
                                                    <span class="sr-only">{{ category.name }}</span>
                                                </a>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="col-auto px-md-3 mb-3">
                                    <a class="btn btn-primary btn-md-lg" :href="group.url">
                                        {{ buttonLabel }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                </template>
            </b-tabs>
        </template>
    </div>
</template>

<script>
    import { BTab, BTabs } from 'bootstrap-vue';
    import { getPopularProducts } from "../../api";
    import ProductItem from "../products/ProductItem.vue";

    export default {
        props: {
            tileId: String,
            buttonLabel: String,
            buttonUrl: String,
        },
        components: {
            BTab,
            BTabs,
            ProductItem,
        },
        data() {
            return {
                loading: false,
                categoryGroups: null,
            }
        },
        computed: {
            isUnique() {
                return this.categoryGroups.length === 1;
            },
        },
        methods: {
            activeCategories(group) {
                return group.categories?.filter(category => category.products?.length > 0);
            },
            minifiedCategories(group) {
                return group.categories?.filter(category => !category.products?.length);
            },
            async get() {
                this.loading = true;
                const data = await getPopularProducts({
                    tileId: this.tileId,
                    thumbnail_width: 200,
                });
                this.categoryGroups = data.categoryGroups;
                this.loading = false;
            }
        },
        created() {
            this.get();
        }
    }
</script>
