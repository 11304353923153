<template>
    <div class="visual-tabs" :class="classes">
        <b-tabs
            v-model="currentIndex"
            :class="tabsClass"
            pills
            :vertical="vertical"
            v-bind="$attrs"
            ref="bTabs"
        >
            <slot />
<!--            <template v-slot:tabs-start>-->
<!--                <li>-->
<!--                    <button class="btn btn-link px-1 px-lg-2 px-xl-3 h-100" @click="handlePreviousButtonClicked">-->
<!--                        <svg width="18" height="18" class="fill-current">-->
<!--                            <use xlink:href="#sprite-arrow-left" />-->
<!--                        </svg>-->
<!--                    </button>-->
<!--                </li>-->
<!--            </template>-->
<!--            <template v-slot:tabs-end>-->
<!--                <li>-->
<!--                    <button class="btn btn-link px-1 px-lg-2 px-xl-3 h-100" @click="handleNextButtonClicked">-->
<!--                        <svg width="18" height="18" class="sprite-arrow-right fill-current">-->
<!--                            <use xlink:href="#sprite-arrow-left" />-->
<!--                        </svg>-->
<!--                    </button>-->
<!--                </li>-->
<!--            </template>-->
        </b-tabs>
        <slot name="modal" :index="currentIndex" />
    </div>
</template>

<script>
    function mod(n, m) {
        return ((n % m) + m) % m;
    }

    export default {
        props: {
            index: Number,
            tabsClass: String,
            vertical: Boolean,
        },
        data() {
            return {
                currentIndex: this.index || 0,
                tabsCount: 0,
            }
        },
        computed: {
            classes() {
                return {
                    'visual-tabs--disabled': this.tabsCount < 2,
                    'visual-tabs--vertical': this.vertical,
                }
            },
        },
        methods: {
            handleNextButtonClicked() {
                this.currentIndex = mod(++this.currentIndex, this.tabsCount);
            },
            handlePreviousButtonClicked() {
                this.currentIndex = mod(--this.currentIndex, this.tabsCount);
            },
        },
        async mounted() {
            await this.$nextTick();
            this.tabsCount = this.$refs.bTabs.tabs.length;
            // set current index after mounted to ensure active tab
            this.currentIndex = this.index;
        },
    }
</script>
