<template>
    <div>
        <section class="product-page__delivery-block" aria-label="Retrait en magasin">
            <ProductCncSelector
                :product-id="productId"
                :data="data"
                :store="store"
                v-bind="$attrs"
                @change="handleCncChanged"
            />
        </section>

        <div class="product-page__separator mb-2"></div>

        <section class="product-page__delivery-block mb-0" aria-label="Livraison à domicile">
            <div class="product-page__subtitle">
                <div class="row align-items-start mx-n1">
                    <div class="col-auto px-1">
                        <svg class="product-page__sub-icon" width="20" height="20">
                            <use xlink:href="#sprite-delivery" />
                        </svg>
                    </div>
                    <div class="col px-1">
                        <div class="row mb-n2">
                            <div class="col-auto mb-2">
                                <div class="bold-title product-page__delivery-title mb-0">
                                    Livraison à domicile
                                </div>
                            </div>
                            <div class="col d-flex align-items-center mb-2">
                                <template v-if="availableForSfs">
                                    <div class="product-stock product-page__stock">disponible</div>
                                </template>
                                <template v-else>
                                    <div class="product-stock product-stock--unavailable product-page__stock">indisponible</div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="availableForSfs && store && !stock">
                <p>
                    Un autre magasin {{ siteName }} prendra en charge la livraison.
                </p>
            </template>
        </section>
    </div>
</template>

<script>
    import ProductCncSelector from "./ProductCncSelector.vue";
    import { config } from "../../util/config";

    export default {
        components: {
            ProductCncSelector
        },
        props: {
            productId: String,
            availableForSfs: Boolean,
            data: Object,
        },
        data() {
            return {
                store: this.data.store,
                stock: this.data.stock,
            }
        },
        computed: {
            siteName() {
                return config.ek.site_name;
            },
        },
        methods: {
            handleCncChanged(data) {
                this.store = data.store;
                this.stock = data.stock;
            }
        },
    }
</script>
