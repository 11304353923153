import 'core-js/es/promise';
import 'core-js/es/symbol';
import 'core-js/features/object/entries';
import 'core-js/features/object/values';
import 'core-js/features/array/from';
import 'core-js/features/array/includes';
import 'core-js/features/array/at';
import 'core-js/features/string/pad-start';
import 'core-js/es/set';

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();
