<template>
    <div>
        <div class="mb-0 pb-0">
            <slot name="checkbox" v-bind="this"></slot>
        </div>

        <b-collapse :visible="show">
            <template v-if="show">
                <div class="pb-2">
                    <div class="Panel Panel--light mb-0 pb-4">

                        <label for="giftcard_num" class="sr-only">Numéro de la carte</label>

                        <div class="row align-items-center mx-n2">
                            <div class="col-auto px-2">
                                <div class="input-group input-group-white">
                                    <div class="input-group-prepend" @click="$refs.input.focus()">
                                        <div class="input-group-text">
                                            {{ prepend.replace('-', ' -') }}
                                        </div>
                                    </div>
                                    <input
                                        :value="inputValue"
                                        id="giftcard_num"
                                        class="form-control"
                                        :class="{ 'is-invalid':error }"
                                        :disabled="loading"
                                        :readonly="success"
                                        style="max-width: 12rem"
                                        placeholder="Numéro de la carte"
                                        spellcheck="false"
                                        ref="input"
                                        @input="handleInputChanged"
                                        @keydown.enter.prevent="handleSubmitted"
                                    >
                                    <template v-if="!success">
                                        <div class="input-group-append">
                                            <button class="btn btn-primary"
                                                type="button"
                                                aria-label="Valider ce numéro de carte cadeau"
                                                :disabled="loading"
                                                @click="handleSubmitted">
                                                Valider
                                            </button>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <template v-if="loading">
                                <div class="col-auto align-self-center px-2">
                                    <Loading />
                                </div>
                            </template>
                            <template v-else-if="success">
                                <div class="col-auto align-self-center px-2">
                                    <svg class="fill-current text-success" width="1.75em" height="1.75em" viewBox="0 0 254 254">
                                        <path d="m187.68 65.7a6 6 0 0 0-4.7188 2.4551l-74.771 102.16-33.586-47.58a6 6 0 0 0-8.3613-1.4414 6 6 0 0 0-1.4414 8.3613l38.398 54.4a6.0006 6.0006 0 0 0 9.7441 0.082l79.701-108.9a6 6 0 0 0-1.2988-8.3867 6 6 0 0 0-3.666-1.1562zm-60.678-65.701c-70.069 0-127 56.931-127 127s56.931 127 127 127 127-56.931 127-127-56.931-127-127-127zm0 12c63.584 0 115 51.416 115 115s-51.416 115-115 115-115-51.416-115-115 51.416-115 115-115z"/>
                                    </svg>
                                </div>
                            </template>
                        </div>

                        <template v-if="success">
                            <div class="mt-1">
                                <a class="small text-body text-decoration-underline" style="opacity: .55" href="#" @click.prevent="handleClearClicked">
                                    utiliser une autre carte
                                </a>
                            </div>
                        </template>
                        <template v-else>
                            <div>
                                <template v-if="error">
                                    <div class="invalid-feedback d-block">
                                        {{ error }}
                                    </div>
                                </template>

                                <div class="form-text small mt-2">
                                    Entrez les 12 chiffres présent sur votre carte
                                </div>
                            </div>
                        </template>

                        <template v-if="errorMessage">
                            <div class="alert alert-danger mt-3 mb-0">
                                {{ errorMessage }}
                            </div>
                        </template>

                        <template v-if="success">
                            <div class="mb-n2">
                                <div class="alert alert-success mt-4 mb-0">
                                    <template v-if="giftcard.transaction_status === 'full_giftcard'">
                                        Votre carte cadeau d'un montant de <Price style="color: inherit; font-size: 1em" thin :price="giftcard.giftcard_balance" />
                                        a bien été trouvée, vous pouvez payer l'intégralité de votre commande avec.
                                    </template>
                                    <template v-else-if="giftcard.transaction_status === 'needs_payplug'">
                                        Votre carte cadeau d'un montant de <Price style="color: inherit; font-size: 1em" thin :price="giftcard.giftcard_balance" /> a bien été trouvée.
                                    </template>
                                </div>

                                <template v-if="giftcard.transaction_status === 'needs_payplug'">
                                    <div class="mt-3">
                                        il vous reste <Price class="ml-0" style="color: inherit; font-size: 1em" thin :price="giftcard.amount_to_be_paid_with_payplug" />
                                        à payer pour cette commande.
                                    </div>
                                </template>
                                <template v-if="giftcard.transaction_status === 'full_giftcard' && nextGiftcardBalance > 0">
                                    <div class="mt-3">
                                        Il vous restera <Price :price="nextGiftcardBalance" inline /> sur cette carte.
                                    </div>
                                </template>
                            </div>

                            <input type="hidden" name="giftcard_num" :value="giftcard.giftcard_num">
                            <input type="hidden" name="amount_paid_with_giftcard" :value="giftcard.amount_to_be_paid_with_giftcard">
                        </template>
                    </div>
                </div>
            </template>
        </b-collapse>
    </div>
</template>

<script>
    import {postGiftcardCheck} from "../../api";
    import Price from "../Price.vue";
    import Loading from "../Loading.vue";

    export default {
        components: {
            Price,
            Loading,
        },
        props: {
            prepend: {
                type: String,
                default: 'KDO-',
            }
        },
        data() {
            return {
                show: false,
                loading: false,
                error: null,
                errorMessage: null,

                giftcard: {
                    giftcard_num: null,
                    transaction_status: null,
                    giftcard_balance: null,
                    amount_to_be_paid_with_giftcard: null,
                    amount_to_be_paid_with_payplug: null,
                },
            }
        },
        watch: {
            loading: 'handleLoadingChanged',
        },
        computed: {
            inputValue() {
                return this.giftcard.giftcard_num?.replace(this.prepend, '');
            },
            success() {
                return !!this.giftcard.transaction_status;
            },
            nextGiftcardBalance() {
                return this.giftcard.giftcard_balance - this.giftcard.amount_to_be_paid_with_giftcard;
            },
        },
        methods: {
            handleCheckboxChanged(e) {
                this.show = e.target.checked;
                if(this.show) {
                    this.$emit('input', { ...this.giftcard });
                    setTimeout(() => {
                        this.$refs.input.focus();
                    }, 300);
                } else {
                    this.$emit('input', null);
                    this.loading = false;
                }
            },
            handleInputChanged(e) {
                this.giftcard.giftcard_num = `${this.prepend}${e.target.value}`;
            },
            handleClearClicked() {
                this.giftcard = {};
                this.$emit('input', null);
            },
            handleLoadingChanged(loading) {
                this.$emit('loading', loading);
            },
            handleSubmitted() {
                this.error = null;
                this.errorMessage = null;
                this.loading = true;

                postGiftcardCheck({ giftcard_num: this.giftcard.giftcard_num })
                    .then(data => {
                        this.giftcard = data;
                        this.$emit('input', data);
                    })
                    .catch(error => {
                        if(error.response?.status === 422) {
                            this.error = error.response.data.errors.giftcard_num[0];
                            this.error = this.error.replace('16', 16 - this.prepend.length);
                        }
                        else if(error.response?.status === 403) {
                            this.errorMessage = error.response.data.message ?? `Cette carte n’existe pas ou a déjà été utilisée`;
                        }
                        else if(error.response?.status === 429) {
                            this.errorMessage = error.response.data.errors.throttle[0];
                        }
                        else {
                            return Promise.reject(error);
                        }
                    })
                    .catch(error => {
                        this.errorMessage = `Impossible de trouver cette carte pour le moment, veuillez réessayer ultérieurement`;
                        if(!error.response) {
                            return Promise.reject(error);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },
    }
</script>
