<template>
    <span>
        <strong>{{ address.firstname }} {{ address.lastname }}</strong><br>

        {{ address.address1 }}<br>
        <template v-if="address.address2">
            {{ address.address2 }}<br>
        </template>

        {{ address.postal_code }} {{ address.city }}
        <template v-if="address.country !== 'FR'">
            <br>{{ address.country_label }}
        </template>
    </span>
</template>

<script>
    export default {
        props: {
            address: Object,
        }
    }
</script>
