<template>
    <div>
        <slot v-bind="this" />
    </div>
</template>

<script>
    export default {
        methods: {
            handleErrorClicked(e) {
                e.preventDefault();
                const link = e.target?.closest('a');
                if(link) {
                    const value = link.innerText.trim();
                    const input = this.$el.querySelector('input');
                    input.value = value;
                    input.classList.remove('is-invalid');
                    this.$el.querySelector('.invalid-feedback')?.remove();
                    this.$emit('change', value);
                }
            }
        },
    }
</script>
