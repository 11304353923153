<template>
    <div>
        <b-popover
            :target="target"
            :show.sync="menuVisible"
            :boundary-padding="0"
            :delay="{ show:250, hide: 250 }"
            :container="container"
            :triggers="triggers"
            :disabled="isDisabled"
            :custom-class="popoverClass"
            placement="bottom"
            fallback-placement="clockwise"
            @show="handlePopoverShow"
            @shown="handlePopoverShown"
            @hide="handlePopoverHide"
            @hidden="handlePopoverHidden"
            ref="popover"
        >
            <div class="nav-menu__content"
                @mouseenter="handlePopoverMouseenter"
                @mouseleave="handlePopoverMouseleave"
                @keydown="handlePopoverKeydown"
                ref="content"
            >
                <button class="close popover-close d-none d-md-inline-block" @click="handleCloseClicked" ref="close">&times;</button>
                <template v-if="menuVisible">
                    <slot
                        :submenu="currentSubmenu"
                        :handle-submenu-selected="handleSubmenuSelected"
                        :handle-submenu-escape="handleSubmenuEscape"
                    />
                </template>
            </div>
        </b-popover>
        <div class="d-none" v-once>
            <slot :submenu="null" :handle-submenu-selected="()=>{}" :handle-submenu-escape="()=>{}" />
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import {BPopover} from 'bootstrap-vue';


    export default {
        components: {
            BPopover,
        },
        props: {
            target: String,
            container: String,
            menuClass: String,
            show: Boolean,
            minWidth: {
                type: Number,
                default: 768,
            },
            disabled: Boolean,
        },
        data() {
            return {
                menuVisible: this.show,
                currentSubmenu: null,
                triggers: 'hover',
                viewportWidth: window.innerWidth,
            }
        },
        computed: {
            isDisabled() {
                return this.disabled || this.viewportWidth < this.minWidth;
            },
            popoverClass() {
                return `nav-menu ${this.menuClass}`;
            },
        },
        methods: {
            handlePopoverShow(e) {
                if(this.$store.state.menuOpened) {
                    e.preventDefault();
                    this.menuVisible = false;
                    return;
                }
                const navItem = e.target.parentElement;
                navItem.classList.add('show');
                document.body.classList.add('menu-opened');
                this.$store.dispatch('setMenuOpened', true);
            },
            handlePopoverShown(e) {
                e.target.setAttribute('aria-expanded', 'true');
                document.body.classList.add('menu-opened');
            },
            handlePopoverHide(e) {
                const navItem = e.target.parentElement;
                navItem.classList.remove('show');
                this.currentSubmenu = null;
                this.$store.dispatch('setMenuOpened', false);
            },
            handlePopoverHidden(e) {
                e.target.setAttribute('aria-expanded', 'false');
                document.body.classList.remove('menu-opened');
            },
            handlePopoverMouseenter(e) {
                this.$refs.popover.$_toolpop.enter(e);
            },
            handlePopoverMouseleave(e) {
                this.$refs.popover.$_toolpop.leave(e);
            },
            handleCloseClicked() {
                this.menuVisible = false;
            },
            handleNavLinkKeydown(e) {
                if(e.key === 'Enter') {
                    e.preventDefault();
                    if(this.menuVisible) {
                        this.menuVisible = false;
                    } else {
                        this.menuVisible = true;
                        this.$refs.popover.$once('shown', e => {
                            const firstMenu = e.relatedTarget.querySelector('[data-menu]');
                            if(firstMenu) {
                                firstMenu.focus();
                            }
                        });
                    }
                }
            },
            async handlePopoverKeydown(e) {
                if(e.key === 'Escape') {
                    this.menuVisible = false;
                    await this.$nextTick();
                    this.navLink.focus();
                }
            },
            async handleSubmenuSelected(menu, submenu, { focus } = {}) {
                this.currentSubmenu = submenu;
                // setTimeout because submenu is not rendered on nextTick
                setTimeout(() => {
                    const submenuEl = this.$refs.content && this.$refs.content.querySelector(`[data-menu=${submenu}]`);
                    if(focus && submenuEl) {
                        submenuEl.focus()
                    }
                });
            },
            handleSubmenuEscape(menu, e) {
                this.currentSubmenu = '';
                const parentLink = this.$refs.content.querySelector(`[data-target=${menu}]`);
                if(parentLink) {
                    parentLink.focus();
                    e.stopPropagation();
                }
            },
            handleClickOut(e) {
                if(this.$refs.content && !this.$refs.content.contains(e.target)) {
                    this.menuVisible = false;
                }
            },
            handleResize() {
                this.viewportWidth = window.innerWidth;
            },
        },
        async mounted() {
            this.navLink = document.querySelector(this.target);
            this.navLink.setAttribute('aria-haspopup', 'true');
            this.navLink.setAttribute('aria-expanded', 'false');
            this.navLink.addEventListener('keydown', this.handleNavLinkKeydown);

            document.addEventListener('click', this.handleClickOut);
            this.handleResize = debounce(this.handleResize, 200);
            window.addEventListener('resize', this.handleResize);

            // dev
            // if(process.env.NODE_ENV === 'development') {
            //     this.triggers = 'click';
            //     this.handlePopoverMouseleave = ()=>{};
            //     this.$on('hook:mounted', ()=>document.querySelector(this.target).addEventListener('click', e=>e.preventDefault()));
            //     document.removeEventListener('click', this.handleClickOut)
            // }
        },
        beforeDestroy() {
            this.navLink.removeEventListener('keydown', this.handleNavLinkKeydown);
            document.removeEventListener('click', this.handleClickOut);
            window.removeEventListener('resize', this.handleResize);
            this.$store.dispatch('setMenuOpened', false);
        }
    }
</script>
