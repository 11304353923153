<template>
    <div class="marketing-label marketing-label--frame">
        <!-- this marketing label only applies a border to the product item -->
    </div>
</template>

<script>
    export default {

    }
</script>
