<template>
    <div class="filter-collapse">
        <div class="card filter-collapse__card border-0">
            <div class="card-header filter-collapse__header p-0">
                <button type="button" class="btn w-100 px-0 filter-collapse__button" v-b-toggle="collapseKey">
                    <span class="row flex-nowrap mx-n2 align-items-center">
                        <span class="col text-truncate d-block px-2">
                            {{ label }}
                        </span>
                        <span class="col-auto px-2 d-block">
                             <svg class="filter-collapse__arrow align-baseline" :class="arrowClasses" width="12" height="12">
                                <use xlink:href="#sprite-arrow-left"></use>
                             </svg>
                        </span>
                    </span>
                </button>
            </div>
            <b-collapse
                :id="collapseKey"
                :visible="currentVisibility"
                @input="handleVisibilityChanged"
            >
                <div class="card-body px-3 pt-2" :class="bodyClass">
                    <slot />
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
    import {BCollapse, VBToggle} from 'bootstrap-vue';

    export default {
        components: {
            BCollapse,
        },

        props: {
            id: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            bodyClass: String,
            collapsed: Boolean,
        },

        data() {
            return {
                currentVisibility: !this.collapsed,
            }
        },

        watch: {
            collapsed(collapsed) {
                if(!collapsed) {
                    this.currentVisibility = true;
                }
            },
        },

        computed: {
            collapseKey() {
                return `filter-${this.id}`;
            },
            arrowClasses() {
                return {
                    'filter-collapse__arrow--expanded': this.currentVisibility,
                }
            },
        },

        methods: {
            handleVisibilityChanged(visibility) {
                this.currentVisibility = visibility;
            },
        },

        directives: {
            'b-toggle': VBToggle,
        },
    }
</script>
