<template>
    <Swiper
        :class="classes"
        :options="options"
        @ready="handleSwiperReady"
        ref="swiper"
        :key="key"
    >
        <slot />

        <template v-slot:button-prev>
            <button class="tile-product-ribbon__button tile-product-ribbon__button-prev btn">
                <svg width="28" height="40">
                    <use xlink:href="#sprite-arrow-left" />
                </svg>
            </button>
        </template>
        <template v-slot:button-next>
            <button class="tile-product-ribbon__button tile-product-ribbon__button-next btn">
                <svg class="sprite-arrow-right" width="28" height="40">
                    <use xlink:href="#sprite-arrow-left" />
                </svg>
            </button>
        </template>
    </Swiper>
</template>

<script>
    import { swiper } from 'vue-awesome-swiper';
    import debounce from 'lodash/debounce';

    export default {
        props: {
            colsMd: {
                type: Number,
                default: 3,
            },
            colsLg: {
                type: Number,
                default: 4,
            }
        },
        components: {
            Swiper: swiper,
        },
        data() {
            return {
                key: 0,
                showNavigation: true,
                hasFrameItems: false,
            }
        },
        computed: {
            classes() {
                return {
                    'hide-navigation': !this.showNavigation,
                    'has-frame': this.hasFrameItems,
                }
            },
            options() {
                return {
                    speed: 500,
                    slidesPerView: 2,
                    slidesPerColumn: 2,
                    slidesPerGroup: 2,
                    breakpointsInverse: true,
                    breakpoints: {
                        768: {
                            slidesPerView: this.colsMd,
                            slidesPerColumn: 1,
                            slidesPerGroup: 1,
                        },
                        992: {
                            slidesPerView: this.colsLg,
                            slidesPerColumn: 1,
                            slidesPerGroup: 2,
                        }
                    },
                    navigation: {
                        prevEl: '.tile-product-ribbon__button-prev',
                        nextEl: '.tile-product-ribbon__button-next',
                        disabledClass: 'disabled',
                    },
                    simulateTouch: false,
                }
            }
        },
        methods: {
            layout() {
                this.hasFrameItems = !!this.$el.querySelector('.product-item--marketing-frame');
            },
            handleResize() {
                // force rerender
                this.key++;
            },
            handleSwiperReady(swiper) {
                this.showNavigation = !swiper.isBeginning || !swiper.isEnd;
            },
        },
        mounted() {
            this.layout();
            this.handleResize = debounce(this.handleResize, 200);
            window.addEventListener('resize', this.handleResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        }
    }
</script>
