<template>
    <div>
        <div class="product-page__subtitle">
            <div class="row align-items-start mx-n1">
                <div class="col-auto px-1">
                    <svg class="product-page__sub-icon" width="20" height="20">
                        <use xlink:href="#sprite-click-and-collect" />
                    </svg>
                </div>
                <div class="col px-1">
                    <div class="row">
                        <div class="col-auto">
                            <div class="bold-title product-page__delivery-title mb-0">
                                Retrait en magasin
                            </div>
                        </div>
                        <template v-if="store">
                            <div class="col d-flex align-items-center">
                                <StockLabel
                                    label-class="product-page__stock"
                                    :stock="stock"
                                    :stock-threshold="stockThreshold"
                                    :orderable-without-stock="orderableWithoutStock"
                                    :is-locally-unavailable="isLocallyUnavailable"
                                />
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="store">
            <p class="mb-3">
                <template v-if="isLocallyUnavailable">
                    Ce produit n’est pas disponible dans votre magasin <a class="text-body" :href="store.url"><strong>{{ store.full_name }}</strong></a>,
                    sélectionnez un autre magasin en cliquant sur le bouton ci-dessous.
                </template>
                <template v-else-if="isOrderable">
                    Votre magasin <a class="text-body" :href="store.url"><strong>{{ store.full_name }}</strong></a>
                    se tient à votre disposition pour vous apporter une solution dans les meilleurs délais.
                </template>
                <template v-else>
                    Votre magasin:<br>
                    <a class="text-body" :href="store.url">
                        <strong>{{ store.full_name }}</strong>
                    </a>
                </template>
            </p>

            <div class="mb-n3">
                <div class="mb-3">
                    <div class="row mx-n1">
                        <div class="col-auto px-1">
                            <svg class="text-primary fill-current" width="16" height="16" style="width:1rem;height:1rem;">
                                <use xlink:href="#sprite-store-filled-sm" />
                            </svg>
                        </div>
                        <div class="col px-1">
                            <button class="btn btn-link-dark text-left text-lowercase" @click="handleChangeStoreClicked">
                                <span>
                                    voir les autres magasins
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                <template v-if="!stock && !isLocallyUnavailable">
                    <div class="mb-3">
                        <div class="row mx-n1">
                            <div class="col-auto px-1">
                                <div class="d-inline-flex justify-content-center align-items-center text-primary font-weight-bold"
                                    style="width: 1rem; font-size: 1.125rem"
                                >
                                    ?
                                </div>
                            </div>
                            <div class="col px-1">
                                <a :href="contactStoreUrl" class="btn btn-link-dark text-left text-lowercase">
                                    <span>
                                        contacter mon magasin pour un délai de réapprovisionnement
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <template v-else>
            <button class="btn btn-outline-primary product-page__store-button" @click="handleChangeStoreClicked">
                Sélectionner un magasin
            </button>
        </template>

        <StorePanel
            :visible.sync="storePanelVisible"
            :product-id="productId"
            :stock-threshold="stockThreshold"
            :orderable-without-stock="orderableWithoutStock"
            :store="store"
            :update-profile="false"
            @select="handleStoreSelected"
        />
    </div>
</template>

<script>
    import StorePanel from "../StorePanel.vue";
    import { postCartStore } from "../../api";
    import StockLabel from "../StockLabel.vue";


    export default {
        components: {
            StockLabel,
            StorePanel,
        },
        props: {
            productId: {
                type: String,
                required: true,
            },
            data: {
                type: Object,
                required: true,
                default: () => ({
                    store: null,
                    stock: 0,
                }),
            },
            isLocal: Boolean,
            isLocallyUnavailable: Boolean,
            stockThreshold: Number,
            orderableWithoutStock: Boolean,
            contactPageUrl: String,
            contactMessage: String,
        },
        data() {
            return {
                store: this.data.store,
                stock: this.data.stock,

                storePanelVisible: false,
            }
        },
        computed: {
            isOrderable() {
                return !this.stock && this.orderableWithoutStock;
            },
            contactStoreUrl() {
                return `${this.contactPageUrl}/stock-info/${this.store.id}/${this.productId}`;
            },
        },
        methods: {
            handleChangeStoreClicked() {
                this.storePanelVisible = true;
            },
            async handleStoreSelected(store) {
                await postCartStore(store.id);

                if(this.isLocal) {
                    location.reload();
                    return;
                }

                this.store = store;
                this.stock = store.product.stock;

                this.$store.dispatch('updateCartStore', store);

                this.$emit('change', {
                    store,
                    stock: store.product.stock,
                });
            },
        },
    }
</script>
